@import "../../../assets/styles/variaveis.scss";

.card-post-container {
    display: flex;
    flex-direction: row;
    cursor: pointer;

    .card-post-thumb {
        width: calc(94 * var(--newSize));
        height: calc(94 * var(--newSize));
    }

    .card-post-dados {
        .card-post-titulo {
            width: calc(276 * var(--newSize));
            font-size: 1.4rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--white-one);
            margin-left: calc(33 * var(--newSize));
            cursor: pointer;
        }

        .card-post-descricao {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-left: calc(33 * var(--newSize));
            height: calc(45 * var(--newSize));
            width: calc(276 * var(--newSize));
            font-size: 1.15rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.3rem;
            letter-spacing: normal;
            text-align: left;
            color: var(--warm-grey);
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .card-post-link {
            margin-left: calc(33 * var(--newSize));
            padding-top: calc(15 * var(--newSize));

            a {
                color: var(--bright-orange);
                text-decoration: none;
                font-size: 1.15rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.35;
                letter-spacing: normal;
            }
        }
    }
}

.card-post-container:hover {
    .card-post-titulo {
        color: var(--bright-orange);
    }
}

.card-post-divisor {
    border-bottom: var(--white-one) 1px solid;
    width: 100%;
    opacity: 0.25;
    margin-top: calc(32 * var(--newSize));
}

@media (max-width: $max-width-mobile) {
    .card-post-container {
        .card-post-thumb {
            width: calc(52 * var(--newSize)) !important;
            height: calc(52 * var(--newSize)) !important;
        }

        .card-post-dados {
            .card-post-titulo {
                width: calc(144 * var(--newSize));
                font-size: 0.96rem;
            }

            .card-post-descricao {
                margin-top: calc(9 * var(--newSize));
                height: calc(58 * var(--newSize));
                width: calc(150 * var(--newSize));
                font-size: 0.96rem;
            }
        }
    }
}
