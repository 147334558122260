@import "../../../assets/styles/variaveis.scss";
.popup-container {

    .popup-titulo {
        margin-top: calc(78 * var(--newSize));
        width: 100%;
        display: block;
        font-size: 1.625rem; /* 16 * 1.625 */
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.57;
        letter-spacing: normal;
        text-align: center;
        color: var(--warm-grey);
    }

    .popup-titulo-light {
        color: #000;
    }

    .popup-subtitulo {
        margin-top: calc(24 * var(--newSize));
        font-family: Roboto;
        font-size: 1.12rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: center;
        color: var(--warm-grey);
        width: 100%;
        display: block;
    }

    .popup-subtitulo-light {
        color: #000;
    }
}


.closed-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
}

@media (max-width: $max-width-mobile) {
    .popup-container {
        overflow: hidden;
    }
}
