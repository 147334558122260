.logo {
    padding: 0 calc(30 * var(--newSize));
    z-index: 5;
    height: 100%;
    display: flex;
    align-items: center;
}

.logo-cursor-pointer {
    cursor: pointer;
}

.logo img {
    max-width: 100%;
    /* width: 137px; */
    width: calc(137 * var(--newSize));
    border-radius: 5px;
}


@media screen and (max-width: 992px) {
    .logo img {
        width: 100px;

    }
}