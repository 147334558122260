@import "../../../assets/styles/variaveis.scss";

.containerListaFilmeHorizontalFavoritos {
    height: 200;
    background-color: "#ccc";
    padding: 20px;
    margin-bottom: 104px;

    .titulo {
        display: flex;
        align-items: first baseline;
        margin-bottom: 37px;
        

        div:last-child {
            margin-left: 33px;

        }

        .titulo-ver-mais {
            cursor: pointer;
        }
        .Text-Style-2 {
            font-size: 20px;
            margin-left: 20px;
            border-left: 4px solid #e20000;
            padding: 5px;
            font-weight: 400;

        }
        @media(max-width: 1024px) {
            .Text-Style-2 {
                font-size: 20px;
                margin-left: 0;
                border-left: 4px solid #e20000;
                padding: 5px;
                font-weight: 400;
    
            }
        }
    }

    @media (max-width: 992px) {
        margin-bottom: 24.3px;
        
        .titulo {
            margin-bottom: 11px;
    
            div:last-child {
                margin-left: 11px;
    
            }
        }
    }
}

// .titulo-lista-horizontal {
//     font-size: 28px;
//     line-height: 30px;
//     font-family: "DIN Pro Bold", sans-serif;
//     color: #fff;
//     letter-spacing: -1px;
//     font-weight: 400;
// }

@media (max-width: $max-width-mobile) {
    .containerListaFilmeHorizontal {
        padding: 0px !important;
        margin-left: 0px;
        margin-right: 12px;

        .titulo {

            .Text-Style-2 {
                font-size: 20px;
                margin-left: 0;
                border-left: 4px solid #e20000;
                padding: 5px;
                font-weight: 400;
    
            }
        }
      
    }
}