@import "../../../assets/styles/variaveis.scss";
.container-lista-anuncio-meia-tira-horizontal {
    height: 100;
    background-color: "#ccc";
    padding: 21px;

    .lista-anuncios-horizontal-titulo {
        font-size: 1rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: var(--warm-grey);
        margin-bottom: 2px;
    }

    .lista-anuncios-meia-tira {
        margin-top: 26px;
        height: calc(300 * var(--newSize)) !important;
        display: flex;
        overflow: hidden;
        width: 100%;
        flex-wrap: nowrap;
        transition: 0.5s;
        justify-content: space-between;

        div {
            // margin-left: 1px;
        }
    }

    .lista-anuncio-item {
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    // @media (max-width: 992px) {

    // }

    @media (max-width: $max-width-mobile) {
        .container-lista-anuncio-meia-tira-horizontal {
            height: calc(100 * var(--newSize));
        }

        .lista-anuncios-horizontal-titulo {
            font-size: 0.62rem;
        }

        .lista-anuncios-meia-tira {
            height: calc(150 * var(--newSize)) !important;
            margin-top: calc(8 * var(--newSize));
            //flex-direction: column;
            justify-content: center;
        }
    }
}
