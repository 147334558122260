@import "../../../assets/styles/variaveis.scss";

.noticia {
    width: calc(426 * var(--newSize));

    .noticia-data {
        font-family: Roboto;
        //height: calc(63 * var(--newSize));
        margin-top: calc(24 * var(--newSize));
        font-family: Roboto;
        font-size: 2rem;
        font-weight: 900;
        letter-spacing: normal;
        text-align: left;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #ff6a00;
            cursor: pointer;
        }
    }

// @media screen and (max-width: 992px) {

// }

@media (max-width: $max-width-mobile) {
    .noticia {
        width: calc(309 * var(--newSize));

        .noticia-titulo {
            width: 100%;
            //            height: calc(43 * var(--newSize));
            font-size: 1.37rem;
            margin-right: calc(75 * var(--newSize));
        }

        .noticia-data {
            margin-top: calc(38 * var(--newSize));
            font-size: 0.78rem;
        }

        .noticia-detalhe {
            margin-top: calc(18 * var(--newSize));
            p {
                max-height: calc(120 * var(--newSize));
                font-size: 0.96rem;
            }

            .leia-mais {
                font-size: 0.96rem;
            }
        }
        .noticia-imagem {
            width: calc(305 * var(--newSize));
            height: calc(187 * var(--newSize));
            margin-left: calc(17 * var(--newSize));
            margin-right: calc(16 * var(--newSize));
        }
    }
}


