@import "../../../assets/styles/variaveis.scss";

.container-lista-anuncio-horizontal {
    height: 100;
    background-color: "#ccc";
    padding: 21px;
    
    .lista-anuncios-horizontal-titulo {
        font-size: 1rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: var(--warm-grey);
    }

    .lista-anuncios {
        margin-top: 26px;
        display: flex;
        overflow: hidden;
        width: 100%;
        flex-wrap: nowrap;
        transition: 0.5s;
        justify-content: space-around;
        align-items: flex-end;

        div {
            // margin-left: 1px;
        }
    }


.loader-anuncios { 
    width: 48px; 
    height: 48px; 
    border: 5px solid #FFF; 
    border-bottom-color: #FF3D00; 
    border-radius: 50%; 
    display: inline-block; 
    box-sizing: border-box; 
    animation: rotation 1s linear infinite; 
    
    margin: 15px auto 0 auto;
} 
@keyframes rotation { 
    0% { 
        transform: rotate(0deg); 
    } 
    100% { 
        transform: rotate(360deg); 
    } 
}

    // @media (max-width: 992px) {

    // }

    @media (max-width: $max-width-mobile) {
        //height: calc(300 * var(--newSize));
        display: flex;
        flex-direction: column;
        .lista-anuncios-horizontal-titulo {
            align-self: center;
            font-size: 0.62rem;
            width: calc(190 * var(--newSize));
        }

        .lista-anuncios-horizontal-titulo {
            font-size: 0.62rem;
        }

        .lista-anuncios {
            height: calc(195 * var(--newSize)) !important;
            margin-top: calc(8 * var(--newSize));

            flex-direction: column;
            align-items: center;

            display: unset;

            .react-multi-carousel-item {
                display: flex;
                justify-content: center;
            }
        }
        .lista-anuncios-mobile {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }
}
