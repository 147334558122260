@import "../../../assets/styles/variaveis.scss";

.anuncio-meia-tira {
    .imagemCapa {
        width: calc(733 * var(--newSize));
        height: calc(250 * var(--newSize));
    }
}

@media (max-width: $max-width-mobile) {
    .anuncio-meia-tira {
        .imagemCapa {
            width: calc(300 * var(--newSize));
            height: calc(102 * var(--newSize));
        }
    }
}
