@import "../../../assets/styles/variaveis.scss";
.fazer-cadastro-container {
    // padding: 10px 50px;

    width: calc(921 * var(--newSize));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 63px calc(187 * var(--newSize));

    .botao {
        width: calc(150 * var(--newSize));
    }

    .divisor-bloco {
        width: calc(921 * var(--newSize));
        height: 1px;
        opacity: 0.25;
        background-color: var(--white-one);
        margin-left: calc(-187 * var(--newSize));
        margin-right: calc(-187 * var(--newSize));
        margin-top: 50px;
    }

    .dados-pessoais {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        flex-direction: column;
        margin-top: calc(63 * var(--newSize));
        position: relative;

        .camuflar {
            background-color: rgba(0, 0, 0, 0.7);
            top: calc(-63 * var(--newSize));
            position: absolute;
            left: calc(-187 * var(--newSize));
            right: calc(-187 * var(--newSize));
            bottom: -50px;
        }

        .fazer-cadastro-inputs {
            width: 100%;
            margin-top: 26px;

            .row {
                input {
                    color: #fff;
                }

                display: inline-flex;
                width: 100%;
                .w100 {
                    width: 100%;
                }

                .w50 {
                    width: 50%;
                }

                .w50:not(:last-child) {
                    margin-right: 32px;
                }
            }
        }

        .titulo-bloco {
            font-family: Roboto;
            font-size: 1.25rem;
            line-height: 0.8;
            letter-spacing: normal;
            text-align: left;
            color: var(--warm-grey);
        }

        .termos {
            font-size: 1rem;
            font-weight: 300;
            line-height: normal;
            color: var(--warm-grey);
            text-align: left;
            .destaque {
                color: var(--white-one);
            }

            .destaque:link {
                text-decoration: none;
            }

            input:checked {
                background-color: #e9ecee;
                border: 1px solid #adb8c0;
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
                    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
                color: #99a1a7;
                background-color: var(--warm-grey);
            }
        }
    }

    .fazer-cadastro-acoes {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: calc(91 * var(--newSize));

        .botao-arredondado-hack {
            height: calc(56 * var(--newSize));
            width: calc(266 * var(--newSize));
            .text {
                font-size: 2rem;
            }
        }

        a {
            align-items: center;
            line-height: 60px;
            height: 60px;
        }

        .nao-tem-cadastro {
            font-family: Roboto;
            font-size: 1.4rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.73;
            letter-spacing: normal;
            text-align: center;
            color: var(--warm-grey);
        }
    }
}

@media (max-width: $max-width-mobile) {
    .fazer-cadastro-container {
        width: 100%;
        padding: 63px calc(18 * var(--newSize));
        .dados-pessoais {
            display: flex;
            box-sizing: border-box;
            width: 100%;
            flex-direction: column;
            margin-top: calc(63 * var(--newSize));
            position: relative;
    
            .camuflar {
                background-color: rgba(0, 0, 0, 0.7);
                top: calc(-63 * var(--newSize));
                position: absolute;
                left: calc(-187 * var(--newSize));
                right: calc(-187 * var(--newSize));
                bottom: -50px;
            }
    
            .fazer-cadastro-inputs {
                width: 100%;
                margin-top: 26px;
    
                .row {
                    input {
                        color: #fff;
                    }
    
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    .w100 {
                        width: 100%;
                    }
    
                    .w50 {
                        width: 100%;
                    }
    
                    .w50:not(:last-child) {
                        margin-right: 0px;
                        padding-right: 0px;;
                    }
                }
            }
        }

        .fazer-cadastro-acoes {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: calc(91 * var(--newSize));
    
            .botao-arredondado-hack {
                height: calc(48 * var(--newSize));
                width: calc(220 * var(--newSize));
                .text {
                    font-size: 1rem;
                }
            }
        }
    }
}