@import "../../../assets/styles/variaveis.scss";
.copyright{
    padding: 20px;
}

.copyright span {
    font-size: 9px;
    font-family: 'Roboto', sans-serif;
}
.rodape {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: calc(150 * var(--newSize));
}

@media (max-width: $max-width-mobile) {
    .rodape {
        margin-top: calc(50 * var(--newSize));
        flex-direction: column;
    }
    .copyright {
        font-weight: bold;
    }
}
