@import "../../../assets/styles/variaveis.scss";

.favoritar {
    cursor: pointer;

    img {
        width: calc(49 * var(--newSize));
    }
}

.favoritar[inativo="1"] {
    opacity: 0.4;
}

@media (max-width: $max-width-mobile) {
    .favoritar {
        img {
            width: calc(35 * var(--newSize));
        }
    }
}
