@import "../../../assets/styles/variaveis.scss";





@media (max-width: 450px) {

    .progresso-com-legenda {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        margin-bottom: 1rem;
        text-align: center;
        width: 100%;
        overflow: hidden;

        .divBotoesGift {
            width: 95%;
            // overflow: hidden;
            .boxSaldo {
                display: flex;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                background-color: #363030;
                text-align: center;
                border: 2px solid var(--bright-orange);
                border-radius: 5px;
                // padding-top: 10px;
                margin: 15px 0 ;
                width: 100%;
                height: 30px;
                align-items: center;
                justify-content:  center;
                height: 40px;
                gap: 5px;

        
                .spanColor {
                    color: var(--bright-orange);
                    font-size: 20px;
                    font-weight: 900;

                }
            }

            .campoBotoes {
                display: flex;
                flex-direction: column !important;
                gap: 10px ;
                width: 100%;
                overflow: hidden;
                // padding: 0 10px;
                justify-content: center;

                .conta-perfil-botao-gift {
                    height: 32px;
                    min-height: none !important;
                    width: 100%;
                    align-self: center;
                    justify-self: center;
                    background-color: var(--bright-orange);
                    border: 0;
                    .botao-borda-arredondado-text {
                        font-size: 0.8rem;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                    }

                }
                .conta-perfil-botao-pontos {
                    height: 32px;
                    min-height: none !important;
                    width: 100%;
                    align-self: center;
                    justify-self: center;
                    background-color: #616161;
                    border: 0;
                    .botao-borda-arredondado-text {
                        font-size: 0.8rem;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                    }

                }
            }
        }

        
    }

}

@media (min-width: 450px) {

    .progresso-com-legenda {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        margin-bottom: 1rem;
        text-align: center;
        width: 100%;
        // overflow: hidden;

        .divBotoesGift {
            width: 95%;
            // overflow: hidden;
            display: flex;
            flex-direction: column;
           
            .boxSaldo {
                display: flex;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                background-color: #363030;
                text-align: center;
                border: 2px solid var(--bright-orange);
                border-radius: 5px;
                // padding-top: 10px;
                margin: 15px 0 ;
                width: 100%;
                height: 30px;
                align-items: center;
                justify-content:  center;
                height: 40px;
                gap: 5px;

                // span {
                //     color: var(--bright-orange);
                //     font-weight: bold;
                // }

                .spanColor {
                    color: var(--bright-orange);
                    font-size: 20px;
                    font-weight: 900;

                }
            }

            .campoBotoes {
                display: flex;
                flex-direction: column !important;
                gap: 5px !important;
                width: 100%;
                overflow: hidden;
                // padding: 0 10px;
                justify-content: center;

                .conta-perfil-botao-gift {
                    height: 30px;
                    min-height: none !important;
                    width: 100%;
                    align-self: center;
                    justify-self: center;
                    background-color: var(--bright-orange);
                    border: none !important;
                    .botao-borda-arredondado-text {
                        font-size: 12px;
                        // font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                    }

                }
                .conta-perfil-botao-pontos {
                    height: 30px;
                    min-height: none !important;
                    width: 100%;
                    align-self: center;
                    justify-self: center;
                    background-color: #616161;
                    border: none !important;
                    .botao-borda-arredondado-text {
                        font-size: 12px;
                        // font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                    }

                }
            }
        }

        
    }

}

@media (min-width: 794px) {

    .progresso-com-legenda {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        margin-bottom: 1rem;
        text-align: center;
        width: 100%;
        // overflow: hidden;

        .divBotoesGift {
            width: 95%;
            // overflow: hidden;
            // margin-left: 1px;
            display: flex;
            flex-direction: column;
           
            .boxSaldo {
                display: flex;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                background-color: #363030;
                text-align: center;
                border: 2px solid var(--bright-orange);
                border-radius: 5px;
                // padding-top: 10px;
                margin: 15px 0 ;
                width: 100%;
                height: 30px;
                align-items: center;
                justify-content:  center;
                height: 40px;
                gap: 5px;


                .spanColor {
                    color: var(--bright-orange);
                    font-size: 20px;
                    font-weight: 900;
                }
            }

            .campoBotoes {
                display: flex;
                flex-direction: column !important;
                gap: 5px !important;
                width: 100%;
                overflow: hidden;
                // padding: 0 10px;
                justify-content: center;

                .conta-perfil-botao-gift {
                    height: 32px;
                    min-height: none !important;
                    width: 100%;
                    align-self: center;
                    justify-self: center;
                    background-color: var(--bright-orange);
                    border: none !important;
                    .botao-borda-arredondado-text {
                        font-size: 12px;
                        // font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                    }

                }
                .conta-perfil-botao-pontos {
                    height: 32px;
                    min-height: none !important;
                    width: 100%;
                    align-self: center;
                    justify-self: center;
                    background-color: #616161;
                    border: none !important;
                    .botao-borda-arredondado-text {
                        font-size: 12px;
                        // font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                    }

                }
            }
        }

        
    }
}

@media (min-width: 1024px) {

    .progresso-com-legenda {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 3rem;
        margin: 30px 0 0 0;
    
        .legenda {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
    
            .resto {
                font-size: 1.5rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: var(--warm-grey);
                padding-bottom: calc(5 * var(--newSize));
                margin-left: calc(15 * var(--newSize));
            }
            
            .progresso-title {
                font-size: 2rem;
                font-weight: lighter;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: var(--bright-orange);
            }
            .progresso {
                font-size: 2.47rem;
                font-weight: 800;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: var(--bright-orange);
            }
            
        }
    
        .divBotoesGift {
            width: 100%;
            // overflow: hidden;
            // margin-left: 1px;
    
            .boxSaldo {
                display: flex;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                background-color: #363030;
                text-align: center;
                border: 2px solid var(--bright-orange);
                border-radius: 5px;
                // padding-top: 10px;
                margin: 15px 0 ;
                width: 100%;
                height: 30px;
                align-items: center;
                justify-content:  center;
                height: 40px;
                gap: 5px;

                .spanColor {
                    color: var(--bright-orange);
                    font-size: 20px;
                    font-weight: 900;
                }
            }
            .campoBotoes {
                display: flex;
                flex-direction: row !important;
                gap: 30px;
                // margin-top: 20px;
                // width: 380px;
                justify-content: space-between;
        
        
                .conta-perfil-botao-gift {
                    font-size: 12px;
                    background-color: var(--bright-orange) !important;
                    width: 45%;
                    margin: 0;
                }
        
                .conta-perfil-botao-pontos {
                    font-size: 12px;
                    background-color:  #616161 !important;
                    width: 45%;
                    margin: 0;
                }
            }
        }   
    }
}





@media (min-width: 1200px) {

    .progresso-com-legenda {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 3rem;
        margin: 30px 0 0 0;
    
        .legenda {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
    
            .resto {
                font-size: 1.5rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: var(--warm-grey);
                padding-bottom: calc(5 * var(--newSize));
                margin-left: calc(15 * var(--newSize));
            }
            
            .progresso-title {
                font-size: 2rem;
                font-weight: lighter;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: var(--bright-orange);
            }
            .progresso {
                font-size: 2.47rem;
                font-weight: 800;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: var(--bright-orange);
            }
            
        }
    
        .divBotoesGift {
            width: 100%;
            // overflow: hidden;
            // margin-left: 1px;
    
            .boxSaldo {
                align-items: center;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                background-color: #363030;
                text-align: center;
                border: 2px solid var(--bright-orange);
                border-radius: 5px;
                // padding: 10px;
                gap: 5px;
                margin: 0 0 1.6rem 0;
                height: 40px;
                width: 100%;
        
                .spanColor {
                    color: var(--bright-orange);
                    font-size: 20px;
                    font-weight: 900;
                }
            }
            .campoBotoes {
                display: flex;
                flex-direction: row !important;
                gap: 30px;
                margin: 0px 0 40px 0;
                // width: 380px !important;
                justify-content: space-between;
        
        
                .conta-perfil-botao-gift {
                    font-size: 12px;
                    background-color: var(--bright-orange) !important;
                    width: 45%;
                }
        
                .conta-perfil-botao-pontos {
                    font-size: 12px;
                    background-color:  #616161 !important;
                    width: 45%;
                }
            }
        }   
    }
}



