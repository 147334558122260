@import '../../../assets/styles/variaveis.scss';

.card-box {
    padding-right: 10px;
    border-radius: 8px;
    .card-fav {
        border-top: 3px solid #e20000;
    }
}

.mobile {
    display: none;
}

.card-opcoes-on-mouse-over {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0px;
    left: 0px;
    bottom: 5px;
    right: 10px;
    border-radius: 5px;
    padding: 14px;
    transition: all .25s;
    transform: scale(0.95);
}

.card-opcoes-on-mouse-over:hover {
    transform: scale(1.0);
}

.card-image-poster {
    width: 100%;
    height: 100%; 
    border-radius: 5px ;
    /* box-shadow: 0 10px 20px 0 rgba(0,0,0,.2),0 10px 20px 0 rgba(0,0,0,.2); */
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
}

@media (max-width: $max-width-mobile) {

    .card-fav {
        border-top: 3px solid #e20000;
    }
    .mobile {
        display: flex;
    }

    .colunas{
        display: none;
        
    }

    .card-image-poster {
        width: calc(97 * var(--newSize)) !important;
        height: calc(141 * var(--newSize)) !important;
        
        

        //width: 80px;
        //height: 100%; 
        //border-radius: 5px ;
    }
}

