@import "../../../assets/styles/variaveis.scss";

.container-lista-noticia-vertical {
    padding: 0 20px;

    .lista-noticias-vertical-titulo {
        font-size: 1rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: var(--warm-grey);
    }

    .lista-noticias {
        margin-top: 26px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
        flex-wrap: nowrap;
        transition: 0.5s;
        justify-content: space-around;

        div {
            // margin-left: 1px;
        }
    }

    // @media (max-width: 992px) {

    // }

    .title-notice {
        font-size: 2rem;
        font-weight: bolder;

        p {
            margin-bottom: 1rem;
        }

    }

    .description-notice {
        display: flex;
        font-size: 2rem;
        justify-content: space-between;
        border-left: 0.3rem solid #ff6a00;
        p {
            margin-left: 5px;
            margin-block-end: 0;
            margin-block-start: 0;
        }

        a {
            text-align: right;
            color: #ff6a00;
            font-size: 1.8rem;
        }
    }

    @media (max-width: $max-width-mobile) {

        .lista-noticias-vertical-titulo {
            font-size: 1rem;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            text-align: left;
            color: var(--warm-grey);
        }
    
        .lista-noticias {
            margin-top: calc(1 * var(--newSize));
            flex-direction: column;
        }
    
        .title-notice {
            font-size: 1rem;
            font-weight: bolder;
    
            p {
                margin-bottom: 1rem;
            }
    
        }
    
        .description-notice {
            display: flex;
            font-size: 2rem;
            justify-content: space-between;
            border-left: 0.3rem solid #ff6a00;
            p {
                margin-left: 5px;
                margin-block-end: 0;
                margin-block-start: 0;
            }
    
            a {
                text-align: right;
                color: #ff6a00;
                font-size: 1.8rem;
            }
        }
    }
}
