@import "../../../assets/styles/variaveis.scss";
.trocar-senha-container {
    padding: 10px 50px 30px 50px;
    width: calc(500 * var(--newSize));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    form {
        width: 100%;
    }

    .trocar-senha-inputs {
        margin-top: 13.8%;
        width: 100%;
        .trocar-senha-input {
            width: calc(390 * var(--newSize));
        }
    }

    .trocar-senha-acoes {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}

.popup-custom {
    .popup-titulo {
        color: #fff;
        font-size: 1.75rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
    }
}

@media (max-width: $max-width-mobile) {
    .trocar-senha-container {
        padding: 10px calc(20 * var(--newSize)) 30px calc(20 * var(--newSize));
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

        form {
            width: 100%;
        }

        .trocar-senha-inputs {
            margin-top: 13.8%;
            width: 100%;
            .trocar-senha-input {
                width: calc(390 * var(--newSize));
            }
        }

        .trocar-senha-acoes {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
    }

    .popup-custom {
        .popup-titulo {
            color: #fff;
            font-size: 1.5rem;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
        }
    }
}
