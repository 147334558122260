@import "../../../assets/styles/variaveis.scss";
.moldura-popup {
    // background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;

    .container {
        position: relative;
        max-width: calc(1800 * var(--newSize));
        background-color: var(--background-black-two);
        font-size: 2rem;
        border-radius: 8px;
        border: solid 1px var(--warm-grey);
        max-height: calc(850 * var(--newSize));
        overflow-y: auto;
        overflow-x: hidden;
        scroll-behavior: smooth;
        -webkit-scroll-behavior-duration: 0.5s;
        -webkit-scroll-behavior-timing-function: ease-in;
    }

    .container-bg-light {
        background-color: #fff;
    }

    @media (max-width: $max-width-mobile) {
        box-sizing: border-box;
        width: 90%;
        height: 100%;
        margin: 0 auto;
        
        top: 3rem;
        .container {
            height: 70%;
            overflow-y: scroll;
            margin-bottom: 60px;
            padding-bottom: 10px;
        }
    }
}
