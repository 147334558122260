@import "funcoes.scss";
@import "variaveis.scss";

:root {
    --black: #000;

    --white: #fff;
    --white-one: #fff;

    --background-black-two: #141414;

    --black-three: #0f0e0e;

    --bright-orange: #ff6a00;

    --warm-grey: #808080;

    --dark-indigo-85: rgba(14, 10, 88, 0.85);

    --greyish-brown: #474646;

    --screen-width-hd: 1366px;
    --max-width-mobile: 996px;
}

html {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
}

body {
    background: var(--background-black-two);
    font-family: Roboto;
    color: #fff;
    margin: 0;
    padding: 0;
}

@media (min-width: 1367px) {
    :root {
        --newSize: calc(1 / 1920 * 100vw);
    }
    html {
        font-size: calc(16 / 1920 * 100vw); //calc(16/1920 * 100vw);
    }
    body {
        font-size: calc(16 / 1920 * 100vw); //calc(16/1920 * 100vw);
    }
}

@media (min-width: 992px) and (max-width: 1366px) {
    :root {
        --newSize: 0.71px;
    }
    html {
        font-size: calc(16 * var(--newSize)); //calc(16/1920 * 100vw);
    }
    body {
        font-size: calc(16 * var(--newSize));
        position: relative;
        min-width: 1124px;
    }
}
@media (max-width: $max-width-mobile) {
    :root {
        --newSize: calc(1 / 720 * 100vw);
    }

    body {
        font-size: 16px; // calc(16 * var(--newSize));;
        min-width: unset;
    }

    html {
        font-size: 16px; // calc(16 * var(--newSize));;
        font-size: calc(16 * var(--newSize));
    }
}

@media (max-width: 460px) {
    :root {
        --newSize: calc(1 / 360 * 100vw);
    }

    body {
        font-size: 16px; // calc(16 * var(--newSize));;
        min-width: unset;
    }

    html {
        font-size: 16px; // calc(16 * var(--newSize));;
        font-size: calc(16 * var(--newSize));
    }
}

h3 {
    font-size: 28px;
    line-height: 32px;
    font-family: "DIN Pro Bold", sans-serif;
    color: #fff;
    letter-spacing: -1px;
}

.link-popup {
    color: var(--white-one);
    font-size: 1rem;
}

.botao-arredondado-hack {
    border-radius: 28px;
}


.Toastify__toast-container{
    position: fixed;
    z-index: 99999 !important;
}

.row {
    input {
        color: #fff;
    }

    display: inline-flex;
    width: 100%;
    .w100 {
        width: 100%;
    }

    .w50 {
        width: 50%;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
    }

    .w50:not(:last-child) {
        padding-right: 32px;
    }

    .w25 {
        width: 25%;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
    }

    .w25:not(:last-child) {
        padding-right: 32px;
    }

    @media (max-width: $max-width-mobile) {
        display: inline-flex;
        flex-direction: column;
        width: 100%;
        .w100 {
            width: 100%;
        }

        .w50 {
            width: 100%;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
        }

        .w50:not(:last-child) {
            padding-right: 0;
        }

        .w25 {
            width: 100%;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
        }

        .w25:not(:last-child) {
            padding-right: 0px;
        }
    }
}

.showInMobile {
    @media (min-width: $max-width-mobile) {
        display: none !important;
    }
}

.hideInMobile {
    @media (max-width: $max-width-mobile) {
        display: none !important;
    }
}

.botao-inativo {
    cursor: not-allowed;
    filter: grayscale(0.7);
}
