.sinopse-filme-serie {
    font-family: Roboto;
    font-size: 96.5px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--white-one);
}