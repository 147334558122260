.text-accordion-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #151A25;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-bottom: none;
}

.max-md\:max-w-full {
    max-width: 100%;
}

.relative {
    position: relative;
}

.text-5xl {
    font-size: 3rem;
}

.font-bold {
    font-weight: bold;
}

.text-gray-900 {
    color: #1a202c;
}

.bg-white {
    background-color: #ffffff;
}

.rounded-\[56px\] {
    border-radius: 56px;
}

.w-\[936px\] {
    width: 936px;
}

.text-accordion-title {
    font-size: 1.25rem;
}

/* Remove shadow, border, or effect from the Accordion root */
.MuiAccordion-root {
    box-shadow: none;
    /* Removes the shadow */
    border: none;

    /* Removes any default border */
    &:before {
        display: none;
        /* Removes the default bottom border line */
    }
}

/* Remove shadow, border, or effect from the AccordionSummary */
.MuiAccordionSummary-root {
    box-shadow: none;
    /* Removes the shadow */
    border: none;
    /* Removes any default border */
}

/* Remove shadow, border, or effect from the AccordionDetails */
.MuiAccordionDetails-root {
    box-shadow: none;
    /* Removes the shadow */
    border: none;
    /* Removes any default border */
}