@import "../../../assets/styles/variaveis.scss";
.fazer-login-popup {
    padding: 40px 50px;
    width: calc(500 * var(--newSize));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    .fazer-login-inputs {
        margin-top: 13.8%;
        width: 100%;

        .password {
            margin-bottom: 0;
        }
    }

    .fazer-login-acoes {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .break-login {
            margin-top: -10px;
            margin-bottom: -10px;
            font-size: 1.6rem;
            color: var(--warm-grey);
        }

        .botao-arredondado-hack {
            font-size: 1rem;
            width: calc(266 * var(--newSize));
            border-radius: 5px;

            div.text {
                text-transform: none;
            }
        }

        .botao-arrendondado-google {
            background-color: rgb(255, 255, 255);
            display: inline-flex;
            align-items: center;
            padding: 0px;
            border-radius: 5px;
            border: 1px solid transparent;
            font-size: 14px;
            font-weight: 500;
            font-family: Roboto, sans-serif;
            width: auto;
            padding: 10px 5px;
            margin-bottom: 20px;

            div.text {
                text-transform: none;
                margin-left: 10px;
                font-family: Roboto;
                font-size: 1.1rem;
                font-weight: 900;
                line-height: 2.03;
                letter-spacing: 0.46px;
                text-align: center;
                color: var(--warm-grey);
            
            }
        }

        .botao-cadastre-se-hack {
            margin-top: 15px;
            width: calc(266 * var(--newSize));
            border-radius: 10px;
            min-height: calc(41 * var(--newSize));

            div.transparencia {
                border-radius: 10px;
            }
        }

        a {
            align-items: center;
            line-height: 60px;
            height: 60px;
        }

        .nao-tem-cadastro {
            font-family: Roboto;
            font-size: 1.4rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.73;
            letter-spacing: normal;
            text-align: center;
            color: var(--warm-grey);

            margin-top: 16px;
        }
    }
}

@media (max-width: $max-width-mobile) {
    .fazer-login-popup {
        padding: 40px 50px;
        width: 100%;

        .fazer-login-acoes {
            width: 100%;
    
            .botao-arredondado-hack {
                font-size: 1rem;
                width: 100%;
            }
    
            .botao-cadastre-se-hack {
                margin-top: 15px;
                width: 98%;
            }
        }
        
        .botao-arrendondado-google {

            div.text {
                font-size: 0.8rem !important;
            }
        }
 
    }    
}