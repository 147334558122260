@import "../../../assets/styles/variaveis.scss";

.container-lista-filme-grid {
    height: 200;
    background-color: "#ccc";
    padding: 20px;
    margin-bottom: 104px;

    .grid {
        display: grid;
        grid-template-columns: repeat(8, 1fr);

        .card-custom {
            margin-right: calc(12 * var(--newSize));

            margin-bottom: calc(24 * var(--newSize));
        }
    }

    .titulo {
        display: flex;
        align-items: first baseline;
        margin-bottom: 37px;

        div:last-child {
            margin-left: 33px;
        }

        .titulo-ver-mais {
            cursor: pointer;
        }
    }

    @media (max-width: 992px) {
        margin-bottom: 24.3px;

        .titulo {
            margin-bottom: 11px;

            div:last-child {
                margin-left: 11px;
            }
        }
    }
}

.titulo-lista-horizontal {
    font-size: 28px;
    line-height: 30px;
    font-family: "DIN Pro Bold", sans-serif;
    color: #fff;
    letter-spacing: -1px;
    font-weight: 400;
}

@media (max-width: $max-width-mobile) {
    .container-lista-filme-grid {
        padding: 0px !important;
        // margin-left: calc(5 * var(--newSize));
        // margin-right: calc(5 * var(--newSize));

        .grid {
            display: flex;
            flex: 1 1 30%;
            flex-direction: row;
            flex-wrap: wrap;
            display: flex;
            justify-content: space-evenly;
            padding: 0px !important;
            .card-custom {
                margin-right: 0;
                margin-bottom: calc(10 * var(--newSize));
            }

            .card-box {
                padding-right: 0px;
            }

            .card-image-poster {
                margin-left: calc(0 * var(--newSize)) !important;
            }
        }
    }
}
