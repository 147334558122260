@import "../../../assets/styles/variaveis.scss";
.botao-borda-arredondado-mobile {
    cursor: pointer;
    // min-width: calc(267 * var(--newSize));
    // min-height: calc(58 * var(--newSize));
    // height: calc(58 * var(--newSize));
    border-radius: 29px;
    border: solid 2px var(--white-one);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 35px;
    height: 35px;
    .botao-borda-arredondado-text {
        font-family: Roboto;
        font-size: 1.2rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.34;
        letter-spacing: normal;
        text-align: left;
        color: var(--white-one);
        z-index: 5;
        text-transform: uppercase;
    }

    .transparencia {
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 29px;
        background-color: var(--greyish-brown);
        opacity: 0.8;
        z-index: 1;
    }
  
    
    
    .botao-borda-arredondado-mobile:hover {
        border: solid 2px transparent;
        .transparencia {
            background-color: var(--white-one);
            opacity: 1;
        }
        .botao-borda-arredondado-text {
            color: #333;
        }
    }

}

@media (max-width: $max-width-mobile) {
    .botao-borda-arredondado-mobile {
        cursor: pointer;
        // width: 100px;
        min-height: none;      
        border-radius: 29px;
        border: solid 2px var(--white-one);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        width: 35px;
        height: 35px;
        .botao-borda-arredondado-text {
            font-size: 1rem;
            line-height: 1.34;
            letter-spacing: normal;
        }
        .transparencia {
            border-radius: calc(28 * var(--newSize));
            background-color: var(--greyish-brown);
            opacity: 0.8;
            z-index: 1;
        }
        
        
        .botao-borda-arredondado-mobile:hover {
            border: solid 2px transparent;
            .transparencia {
                background-color: var(--white-one);
                opacity: 1;
            }
            .botao-borda-arredondado-text {
                color: #333;
            }
        }
    }
    
}


