@import "../../assets/styles/variaveis.scss";


body {
    background: black;
}


ul {
    list-style: none;
    margin: 0;
    padding: 0;
    // background-color: var(--background-black-two);

}

@media (max-width: 350px) {
    .contaMeusPontos {

        background: #F9F9F9; // #140f0f;
        
        .custom-menu {
            height: unset;
            background-color: #140f0f;
        }
        .divTitulo {
            // border: 3px solid #eb0606;
            display: flex;
            justify-items: center;
            align-items: center;
            background: url("../../../public/usemais/usemais-banner-mobile.png") no-repeat center;
            height: 300px;
            .tituloMeuspontos {
                display: flex;        
                justify-items: center;
                align-items: center;          
                width: 80%;
                margin: 0 auto;                  
                h1 {
                    display: flex;
                    font-size: 2.3rem;
                    justify-self: center;
                    align-self: center;
                }
             }

        }
        
        
    
        .container-filtros {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            padding: 10px 0;
            gap: 1rem;
            
            .conta-saldo-botao {
                width: 20%;
            }
    
            .conta-perfil-botao-extrato {
                border: 1px solid var(--bright-orange);
                border-radius: 10px !important;
                background-color: #363030 !important;
            }
        }
    
        .container-filtros-mobile {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            width: 95%;
            margin: 0 auto;
            // padding: 10px 0;
            // height: 30px;
            .saldoOrdenar {
                margin-bottom: 24ṕx;
                .btn-saldo-pontos {
                    height: 40px;
                    border: 1px solid var(--bright-orange) !important;
                    background-color: #363030;
                    width: 60% !important;
                    border-bottom-left-radius: 6px !important;
                    border-top-left-radius: 6px !important;
                    margin: 0;

                    .botao-borda-arredondado-text {
                        font-size: 10px;
                        span {
                            font-size: 14px;
                        }
                    }

                }
                .botao-resgate-custom {
                    width: 40% !important;
                    min-width: 40% !important;
                    height: 40px;

                    span {
                        font-size: 10px !important;
                    }

                    .text {
                        font-size: 12px !important;
                    }
                }

            }

            .filtro-voucher-mobile {
                height: 40px;
                .busca-containerMobile {
                    height: 100%;
                    margin: 0;
                }
            }






                .conta-perfil-botao-extrato {
                    // width: 100vh;
                    border: 1px solid var(--bright-orange);
                    border-radius: 10px !important;
                    background-color: #363030 !important;
                }
        
                .filtroMobile {
                    // flex-direction: row;
                    align-self: center;
                    justify-self: center;
                    width: 100vh;
                }
            // }
    
            .conta-saldo-botao {
                // width: 20%;
            }
    
           
        }
    
        .resgate-seus-pontos {
            // margin-top: calc(150 * var(--newSize));
            // margin-bottom: calc(181 * var(--newSize));
    
            .resgate-titulo {
                font-size: 2rem;
                font-weight: 500;
                font-stretch: normal;
                text-align: left;
                margin-left: calc(150 * var(--newSize));
                // color: var(--bright-orange);
            }
    
            .resgate-titulo-skeleton {
                font-size: 2rem;
                font-weight: 500;
                font-stretch: normal;
                text-align: left;
                margin-left: calc(150 * var(--newSize));
                width: calc(400 * var(--newSize));
                // color: var(--bright-orange);
            }
    
            .custom-lista-resgate-pontos {
               
                justify-content: center;
                gap: 10px;
                width: 95%;
                margin: 10px auto;

                .text {
                    font-size: 12px !important;
                    font-weight: 500 !important;
                    font-stretch: normal;
                }

                .dialog-custom-button.ok-button {
                    font-size: 14px;
                }

                // width: 80%;
                .custom-card-resgate {
                    width: 140px;
                    flex-shrink: 1;
                    height: 170px !important;
                    padding-bottom: 10px !important;
                    margin: 0;
                    background-color: #FFF;
                    justify-content: space-between;

                    .card-resgate-total-pontos {
                        font-size: 12px !important;
                        height: 24px !important;
                        padding: 0 !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        // margin: 0 0 20px 0;
                    }

                    .card-resgate-pontos-body {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;

                        .card-resgate-premio {
                            font-size: 10px;
                            margin-top: 0 !important;
                        }
                    }

                    .botao-arredondado {
                        min-width: 10px !important;
                    }
                    .botao-resgate-custom {
                        width: 118px !important;
                        height: 24px !important;
                        align-items: center;
                        justify-content: center;
                        .text {
                            font-size: 11px !important;
                            font-weight: 500 !important;
                            font-stretch: normal;
                            line-height: 11px !important;
                        }
                    }
                }
            }
    
            // .filtro-voucher {
            //     text-align: left;
            //     margin: 0 10px 0 10px;
            // }
    
            .listaCategoria {
                // border: 1px solid white;
                margin: 10px;
                padding: 0;
                
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    background-color: var(--background-black-two);
                
                }
                li {
                    margin: 10px;
                    padding: 10px;
                }
            
                li:hover {
                    background-color: var(--bright-orange);
                    cursor: pointer;
                }
    
                li:first-of-type {      
                    background-color: var(--warm-grey) !important;
                    font-size: 18px;
                    
                }
            }
            .listaCategoriaMobile {
                // border: 1px solid white;
                // margin: 10px 10px 10px 10px;
                padding: 0;
                display: inline-block;
                position: relative;
                width: 100%;
                height: 100%;
                background-color: #363030;
                border-radius: 6px;
                cursor: pointer;

                .divBtMenu {
                    background-color: #363030 !important;
                    border-radius: 6px;
                    margin: 0 !important;
                    height: 40px !important;
                    .btnMenu {
                        background-color: #363030 !important;
                        margin: 0 !important;
                        width: 100%;

                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        span {
                            // float: left; 
                            margin: 0 !important;
                            padding-left: 20px;
                            font-size: 18px;
                            font-weight: 300;
                        }

                        .icone-categoria {
                            // float:right;
                            padding-right: 20px;
                            
                        }
                    }
                }
    
    
                .listaBuscaMobile {
                    display: none;
                    position: absolute;
                    z-index: 1;
                }
    
                li {
                    margin: 10px;
                    padding: 10px;
                    position:relative;
    
                    border-right:1px solid #c0c0c0;
                }
            
                li:hover {
                    background-color: var(--bright-orange);
                    cursor: pointer;
                }
                .btnMenu {
                    background-color: var(--background-black-two) ;
                    // border: 1px solid var(--bright-orange);
                    position: absolute;
                    // width: 100% !important;
                    text-align: center;
                    text-justify: center;
                    align-items: center;
                    float: right;
                    margin: 0 10px 0px 10px;
                    width: 90%;
                }
    
                .divBtMenu {
                    display: flex;
                    flex-direction: row;
                    // width: 100% !important;
                    // border: 1px solid var(--bright-orange); 
                    justify-content: space-between;
                    align-content: center;
                    justify-items: center;
                    align-items: center;
                    height: 50px;
                    margin: 0 10px 0px 10px;
                    border-radius: 10px !important;
                    background-color: var(--background-black-two) ;
    
                    span {
                        color: #c0c0c0;
                    }
                }
    
            }
    
    
            .mobileItemCard {
                display: flex;
                flex-direction: column;
                width: 100% !important;
            } 
        }
    }
    
}

@media (min-width: 350px) {
    .contaMeusPontos {

        background: #F9F9F9; // #140f0f;
        
        .custom-menu {
            height: unset;
            background-color: #140f0f;
        }
        .divTitulo {
            // border: 3px solid #eb0606;
            display: flex;
            justify-items: center;
            align-items: center;
            background: url("../../../public/usemais/usemais-banner-mobile.png") no-repeat center;
            height: 300px;
            .tituloMeuspontos {
                display: flex;        
                justify-items: center;
                align-items: center;          
                width: 80%;
                margin: 0 auto;                  
                h1 {
                    display: flex;
                    font-size: 2.3rem;
                    justify-self: center;
                    align-self: center;
                }
             }

        }
        
        
    
        .container-filtros {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            padding: 10px 0;
            gap: 1rem;
            
            .conta-saldo-botao {
                width: 20%;
            }
    
            .conta-perfil-botao-extrato {
                border: 1px solid var(--bright-orange);
                border-radius: 10px !important;
                background-color: #363030 !important;
            }
        }
    
        .container-filtros-mobile {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            width: 95%;
            margin: 0 auto;
            // padding: 10px 0;
            // height: 30px;
            .saldoOrdenar {
                margin-bottom: 24ṕx;
                .btn-saldo-pontos {
                    height: 40px;
                    border: 1px solid var(--bright-orange) !important;
                    background-color: #363030;
                    width: 60% !important;
                    border-bottom-left-radius: 6px !important;
                    border-top-left-radius: 6px !important;
                    margin: 0;

                    .botao-borda-arredondado-text {
                        font-size: 12px;
                        span {
                            font-size: 14px;
                        }
                    }

                }
                .botao-resgate-custom {
                    width: 40% !important;
                    min-width: 40% !important;
                    height: 40px;

                    .text {
                        font-size: 14px !important;
                    }
                }

            }

            .filtro-voucher-mobile {
                height: 40px;
                .busca-containerMobile {
                    height: 100%;
                    margin: 0;
                }
            }






                .conta-perfil-botao-extrato {
                    // width: 100vh;
                    border: 1px solid var(--bright-orange);
                    border-radius: 10px !important;
                    background-color: #363030 !important;
                }
        
                .filtroMobile {
                    // flex-direction: row;
                    align-self: center;
                    justify-self: center;
                    width: 100vh;
                }
            // }
    
            .conta-saldo-botao {
                // width: 20%;
            }
    
           
        }
    
        .resgate-seus-pontos {
            // margin-top: calc(150 * var(--newSize));
            // margin-bottom: calc(181 * var(--newSize));
    
            .resgate-titulo {
                font-size: 2rem;
                font-weight: 500;
                font-stretch: normal;
                text-align: left;
                margin-left: calc(150 * var(--newSize));
                // color: var(--bright-orange);
            }
    
            .resgate-titulo-skeleton {
                font-size: 2rem;
                font-weight: 500;
                font-stretch: normal;
                text-align: left;
                margin-left: calc(150 * var(--newSize));
                width: calc(400 * var(--newSize));
                // color: var(--bright-orange);
            }
    
            .custom-lista-resgate-pontos {
               
                justify-content: space-evenly;
                gap: 10px;
                width: 95%;
                margin: 10px auto;

                .text {
                    font-size: 14px !important;
                    font-weight: 500 !important;
                    font-stretch: normal;
                }

                .dialog-custom-button {
                    .botao-borda-arredondado-text {
                        font-size: 10px !important;
                        font-weight: 500 !important;
                    }

                    &.ok-button {
                        display: flex;
                        justify-content: center;
                        .text {
                            font-size: 11px !important;
                            font-weight: 500 !important;
                        }
                    }
                }

                // width: 80%;
                .custom-card-resgate {
                    width: 160px;
                    height: 190px !important;
                    padding-bottom: 10px !important;
                    margin: 0;
                    background-color: #FFF;
                    justify-content: space-between;

                    .card-resgate-total-pontos {
                        font-size: 12px !important;
                        height: 24px !important;
                        padding: 0 !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        // margin: 0 0 20px 0;
                    }

                    .card-resgate-pontos-body {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                    }

                    .botao-arredondado {
                        min-width: 10px !important;
                    }
                    .botao-resgate-custom {
                        width: 118px !important;
                        height: 24px !important;
                        align-items: center;
                        justify-content: center;
                        .text {
                            font-size: 11px !important;
                            font-weight: 500 !important;
                            font-stretch: normal;
                            line-height: 11px !important;
                        }
                    }
                }
            }
    
            // .filtro-voucher {
            //     text-align: left;
            //     margin: 0 10px 0 10px;
            // }
    
            .listaCategoria {
                // border: 1px solid white;
                margin: 10px;
                padding: 0;
                
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    background-color: var(--background-black-two);
                
                }
                li {
                    margin: 10px;
                    padding: 10px;
                }
            
                li:hover {
                    background-color: var(--bright-orange);
                    cursor: pointer;
                }
    
                li:first-of-type {      
                    background-color: var(--warm-grey) !important;
                    font-size: 18px;
                    
                }
            }
            .listaCategoriaMobile {
                // border: 1px solid white;
                // margin: 10px 10px 10px 10px;
                padding: 0;
                display: inline-block;
                position: relative;
                width: 100%;
                height: 100%;
                background-color: #363030;
                border-radius: 6px;
                cursor: pointer;

                .divBtMenu {
                    background-color: #363030 !important;
                    border-radius: 6px;
                    margin: 0 !important;
                    height: 40px !important;
                    .btnMenu {
                        background-color: #363030 !important;
                        margin: 0 !important;
                        width: 100%;

                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        span {
                            // float: left; 
                            margin: 0 !important;
                            padding-left: 20px;
                            font-size: 18px;
                            font-weight: 300;
                        }

                        .icone-categoria {
                            // float:right;
                            padding-right: 20px;
                            
                        }
                    }
                }
    
    
                .listaBuscaMobile {
                    display: none;
                    position: absolute;
                    z-index: 1;
                }
    
                li {
                    margin: 10px;
                    padding: 10px;
                    position:relative;
    
                    border-right:1px solid #c0c0c0;
                }
            
                li:hover {
                    background-color: var(--bright-orange);
                    cursor: pointer;
                }
                .btnMenu {
                    background-color: var(--background-black-two) ;
                    // border: 1px solid var(--bright-orange);
                    position: absolute;
                    // width: 100% !important;
                    text-align: center;
                    text-justify: center;
                    align-items: center;
                    float: right;
                    margin: 0 10px 0px 10px;
                    width: 90%;
                }
    
                .divBtMenu {
                    display: flex;
                    flex-direction: row;
                    // width: 100% !important;
                    // border: 1px solid var(--bright-orange); 
                    justify-content: space-between;
                    align-content: center;
                    justify-items: center;
                    align-items: center;
                    height: 50px;
                    margin: 0 10px 0px 10px;
                    border-radius: 10px !important;
                    background-color: var(--background-black-two) ;
    
                    span {
                        color: #c0c0c0;
                    }
                }
    
            }
    
    
            .mobileItemCard {
                display: flex;
                flex-direction: column;
                width: 100% !important;
            } 
        }
    }
    
}

@media (min-width: 400px) {
    .contaMeusPontos {

        background: #F9F9F9; // #140f0f;
        
        .custom-menu {
            height: unset;
            background-color: #140f0f;
        }
        .divTitulo {
            // border: 3px solid #eb0606;
            display: flex;
            justify-items: center;
            align-items: center;
            background: url("../../../public/usemais/usemais-banner-web-cupons.png") no-repeat center;
            height: 300px;
            .tituloMeuspontos {
                display: flex;        
                justify-items: center;
                align-items: center;          
                width: 80%;
                margin: 0 auto;                  
                h1 {
                    display: flex;
                    font-size: 2.3rem;
                    justify-self: center;
                    align-self: center;
                }
             }

        }
        
        
    
        .container-filtros {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            padding: 10px 0;
            gap: 1rem;
            
            .conta-saldo-botao {
                width: 20%;
            }
    
            .conta-perfil-botao-extrato {
                border: 1px solid var(--bright-orange);
                border-radius: 10px !important;
                background-color: #363030 !important;
            }
        }
    
        .container-filtros-mobile {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            width: 95%;
            margin: 0 auto;
            // padding: 10px 0;
            // height: 30px;
            .saldoOrdenar {
                margin-bottom: 24ṕx;
                .btn-saldo-pontos {
                    height: 40px;
                    border: 1px solid var(--bright-orange) !important;
                    background-color: #363030;
                    width: 60% !important;
                    border-bottom-left-radius: 6px !important;
                    border-top-left-radius: 6px !important;
                    margin: 0;

                    .botao-borda-arredondado-text {
                        font-size: 12px;
                        span {
                            font-size: 14px;
                        }
                    }

                }
                .botao-resgate-custom {
                    width: 40% !important;
                    min-width: 40% !important;
                    height: 40px;

                    .text {
                        font-size: 14px !important;
                    }
                }

            }

            .filtro-voucher-mobile {
                height: 40px;
                .busca-containerMobile {
                    height: 100%;
                    margin: 0;
                }
            }






                .conta-perfil-botao-extrato {
                    // width: 100vh;
                    border: 1px solid var(--bright-orange);
                    border-radius: 10px !important;
                    background-color: #363030 !important;
                }
        
                .filtroMobile {
                    // flex-direction: row;
                    align-self: center;
                    justify-self: center;
                    width: 100vh;
                }
            // }
    
            .conta-saldo-botao {
                // width: 20%;
            }
    
           
        }
    
        .resgate-seus-pontos {
            // margin-top: calc(150 * var(--newSize));
            // margin-bottom: calc(181 * var(--newSize));
    
            .resgate-titulo {
                font-size: 2rem;
                font-weight: 500;
                font-stretch: normal;
                text-align: left;
                margin-left: calc(150 * var(--newSize));
                // color: var(--bright-orange);
            }
    
            .resgate-titulo-skeleton {
                font-size: 2rem;
                font-weight: 500;
                font-stretch: normal;
                text-align: left;
                margin-left: calc(150 * var(--newSize));
                width: calc(400 * var(--newSize));
                // color: var(--bright-orange);
            }
    
            .custom-lista-resgate-pontos {
               
                justify-content: space-evenly;
                gap: 10px;
                width: 95%;
                margin: 10px auto;

                .text {
                    font-size: 14px !important;
                    font-weight: 500 !important;
                    font-stretch: normal;
                }

                // width: 80%;
                .custom-card-resgate {
                    width: 185px;
                    height: 215px !important;
                    padding-bottom: 10px !important;
                    margin: 0;
                    background-color: #FFF;
                    justify-content: space-between;

                    .card-resgate-total-pontos {
                        font-size: 12px !important;
                        height: 24px !important;
                        padding: 0 !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        // margin: 0 0 20px 0;
                    }

                    .card-resgate-pontos-body {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                    }

                    .botao-arredondado {
                        min-width: 10px !important;
                    }
                    .botao-resgate-custom {
                        width: 118px !important;
                        height: 24px !important;
                        align-items: center;
                        justify-content: center;
                        .text {
                            font-size: 11px !important;
                            font-weight: 500 !important;
                            font-stretch: normal;
                            line-height: 11px !important;
                        }
                    }
                }
            }
    
            // .filtro-voucher {
            //     text-align: left;
            //     margin: 0 10px 0 10px;
            // }
    
            .listaCategoria {
                // border: 1px solid white;
                margin: 10px;
                padding: 0;
                
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    background-color: var(--background-black-two);
                
                }
                li {
                    margin: 10px;
                    padding: 10px;
                }
            
                li:hover {
                    background-color: var(--bright-orange);
                    cursor: pointer;
                }
    
                li:first-of-type {      
                    background-color: var(--warm-grey) !important;
                    font-size: 18px;
                    
                }
            }
            .listaCategoriaMobile {
                // border: 1px solid white;
                // margin: 10px 10px 10px 10px;
                padding: 0;
                display: inline-block;
                position: relative;
                width: 100%;
                height: 100%;
                background-color: #363030;
                border-radius: 6px;
                cursor: pointer;

                .divBtMenu {
                    background-color: #363030 !important;
                    border-radius: 6px;
                    margin: 0 !important;
                    height: 40px !important;
                    .btnMenu {
                        background-color: #363030 !important;
                        margin: 0 !important;
                        width: 100%;

                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        span {
                            // float: left; 
                            margin: 0 !important;
                            padding-left: 20px;
                            font-size: 18px;
                            font-weight: 300;
                        }

                        .icone-categoria {
                            // float:right;
                            padding-right: 20px;
                            
                        }
                    }
                }
    
    
                .listaBuscaMobile {
                    display: none;
                    position: absolute;
                    z-index: 1;
                }
    
                li {
                    margin: 10px;
                    padding: 10px;
                    position:relative;
    
                    border-right:1px solid #c0c0c0;
                }
            
                li:hover {
                    background-color: var(--bright-orange);
                    cursor: pointer;
                }
                .btnMenu {
                    background-color: var(--background-black-two) ;
                    // border: 1px solid var(--bright-orange);
                    position: absolute;
                    // width: 100% !important;
                    text-align: center;
                    text-justify: center;
                    align-items: center;
                    float: right;
                    margin: 0 10px 0px 10px;
                    width: 90%;
                }
    
                .divBtMenu {
                    display: flex;
                    flex-direction: row;
                    // width: 100% !important;
                    // border: 1px solid var(--bright-orange); 
                    justify-content: space-between;
                    align-content: center;
                    justify-items: center;
                    align-items: center;
                    height: 50px;
                    margin: 0 10px 0px 10px;
                    border-radius: 10px !important;
                    background-color: var(--background-black-two) ;
    
                    span {
                        color: #c0c0c0;
                    }
                }
    
            }
    
    
            .mobileItemCard {
                display: flex;
                flex-direction: column;
                width: 100% !important;
            } 
        }
    }
    
}

@media (min-width: 450px) {
    .contaMeusPontos {

        background: #F9F9F9; // #140f0f;
        
        .custom-menu {
            height: unset;
            width: 80%;
            margin: 0 auto;
            
            
            .menu-container {
                border-bottom: 0;
            }

            // .efeito-gradiente-topo {
            //     background: linear-gradient(180deg, #140f0f 10%, rgba(0, 0, 0, 0) 100%);
            // }
        }
        .divTitulo {
            // border: 3px solid #c0c0c0;
            display: flex;
            justify-items: center;
            align-items: center;
            background: url("../../../public/usemais/usemais-banner-web-cupons.png") no-repeat center;
            height: 300px;
            .tituloMeuspontos {
                display: flex;
                justify-items: center;
                align-items: center;             
                width: 80%;
                margin: 0 auto;           
                h1 {
                    display: flex;
                    font-size: 2rem;
                    justify-self: center;
                    align-self: center;
                }
             }

        }

        .container-filtros {
            display: flex;
            align-items: center;
            flex-direction: row;
            gap: 1rem;
            
            .conta-saldo-botao {
                width: 20%;
            }
    
            .conta-perfil-botao-extrato {
                border: 1px solid var(--bright-orange);
                border-radius: 6px !important;
                background-color: #363030 !important;
                width: 272px;
            }
        }
    
        .container-filtros-mobile {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            // padding: 10px 0;
            // height: 30px;
            .saldoOrdenar {
                margin-bottom: 24ṕx;

                .btn-saldo-pontos {
                    height: 40px;
                    border: 1px solid var(--bright-orange) !important;
                    background-color: #363030;
                    width: 60%;
                    border-bottom-left-radius: 6px !important;
                    border-top-left-radius: 6px !important;
                    margin: 0;
                }
                .botao-resgate-custom {
                    width: 40%;
                    height: 40px;
                }

            }

            .filtro-voucher-mobile {
                height: 40px;
                .busca-containerMobile {
                    height: 100%;
                    margin: 0;
                }
            }
            .conta-perfil-botao-extrato {
                // width: 100vh;
                border: 1px solid var(--bright-orange);
                border-radius: 10px !important;
                background-color: #363030 !important;
            }
    
            .filtroMobile {
                // flex-direction: row;
                align-self: center;
                justify-self: center;
                width: 100vh;
            }
            // }
    
            .conta-saldo-botao {
                // width: 20%;
            }
    
           
        }
    
        .resgate-seus-pontos {
            width: 80%;
            margin: 10px auto 0;
    
            .resgate-titulo {
                font-size: 2rem;
                font-weight: 500;
                font-stretch: normal;
                text-align: left;
                margin-left: calc(150 * var(--newSize));
                // color: var(--bright-orange);
            }
    
            .resgate-titulo-skeleton {
                font-size: 2rem;
                font-weight: 500;
                font-stretch: normal;
                text-align: left;
                margin-left: calc(150 * var(--newSize));
                width: calc(400 * var(--newSize));
                // color: var(--bright-orange);
            }
    
            .custom-lista-resgate-pontos {
                margin-top: 10px;
                justify-content: space-evenly;
                gap: 10px;

                .text {
                    font-size: 14px !important;
                    font-weight: 500 !important;
                    font-stretch: normal;
                }

                // width: 80%;
                .custom-card-resgate {
                    width: 185px;
                    height: 215px !important;
                    max-height: 220px;
                    padding-bottom: 10px !important;
                    margin: 0;
                    background-color: #FFF;

                    .card-resgate-total-pontos {
                        font-size: 12px !important;
                        height: 24px !important;
                        padding: 0 !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        // margin: 0 0 20px 0;
                    }
                    .card-resgate-pontos-body {
                        height: 185px !important;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    .botao-arredondado {
                        min-width: 10px !important;
                    }
                    .botao-resgate-custom {
                        width: 118px !important;
                        height: 24px !important;
                        align-items: center;
                        justify-content: center;
                        .text {
                            font-size: 11px !important;
                            font-weight: 500 !important;
                            font-stretch: normal;
                            line-height: 11px !important;
                        }
                    }
                }
            }
    
            .listaCategoria {
                // border: 1px solid white;
                padding: 0;
                
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    background-color: #1c1717;
                    width: 270px;
                    max-width: 270px;
                }
                li {
                    padding: 10px;
                    padding-left: 64px;
                    font-size: 14px;
                }
            
                li:hover {
                    background-color: var(--bright-orange);
                    // cursor: pointer;
                }

                li:not(:first-child) li:hover {
                    background-color: #1c1717;
                    cursor: pointer;
                    font-weight: 500;
                }
    
                li:first-of-type {      
                    background-color: #363030 !important;
                    font-size: 18px;
                    font-weight: 900;
                }

                li:first-child:hover {      
                    cursor: auto;
                }
            }
            .listaCategoriaMobile {
                // border: 1px solid white;
                // margin: 10px 10px 10px 10px;
                padding: 0;
                display: inline-block;
                position: relative;
                width: 100%;
                height: 100%;
                background-color: #363030;
                border-radius: 6px;
                cursor: pointer;

                .divBtMenu {
                    background-color: #363030 !important;
                    border-radius: 6px;
                    margin: 0 !important;
                    height: 40px !important;
                    .btnMenu {
                        background-color: #363030 !important;
                        margin: 0 !important;
                        width: 100%;

                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        span {
                            // float: left; 
                            margin: 0 !important;
                            padding-left: 20px;
                            font-size: 18px;
                            font-weight: 300;
                        }

                        .icone-categoria {
                            // float:right;
                            padding-right: 20px;
                            
                        }
                    }
                }
    
    
                .listaBuscaMobile {
                    display: none;
                    position: absolute;
                    z-index: 1;
                }
    
                li {
                    margin: 10px;
                    padding: 10px;
                    position:relative;
    
                    border-right:1px solid #c0c0c0;
                }
            
                li:hover {
                    background-color: var(--bright-orange);
                    cursor: pointer;
                }
                .btnMenu {
                    background-color: var(--background-black-two) ;
                    // border: 1px solid var(--bright-orange);
                    position: absolute;
                    // width: 100% !important;
                    text-align: center;
                    text-justify: center;
                    align-items: center;
                    float: right;
                    margin: 0 10px 0px 10px;
                    width: 90%;
                }
    
                .divBtMenu {
                    display: flex;
                    flex-direction: row;
                    // width: 100% !important;
                    // border: 1px solid var(--bright-orange); 
                    justify-content: space-between;
                    align-content: center;
                    justify-items: center;
                    align-items: center;
                    height: 50px;
                    margin: 0 10px 0px 10px;
                    border-radius: 10px !important;
                    background-color: var(--background-black-two) ;
    
                    span {
                        color: #c0c0c0;
                    }
                }
    
            }
    
    
            .mobileItemCard {
                display: flex;
                flex-direction: column;
                width: 100% !important;
            }
        }
    }
    
    
}

@media (min-width: 794px) {
    .contaMeusPontos {
        width: 100%;
        background: #F9F9F9; // #140f0f;
        
        .custom-menu {
            height: unset;
            width: 80%;
            margin: 0 auto;
            
            
            .menu-container {
                border-bottom: 0;
            }

            // .efeito-gradiente-topo {
            //     background: linear-gradient(180deg, #140f0f 10%, rgba(0, 0, 0, 0) 100%);
            // }
        }
    
        .divTitulo {
            // border: 3px solid #c0c0c0;
            display: flex;
            justify-items: center;
            align-items: center;
            background: url("../../../public/usemais/usemais-banner-web-cupons.png") no-repeat center;

            background-size: 100%;
            width: 100%;
            height: 300px;

            border-top: 1px solid #c0c0c0;

            .tituloMeuspontos {
                display: flex;
                justify-content: center;
                align-content: center;
                // margin-top: 10%;
            
                h1 {
                    font-size: 40px;
                }
            }
        }
     
    
        
    
        .container-filtros-mobile {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            padding: 10px 0;
            // gap: 2rem;
            
            // .grupoBotoes {
            //     width: 100%;
               
            //     border: 1px solid rgb(230, 16, 158);
            //     display: flex;
            //     flex-direction: column;
    
                .conta-perfil-botao-extrato {
                    // width: 100vh;
                    border: 1px solid var(--bright-orange);
                    border-radius: 10px !important;
                    background-color: #363030 !important;
                }
        
                .filtroMobile {
                    // flex-direction: row;
                    align-self: center;
                    justify-self: center;
                    width: 100vh;
                }
            // }
    
            .conta-saldo-botao {
                // width: 20%;
            }
    
           
        }
    
        .resgate-seus-pontos {
            // margin-top: calc(150 * var(--newSize));
            // margin-bottom: calc(181 * var(--newSize));

            width: 80%;
            margin: 0 auto;
            margin-top: 10px;

            .divResgates {
                display: flex;
                flex-direction: row;
                // justify-content: space-between;
                gap: 40px;
                width: 100%;

                .campoRetornoBusca {
                    display: flex;
                    width: 80%;
                    
                    // flex-direction: column;
                    // justify-content: end;
                    // align-content: end;
                    .divInputBusca {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        // justify-content: center;
                        // justify-self: center;
                        
                        
                        .breadcrumbMeusPontos {
                            margin-top: 10px;
                            font-size: 14px;
                            font-weight: 300;

                            span.filteredCategoria {
                                color: var(--bright-orange);
                            }

                            a.filteredCategoriaWhite {
                                color: #fff;
                                cursor: pointer;
                                text-decoration: none;
                            }
                        }
    
                        .container-filtros {
                            display: flex;
                            align-items: center;
                            flex-direction: row;
                            justify-content: flex-start;

                            .filtro-voucher-custom {
                                width: 50%;
                            }
                            
                            .filtro-voucher {
                                width: 50%;
                                .busca-container {
                                    // margin: 0 20px 0 50px;
                                    // width: 40% !important;
                                    .busca {
                                        // width: 50%;
                                        .input-busca {
                                            // width: 80%;
                                            .botao .lupa {
    
                                            }
                                        }
                                    }
                                }
                                .botao-resgate-custom {
                                    width: 40% !important;
                                }
                            }
                            .conta-saldo-botao {
                                width: 20%;
                            }
                    
                            .conta-perfil-botao-extrato {
                                border: 1px solid var(--bright-orange);
                                border-radius: 6px !important;
                                background-color: #363030 !important;
                                height: 50px;
                                width: 30% !important;
                            }
                        }
                    }
                }
                
            }
    
            .resgate-titulo {
                font-size: 2rem;
                font-weight: 500;
                font-stretch: normal;
                text-align: left;
                margin-left: calc(150 * var(--newSize));
                // color: var(--bright-orange);
            }
    
            .resgate-titulo-skeleton {
                font-size: 2rem;
                font-weight: 500;
                font-stretch: normal;
                text-align: left;
                margin-left: calc(150 * var(--newSize));
                width: calc(400 * var(--newSize));
                // color: var(--bright-orange);
            }
    
            .custom-lista-resgate-pontos {
               
                margin-top: 10px;
                justify-content: space-evenly;
                gap: 10px;

                .text {
                    font-size: 14px !important;
                    font-weight: 500 !important;
                    font-stretch: normal;
                }

                // width: 80%;
                .custom-card-resgate {
                    width: 185px;
                    height: 215px !important;
                    padding-bottom: 10px !important;
                    margin: 0;
                    background-color: #FFF;
                    justify-content: space-between;

                    .card-resgate-total-pontos {
                        font-size: 16px;
                        height: 24px;
                        // margin: 0 0 20px 0;
                    }

                    .card-resgate-pontos-body {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                    }

                    .botao-arredondado {
                        min-width: 10px !important;
                    }
                    .botao-resgate-custom {
                        width: 118px !important;
                        height: 24px !important;
                        align-items: center;
                        justify-content: center;
                        .text {
                            font-size: 11px !important;
                            font-weight: 500 !important;
                            font-stretch: normal;
                            line-height: 11px !important;
                        }
                    }
                }
            }
    
            .listaCategoria {
                // border: 1px solid white;
                padding: 0;
                
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    background-color: #1c1717;
                    width: 270px;
                    max-width: 270px;
                }
                li {
                    padding: 10px;
                    padding-left: 64px;
                    font-size: 14px;
                }
            
                li:hover {
                    background-color: var(--bright-orange);
                    // cursor: pointer;
                }

                li:not(:first-child) li:hover {
                    background-color: #1c1717;
                    cursor: pointer;
                    font-weight: 500;
                }
    
                li:first-of-type {      
                    background-color: #363030 !important;
                    font-size: 18px;
                    font-weight: 900;
                }

                li:first-child:hover {      
                    cursor: auto;
                }
            }
            // .listaCategoriaMobile {
            //     // border: 1px solid white;
            //     // margin: 10px 10px 10px 10px;
            //     padding: 0;
            //     display: inline-block;
            //     position: relative;
            //     width: 100%;
            //     height: 100%;
    
    
    
            //     .listaBuscaMobile {
            //         display: none;
            //         position: absolute;
            //         z-index: 1;
            //     }
    
            //     li {
            //         margin: 10px;
            //         padding: 10px;
            //         position:relative;
    
            //         border-right:1px solid #c0c0c0;
            //     }
            
            //     li:hover {
            //         background-color: var(--bright-orange);
            //         cursor: pointer;
            //     }
            //     .btnMenu {
            //         background-color: var(--background-black-two) ;
            //         // border: 1px solid var(--bright-orange);
            //         position: absolute;
            //         // width: 100% !important;
            //         text-align: center;
            //         text-justify: center;
            //         align-items: center;
            //         float: right;
            //         margin: 0 10px 0px 10px;
            //         width: 90%;
            //     }
    
            //     .divBtMenu {
            //         display: flex;
            //         flex-direction: row;
            //         // width: 100% !important;
            //         // border: 1px solid var(--bright-orange); 
            //         justify-content: space-between;
            //         align-content: center;
            //         justify-items: center;
            //         align-items: center;
            //         height: 50px;
            //         margin: 0 10px 0px 10px;
            //         border-radius: 10px !important;
            //         background-color: var(--background-black-two) ;
    
            //         span {
            //             color: #c0c0c0;
            //         }
            //     }
    
            // }
    
    
            .mobileItemCard {
                display: flex;
                flex-direction: column;
                width: 100% !important;
            }
        }
    }
    
}

@media (min-width: 1024px) {
    .contaMeusPontos {
        width: 100%;
        background: #F9F9F9; // #140f0f;
        
        .custom-menu {
            height: unset;
            width: 80%;
            margin: 0 auto;
            
            
            .menu-container {
                border-bottom: 0;
            }

            // .efeito-gradiente-topo {
            //     background: linear-gradient(180deg, #140f0f 10%, rgba(0, 0, 0, 0) 100%);
            // }
        }
    
        .divTitulo {
            // border: 3px solid #c0c0c0;
            display: flex;
            justify-items: center;
            align-items: center;
            background: url("../../../public/usemais/usemais-banner-web-cupons.png") no-repeat center;
            
            background-size: 100%;
            width: 100%;
            height: 300px;

            border-top: 1px solid #c0c0c0;

            .tituloMeuspontos {
                display: flex;
                justify-content: center;
                align-content: center;
                // margin-top: 10%;
            
                h1 {
                    font-size: 40px;
                }
            }
        }
     
    
        
    
        .container-filtros-mobile {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            padding: 10px 0;
            // gap: 2rem;
            
            // .grupoBotoes {
            //     width: 100%;
               
            //     border: 1px solid rgb(230, 16, 158);
            //     display: flex;
            //     flex-direction: column;
    
                .conta-perfil-botao-extrato {
                    // width: 100vh;
                    border: 1px solid var(--bright-orange);
                    border-radius: 10px !important;
                    background-color: #363030 !important;
                }
        
                .filtroMobile {
                    // flex-direction: row;
                    align-self: center;
                    justify-self: center;
                    width: 100vh;
                }
            // }
    
            .conta-saldo-botao {
                // width: 20%;
            }
    
           
        }
    
        .resgate-seus-pontos {
            // margin-top: calc(150 * var(--newSize));
            // margin-bottom: calc(181 * var(--newSize));

            width: 80%;
            margin: 0 auto;
            margin-top: 10px;

            .divResgates {
                display: flex;
                flex-direction: row;
                // justify-content: space-between;
                gap: 40px;
                width: 100%;

                .campoRetornoBusca {
                    display: flex;
                    width: 80%;
                    
                    // flex-direction: column;
                    // justify-content: end;
                    // align-content: end;
                    .divInputBusca {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        // justify-content: center;
                        // justify-self: center;
                        
                        
                        .breadcrumbMeusPontos {
                            margin-top: 10px;
                            font-size: 14px;
                            font-weight: 300;
                            max-width: 550px;

                            span.filteredCategoria {
                                color: var(--bright-orange);
                            }

                            a.filteredCategoriaWhite {
                                color: #fff;
                                cursor: pointer;
                                text-decoration: none;
                            }
                        }
    
                        .container-filtros {
                            display: flex;
                            align-items: center;
                            flex-direction: row;
                            justify-content: flex-start;
                            max-width: 550px;

                            .filtro-voucher-custom {
                                width: 65%;
                            }

                            .filtro-voucher {
                                // width: 50%;
                                .busca-container {
                                    // margin: 0 20px 0 50px;
                                    // width: 40% !important;
                                    .busca {
                                        // width: 50%;
                                        .input-busca {
                                            // width: 80%;
                                            .botao .lupa {
    
                                            }
                                        }
                                    }
                                }
                                .botao-resgate-custom {
                                    width: 40% !important;
                                    // width: 35% !important;
                                }
                            }
                            .conta-saldo-botao {
                                width: 20%;
                            }
                    
                            .conta-perfil-botao-extrato {
                                border: 1px solid var(--bright-orange);
                                border-radius: 6px !important;
                                background-color: #363030 !important;
                                height: 50px;
                                width: 100% !important;
                            }
                        }
                    }
                }
                
            }
    
            .resgate-titulo {
                font-size: 2rem;
                font-weight: 500;
                font-stretch: normal;
                text-align: left;
                margin-left: calc(150 * var(--newSize));
                // color: var(--bright-orange);
            }
    
            .resgate-titulo-skeleton {
                font-size: 2rem;
                font-weight: 500;
                font-stretch: normal;
                text-align: left;
                margin-left: calc(150 * var(--newSize));
                width: calc(400 * var(--newSize));
                // color: var(--bright-orange);
            }
    
            .custom-lista-resgate-pontos {
                margin: 0;
                margin-top: 10px;
                justify-content: flex-start;
                gap: 10px;
                max-width: 550px;

                .text {
                    font-size: 14px !important;
                    font-weight: 500 !important;
                    font-stretch: normal;
                }

                // width: 80%;
                .custom-card-resgate {
                    width: 170px;
                    height: 205px !important;
                    padding-bottom: 10px !important;
                    margin: 0;
                    background-color: #FFF;
                    justify-content: space-between;

                    .card-resgate-total-pontos {
                        font-size: 16px;
                        height: 24px;
                        // margin: 0 0 20px 0;
                    }

                    .card-resgate-pontos-body {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                    }

                    .botao-arredondado {
                        min-width: 10px !important;
                    }
                    .botao-resgate-custom {
                        width: 118px !important;
                        height: 24px !important;
                        align-items: center;
                        justify-content: center;
                        .text {
                            font-size: 11px !important;
                            font-weight: 500 !important;
                            font-stretch: normal;
                        }
                    }
                }
            }
    
            .listaCategoria {
                // border: 1px solid white;
                padding: 0;
                margin: 0;
                
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    background-color: #1c1717;
                    width: 270px;
                    max-width: 270px;
                }
                li {
                    padding: 10px;
                    padding-left: 64px;
                    font-size: 14px;
                }
            
                li:hover {
                    background-color: var(--bright-orange);
                    // cursor: pointer;
                }

                li:not(:first-child) li:hover {
                    background-color: #1c1717;
                    cursor: pointer;
                    font-weight: 500;
                }
    
                li:first-of-type {      
                    background-color: #363030 !important;
                    font-size: 18px;
                    font-weight: 900;
                }

                li:first-child:hover {      
                    cursor: auto;
                }
            }
            // .listaCategoriaMobile {
            //     // border: 1px solid white;
            //     // margin: 10px 10px 10px 10px;
            //     padding: 0;
            //     display: inline-block;
            //     position: relative;
            //     width: 100%;
            //     height: 100%;
    
    
    
            //     .listaBuscaMobile {
            //         display: none;
            //         position: absolute;
            //         z-index: 1;
            //     }
    
            //     li {
            //         margin: 10px;
            //         padding: 10px;
            //         position:relative;
    
            //         border-right:1px solid #c0c0c0;
            //     }
            
            //     li:hover {
            //         background-color: var(--bright-orange);
            //         cursor: pointer;
            //     }
            //     .btnMenu {
            //         background-color: var(--background-black-two) ;
            //         // border: 1px solid var(--bright-orange);
            //         position: absolute;
            //         // width: 100% !important;
            //         text-align: center;
            //         text-justify: center;
            //         align-items: center;
            //         float: right;
            //         margin: 0 10px 0px 10px;
            //         width: 90%;
            //     }
    
            //     .divBtMenu {
            //         display: flex;
            //         flex-direction: row;
            //         // width: 100% !important;
            //         // border: 1px solid var(--bright-orange); 
            //         justify-content: space-between;
            //         align-content: center;
            //         justify-items: center;
            //         align-items: center;
            //         height: 50px;
            //         margin: 0 10px 0px 10px;
            //         border-radius: 10px !important;
            //         background-color: var(--background-black-two) ;
    
            //         span {
            //             color: #c0c0c0;
            //         }
            //     }
    
            // }
    
    
            .mobileItemCard {
                display: flex;
                flex-direction: column;
                width: 100% !important;
            }
        }
    
    }
}

@media (min-width: 1200px) {
    .contaMeusPontos {
        width: 100%;
        background: #F9F9F9; // #140f0f;
        
        .custom-menu {
            height: unset;
            width: 80%;
            margin: 0 auto;
            
            
            .menu-container {
                border-bottom: 0;
            }

            .efeito-gradiente-topo {
                background: linear-gradient(180deg, #140f0f 10%, rgba(0, 0, 0, 0) 100%);
            }
        }
    
        .divTitulo {
            // border: 3px solid #c0c0c0;
            display: flex;
            justify-items: center;
            align-items: center;
            background: url("../../../public/usemais/usemais-banner-web-cupons.png") no-repeat;

            background-size: 100%;
            width: 100%;
            height: 520px;

            border-top: 1px solid #c0c0c0;

            .tituloMeuspontos {
                display: flex;
                justify-content: center;
                align-content: center;
                // margin-top: 10%;
            
                h1 {
                    font-size: 40px;
                }
            }
        }
     
    
        
    
        .container-filtros-mobile {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            padding: 10px 0;
            // gap: 2rem;
            
            // .grupoBotoes {
            //     width: 100%;
               
            //     border: 1px solid rgb(230, 16, 158);
            //     display: flex;
            //     flex-direction: column;
    
                .conta-perfil-botao-extrato {
                    // width: 100vh;
                    border: 1px solid var(--bright-orange);
                    border-radius: 10px !important;
                    background-color: #363030 !important;
                }
        
                .filtroMobile {
                    // flex-direction: row;
                    align-self: center;
                    justify-self: center;
                    width: 100vh;
                }
            // }
    
            .conta-saldo-botao {
                // width: 20%;
            }
    
           
        }
    
        .resgate-seus-pontos {
            // margin-top: calc(150 * var(--newSize));
            // margin-bottom: calc(181 * var(--newSize));

            width: 80%;
            margin: 0 auto;
            margin-top: 10px;

            .divResgates {
                display: flex;
                flex-direction: row;
                // justify-content: space-between;
                gap: 40px;
                width: 100%;

                .campoRetornoBusca {
                    display: flex;
                    width: 80%;
                    
                    // flex-direction: column;
                    // justify-content: end;
                    // align-content: end;
                    .divInputBusca {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        // justify-content: center;
                        // justify-self: center;
                        
                        
                        .breadcrumbMeusPontos {
                            margin-top: 10px;
                            font-size: 14px;
                            font-weight: 300;
                            max-width: 942px;

                            span.filteredCategoria {
                                color: var(--bright-orange);
                            }
                            a.filteredCategoriaWhite {
                                color: #fff;
                                cursor: pointer;
                                text-decoration: none;
                            }
                        }
    
                        .container-filtros {
                            display: flex;
                            align-items: center;
                            flex-direction: row;
                            max-width: 942px;
                            
                            .filtro-voucher {
                                width: 60%;
                                .busca-container {
                                    // margin: 0 20px 0 50px;
                                    // width: 40% !important;
                                    .busca {
                                        // width: 50%;
                                        .input-busca {
                                            // width: 80%;
                                            .botao .lupa {
    
                                            }
                                        }
                                    }
                                }
                                .botao-resgate-custom {
                                    // width: 35% !important;
                                    
                                }
                            }
                            .conta-saldo-botao {
                                width: 20%;
                            }
                    
                            .conta-perfil-botao-extrato {
                                border: 1px solid var(--bright-orange);
                                border-radius: 6px !important;
                                background-color: #363030 !important;
                                height: 50px;
                                width: 100% !important;
                            }
                        }
                    }
                }
                
            }
    
            .resgate-titulo {
                font-size: 2rem;
                font-weight: 500;
                font-stretch: normal;
                text-align: left;
                margin-left: calc(150 * var(--newSize));
                // color: var(--bright-orange);
            }
    
            .resgate-titulo-skeleton {
                font-size: 2rem;
                font-weight: 500;
                font-stretch: normal;
                text-align: left;
                margin-left: calc(150 * var(--newSize));
                width: calc(400 * var(--newSize));
                // color: var(--bright-orange);
            }
    
            .custom-lista-resgate-pontos {
               
                margin: 10px 0 !important;
                margin-top: 10px;
                justify-content: flex-start;
                gap: 10px;
                width: 100% !important;
                max-width: 942px !important;

                .text {
                    font-size: 14px !important;
                    font-weight: 500 !important;
                    font-stretch: normal;
                }

                // width: 80%;
                .custom-card-resgate {
                    width: 180px;
                    height: 210px !important;
                    padding-bottom: 10px !important;
                    margin: 0;
                    background-color: #FFF;
                    justify-content: space-between;

                    .card-resgate-total-pontos {
                        font-size: 16px;
                        height: 24px;
                        // margin: 0 0 20px 0;
                    }

                    .card-resgate-pontos-body {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                    }

                    .botao-arredondado {
                        min-width: 10px !important;
                    }
                    .botao-resgate-custom {
                        width: 118px !important;
                        height: 24px !important;
                        align-items: center;
                        justify-content: center;
                        .text {
                            font-size: 11px !important;
                            font-weight: 500 !important;
                            font-stretch: normal;
                        }
                    }
                }
            }
    
            .listaCategoria {
                // border: 1px solid white;
                padding: 0;
                margin: 0;
                
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    background-color: #1c1717;
                    width: 270px;
                    max-width: 270px;
                }
                li {
                    padding: 10px;
                    padding-left: 64px;
                    font-size: 14px;
                }
            
                li:hover {
                    background-color: var(--bright-orange);
                    // cursor: pointer;
                }

                li:not(:first-child) li:hover {
                    background-color: #1c1717;
                    cursor: pointer;
                    font-weight: 500;
                }
    
                li:first-of-type {      
                    background-color: #363030 !important;
                    font-size: 18px;
                    font-weight: 900;
                }

                li:first-child:hover {      
                    cursor: auto;
                }
            }
            // .listaCategoriaMobile {
            //     // border: 1px solid white;
            //     // margin: 10px 10px 10px 10px;
            //     padding: 0;
            //     display: inline-block;
            //     position: relative;
            //     width: 100%;
            //     height: 100%;
    
    
    
            //     .listaBuscaMobile {
            //         display: none;
            //         position: absolute;
            //         z-index: 1;
            //     }
    
            //     li {
            //         margin: 10px;
            //         padding: 10px;
            //         position:relative;
    
            //         border-right:1px solid #c0c0c0;
            //     }
            
            //     li:hover {
            //         background-color: var(--bright-orange);
            //         cursor: pointer;
            //     }
            //     .btnMenu {
            //         background-color: var(--background-black-two) ;
            //         // border: 1px solid var(--bright-orange);
            //         position: absolute;
            //         // width: 100% !important;
            //         text-align: center;
            //         text-justify: center;
            //         align-items: center;
            //         float: right;
            //         margin: 0 10px 0px 10px;
            //         width: 90%;
            //     }
    
            //     .divBtMenu {
            //         display: flex;
            //         flex-direction: row;
            //         // width: 100% !important;
            //         // border: 1px solid var(--bright-orange); 
            //         justify-content: space-between;
            //         align-content: center;
            //         justify-items: center;
            //         align-items: center;
            //         height: 50px;
            //         margin: 0 10px 0px 10px;
            //         border-radius: 10px !important;
            //         background-color: var(--background-black-two) ;
    
            //         span {
            //             color: #c0c0c0;
            //         }
            //     }
    
            // }
    
    
            .mobileItemCard {
                display: flex;
                flex-direction: column;
                width: 100% !important;
            }
        }
    }
    
}