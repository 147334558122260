@import "../../assets/styles/variaveis.scss";

.conteudo-post {
    .conteudo-post-fundo {
        height: calc(500 * var(--newSize));
    }

    .conteudo-post-imagem-destaque {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .conteudo-post-menu {
        height: calc(600 * var(--newSize));
    }

    .conteudo-post-body {
        width: calc(1252 * var(--newSize));
        background-color: rgba(100, 100, 100, 0.3);
        padding-left: calc(218 * var(--newSize));
        box-sizing: border-box;
    }

    .colunas {
        display: flex;
        flex-wrap: nowrap;
    }
    .mobile {
        display: none;
    }

    .titulo-pagina {
        padding-top: calc(
            86 * var(--newSize)
        ); // Desconto da altura do menu (86px), para parecer que o texto está centralizado com a imagem de fundo
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .titulo-principal {
            font-size: 3rem;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: var(--white-one);
        }
    }
}

@media (max-width: $max-width-mobile) {
    .conteudo-post {
        .mobile {
            width: calc(326 * var(--newSize));
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .card-post-publicidade {
                // width: calc(221 * var(--newSize));
                margin-left: calc(71 * var(--newSize));
                margin-top: calc(43 * var(--newSize));
                // height: calc(184 * var(--newSize));

                .card-post-publicidade-label {
                    margin-bottom: 0; //calc(25 * var(--newSize));
                    font-size: 0.62rem;
                    font-weight: 500;
                    color: var(--warm-grey);
                }
            }
        }

        .colunas {
            display: none;
        }
        .conteudo-post-fundo {
            height: calc(150 * var(--newSize));
        }

        .conteudo-post-menu {
            height: calc(200 * var(--newSize));
        }

        .conteudo-post-body {
            width: calc(327 * var(--newSize)) !important;
            background-color: rgba(100, 100, 100, 0.3);
            padding-left: calc(218 * var(--newSize));
            box-sizing: border-box;
        }

        .titulo-pagina {
            padding-top: 0 !important; //calc(46 * var(--newSize)) !important; // Desconto da altura do menu (86px), para parecer que o texto está centralizado com a imagem de fundo
            margin-top: calc(44 * var(--newSize));
            margin-left: calc(78 * var(--newSize));
            margin-right: calc(75 * var(--newSize));

            .titulo-principal {
                font-size: 1.37rem !important;
            }
        }
    }
}
