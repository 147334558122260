.input-check {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    .input-and-label {
        display: flex;
        flex-direction: row;

        input {
            margin-right: 16px;
        }
    }

    .input-label {
        margin-top: 10px;
        font-size: 1rem;
        font-weight: 300;
        line-height: 1;
        letter-spacing: normal;
        color: var(--warm-grey);
        padding-left: calc(16 * var(--newSize));
    }
    .input-error {
        margin-top: 10px;
        font-size: 1rem;
        font-weight: 300;
        line-height: 1;
        letter-spacing: normal;
        color: var(--bright-orange);
        padding-left: calc(16 * var(--newSize));
    }

    select {
        flex: 1 0;
        padding: calc(20 * var(--newSize)) calc(16 * var(--newSize)) calc(15 * var(--newSize)) calc(16 * var(--newSize));
        border-radius: 8px;
        border: solid 1px var(--warm-grey);
        background-color: var(--background-black-two);
        font-family: Roboto;
        font-size: 1rem;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: var(--white-one);
    }
}
