body {
    font-family: 'Inter', sans serif !important;
    background-color: #FFFFFF !important;
}

// body {
//     background: white !important;
// }

.homeScreen{
    
}