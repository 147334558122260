@import "../../../assets/styles/variaveis.scss";

.coluna-rodape-links {
    padding: 20px;

    .titulo {
        font-family: Roboto;
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.03;
        letter-spacing: normal;
        text-align: left;
        color: var(--warm-grey);
        height: 60px;
    }

    .subtitulo {
        color: var(--bright-orange) !important;
    }

    .items {
        font-family: Roboto;
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 42px;
        letter-spacing: normal;
        text-align: left;
        color: var(--white-one);
        text-transform: uppercase;

        a:visited,
        a:link {
            color: var(--white-one);
            text-decoration: none;
        }
        a:hover {
            color: var(--bright-orange);
        }
    }
}

@media (max-width: $max-width-mobile) {
    .coluna-rodape-links {
        padding: 20px;

        .titulo::before {
            font-size: 1rem;
            content: "+ ";
            vertical-align: middle;
        }
        .titulo {
            font-family: Roboto;
            font-size: 0.65rem;
            vertical-align: middle;
            height: 1rem;
        }

        .subtitulo {
            color: var(--bright-orange) !important;
        }

        .items {
            div:first-child {
                margin-top: calc(27 * var(--newSize));
            }
            font-size: 0.65rem;
            line-height: 50px;
        }
    }
}
