@import "../../../assets/styles/variaveis.scss";
.share-button {
    cursor: pointer;

    img {
        width: calc(49 * var(--newSize));
    }

    .share-button-options {
        button {
            margin-left: 5px;
        }
    }
}

@media (max-width: $max-width-mobile) {
    .share-button {
        img {
            width: calc(25 * var(--newSize));
        }

        .share-button-options {
            display: flex;
            flex-wrap: wrap;

            button {
                margin-left: 15px;
                margin-bottom: 15px;
            }
        }
    }
}
