.banner-fundo {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 0;

    .efeito-gradiente {
        display: flex;
        position: relative;
        width: 100%;
        height: calc(150 * var(--newSize));
        background: rgb(0, 0, 0);
        margin-top: calc(-150 * var(--newSize));
        z-index: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 100%);
    }

    // @media (max-width: 1366px) {
    //     .efeito-gradiente {
    //         height: 70px;
    //         margin-top: -70px;
    //     }
    // }


    @media screen and (max-width: 992px) {
        // mobile
        .efeito-gradiente {
            display: flex;
            position: relative;
            width: 100%;
            
            height: 30px;
            background: rgb(0, 0, 0);
            margin-top: -30px;
            z-index: 0;
    
            background: linear-gradient(0deg, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 100%);
        }
    }
}
