@import "../../assets/styles/variaveis.scss";
body {
    // background: black;
    background-color: #140f0f;
}


@media (max-width: 450px) {
    .conta-usuario {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-content: center;
        .custom-menu {
            height: auto !important;
        }
        // justify-content: space-between;
        // margin: 0 auto;
        .dados-conta {
            display: flex;
            flex-direction: column;
            
            .topo {
                display: flex;
                flex-direction: column;
                // align-content: center;
                // gap: 20px;
                height: 100%;
                .topo-item {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 50%;
                }
                .topo-item:not(:last-child) {
                    border-right: none;
                }

                .topo-item2 {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 50%;
    
                   
                }
                .Line-2 {
                    width: 171px;
                    height: 1px;
                    margin: 40px 0 40px 0;
                    background-color: rgba(140, 140, 140, 0.5);
                    align-self: center;
                    justify-self: center;
                  }
            }

        }
        .resgate-seus-pontos {
            margin-top: calc(0 * var(--newSize));
            margin-bottom: calc(34 * var(--newSize));

            .resgate-titulo {
                font-size: 1.45rem;
                margin-left: unset;
                text-align: center;
            }

            .filtro-voucher {
                text-align: left;
                margin-left: unset;
            }

            .custom-lista-resgate-pontos {
                margin-top: calc(30 * var(--newSize));
                margin-left: calc(50 * var(--newSize));;
            }
        }

        .Line-3 {
            width: 171px;
            height: 1px;
            margin: 60px 0 60px 0;
            background-color: rgba(140, 140, 140, 0.5);
            align-self: center;
            justify-self: center;
          }
    }
}


@media (min-width: 450px) {
    .conta-usuario {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-content: center;
        margin: 0 auto;
        .custom-menu {
            height: auto !important;
        }
        // justify-content: space-between;
        // margin: 0 auto;
        .dados-conta {
            display: flex;
            flex-direction: column;
            
            .topo {
                display: flex;
                flex-direction: column;
                // align-content: center;
                // gap: 20px;
                height: 100%;
                .topo-item {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 50%;
                }
                .topo-item:not(:last-child) {
                    border-right: none;
                }

                .topo-item2 {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 50%;
    
                   
                }
            }

        .Line-2 {
            width: 171px;
            height: 1px;
            margin: 38.7px 0 60px 0;
            background-color: rgba(140, 140, 140, 0.5);
            align-self: center;
            justify-self: center;
          }
        }
        .resgate-seus-pontos {
            margin-top: calc(0 * var(--newSize));
            margin-bottom: calc(34 * var(--newSize));

            .resgate-titulo {
                font-size: 1.45rem;
                margin-left: unset;
                text-align: center;
            }

            .filtro-voucher {
                text-align: left;
                margin-left: unset;
            }

            .custom-lista-resgate-pontos {
                margin-top: calc(30 * var(--newSize));
                margin-left: calc(50 * var(--newSize));;
            }
        }
    }
}



@media (min-width: 794px){
    .conta-usuario {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-content: center;
        justify-content: space-between;
        margin: 0 auto;
        .dados-conta {
            display: flex;
            flex-direction: column;
            
            .topo {
                display: flex;
                flex-direction: column;
                // align-content: center;
                // gap: 20px;
                height: 100%;
                .topo-item {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 50%;
                }
                .topo-item:not(:last-child) {
                    border-right: none;
                }

                .topo-item2 {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 50%;
    
                   
                }
            }
        }
        .resgate-seus-pontos {
            margin-top: calc(0 * var(--newSize));
            margin-bottom: calc(34 * var(--newSize));

            .resgate-titulo {
                font-size: 1.45rem;
                margin-left: unset;
                text-align: center;
            }

            .filtro-voucher {
                text-align: left;
                margin-left: unset;
            }

            .custom-lista-resgate-pontos {
                margin-top: calc(30 * var(--newSize));
                margin-left: calc(50 * var(--newSize));;
            }
        }

        .Line-3 {
            display: none;
        }
    }
}


@media (min-width: 1024px) {
    .conta-usuario {

        display: flex;
        flex-direction: column;
        width: 90%;
        align-content: center;
        justify-content: space-between;
        margin: 0 auto;
    
        .flew-item {
            justify-content: center;
        }
        .custom-menu {
            height: unset;
        }
    
        .dados-conta {
            margin: 5%;
            display: flex;
            flex-direction: row;
            justify-content: center;
    
            .topo {
                padding-top: 5%;
                position: relative;
                // height: calc(224 * var(--newSize));
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                .Line-2 {
                    display: none;
                  }
                .topo-item {
                    width: 45%;
                    // height: 400px;
                    //background-color: rgba(50, 50, 50, 0.5);
                }
                .topo-item2 {
                    width: 45%;
                    // height: 400px;
                    justify-content: end;
    
                   
                }
                .topo-item:not(:last-child) {
                    border-right: solid 1px rgba(255, 255, 255, 0.25);
                }
            }
        }
    
        .container-filtros {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            padding: 10px 0;
            gap: 1rem;
            
            .conta-saldo-botao {
                width: 20%;
            }
        }
    
        .resgate-seus-pontos {
            margin-top: calc(150 * var(--newSize));
            margin-bottom: calc(181 * var(--newSize));
    
            .resgate-titulo {
                font-size: 2rem;
                font-weight: 500;
                font-stretch: normal;
                text-align: left;
                margin-left: calc(150 * var(--newSize));
                // color: var(--bright-orange);
            }
    
            .resgate-titulo-skeleton {
                font-size: 2rem;
                font-weight: 500;
                font-stretch: normal;
                text-align: left;
                margin-left: calc(150 * var(--newSize));
                width: calc(400 * var(--newSize));
                // color: var(--bright-orange);
            }
    
            .custom-lista-resgate-pontos {
                margin-top: calc(62 * var(--newSize));
            }
    
            .filtro-voucher {
                text-align: left;
                // margin-left: calc(150 * var(--newSize));
            }
    
        }

        .Line-3 {
            display: none;
        }
    }
}


@media (min-width: 1200px) {

    .conta-usuario {

        display: flex;
        flex-direction: column;
        width: 75%;
        align-content: center;
        justify-content: space-between;
        margin: 0 auto;
    
        .flew-item {
            justify-content: center;
        }
        .custom-menu {
            height: unset;
        }
    
        .dados-conta {
            margin: 5%;
            display: flex;
            flex-direction: row;
            justify-content: center;
    
            .topo {
                padding-top: 5%;
                position: relative;
                // height: calc(224 * var(--newSize));
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
    
                .Line-2 {
                    display: none;
                  }
                .topo-item {
                    width: 45%;
                    // height: 400px;
                    //background-color: rgba(50, 50, 50, 0.5);
                }
                .topo-item2 {
                    width: 45%;
                    // height: 400px;
                    justify-content: end;
    
                   
                }
                .topo-item:not(:last-child) {
                    border-right: solid 1px rgba(255, 255, 255, 0.25);
                }
            }
        }
    
        .container-filtros {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            padding: 10px 0;
            gap: 1rem;
            
            .conta-saldo-botao {
                width: 20%;
            }
        }
    
        .resgate-seus-pontos {
            margin-top: calc(150 * var(--newSize));
            margin-bottom: calc(181 * var(--newSize));
    
            .resgate-titulo {
                font-size: 2rem;
                font-weight: 500;
                font-stretch: normal;
                text-align: left;
                margin-left: calc(150 * var(--newSize));
                // color: var(--bright-orange);
            }
    
            .resgate-titulo-skeleton {
                font-size: 2rem;
                font-weight: 500;
                font-stretch: normal;
                text-align: left;
                margin-left: calc(150 * var(--newSize));
                width: calc(400 * var(--newSize));
                // color: var(--bright-orange);
            }
    
            .custom-lista-resgate-pontos {
                margin-top: calc(62 * var(--newSize));
            }
    
            .filtro-voucher {
                text-align: left;
                // margin-left: calc(150 * var(--newSize));
            }
    
        }

        .Line-3 {
            display: none;
        }
    }
}






