@import "../../assets/styles/variaveis.scss";

body {
    background: black;
}

.lista-noticias {
    display: flex;
    // justify-content: space-around;
    flex-direction: column;
}

.title-noticias {
    padding: 0 15rem;
}

.title-notice {
    display: flex;
    font-size: 2rem;
    font-weight: bolder;

    justify-content: flex-start;
    align-items: baseline;
    flex-direction: column;

    p {
        margin-bottom: 1rem;
    }

}

.description-notice {
    display: flex;
    font-size: 2rem;
    justify-content: space-between;
    border-left: 0.3rem solid #ff6a00;
    p {
        margin-left: 5px;
        margin-block-end: 0;
        margin-block-start: 0;
    }

    a {
        text-align: right;
        color: #ff6a00;
        font-size: 1.8rem;
    }
}

.lista-components {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    // padding: 0 10rem;
}

.new-lista-components {
    display: flex;
    flex-direction: row;
    justify-content: center;
    // padding: 0 10rem;
}

@media (max-width: $max-width-mobile) {
    .new-lista-components {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // padding: 0 10rem;
    }
    .lista-noticias {
        display: flex;
        // justify-content: space-around;
        flex-direction: column;
        align-items: center;
    }
    
    .title-noticias {
        padding: 0;
        width: calc(320 * var(--newSize))
    }
    
    .title-notice {
        display: flex;
        font-size: 1.5rem;
        font-weight: bolder;
    
        justify-content: left;
        align-items: baseline;
        flex-direction: column;

        padding: 0 1rem;
    
        p {
            // margin-bottom: 1rem;
        }
    
    }
    
    .description-notice {
        display: flex;
        font-size: 1.5rem;
        justify-content: space-between;
        margin: 0 0 1rem;

        p {
            margin-left: 5px;
            margin-block-end: 0;
            margin-block-start: 0;
        }
    
        a {
            text-align: right;
            color: #ff6a00;
            font-size: 1.3rem;
        }
    }
    
    .lista-components {
        display: flex;
        flex-direction: column;
        align-items: center;
        // padding: 0 10rem;
    }
}