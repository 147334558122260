@import "../../../assets/styles/variaveis.scss";

.galeria-imagens {
    padding: 0px calc(217 * var(--newSize)) calc(66 * var(--newSize)) calc(217 * var(--newSize));
    display: flex;
    //min-width: calc(1200 * var(--newSize));
    flex-direction: column;

    background-color: #000;

    .galeria-imagens-titulo {
        font-family: Roboto;
        font-size: 4rem;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--white-one);
    }

    .galeria-imagens-lista {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;

        overflow-y: auto;
        margin-top: calc(100 * var(--newSize));
    }

    .galeria-imagens-item {
        margin-top: calc(21 * var(--newSize));
    }
    .galeria-imagens-item:not(:last-child) {
        margin-right: calc(21 * var(--newSize));
    }

    .galeria-imagens-visualizacao {
        background-color: rgba(0, 0, 0, 0.9);
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1001;
        justify-content: center;
        text-align: center;

        .galeria-imagens-preview-item {
            height: 100vh;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            img {
                height: calc(700 * var(--newSize));
            }
            .galeria-preview-close {
                position: relative;
                top: calc(30 * var(--newSize));
                min-width: calc(50 * var(--newSize));
                min-height: calc(50 * var(--newSize));
                height: calc(50 * var(--newSize));
            }
        }
    }
}

@media (max-width: $max-width-mobile) {
    .galeria-imagens {

        


        margin-top: calc(82 * var(--newSize));
        padding: 0 2rem;
        // margin-left: calc(14 * var(--newSize));
        // width: calc(1200 * var(--newSize));

        .galeria-imagens-titulo {
            font-size: 1rem;
        }

        .galeria-imagens-lista {
            margin-top: calc(14 * var(--newSize));
        }

        .galeria-imagens-item:not(:last-child) {
            margin-right: calc(6 * var(--newSize));
        }

        .galeria-imagens-visualizacao {
            .galeria-imagens-preview-item {
                img {
                    height: calc(190 * var(--newSize));
                }
            }
        }
    }
}
