@import "../../assets/styles/variaveis.scss";
.como-funciona-menu {
    height: calc(624 * var(--newSize)) !important;
}
.como-funciona-fundo {
    height: calc(624 * var(--newSize)) !important;
    .como-funciona-titulo {
        color: #ffffff;
        letter-spacing: -1px;
        font-size: 6rem;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        font-size: 3.43rem;

        span {
            display: block;
        }
    }
}

.como-funciona-body {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;

    .como-funciona-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 90%;
        max-width: calc(1400 * var(--newSize));

        b,
        strong {
            font-weight: bolder;
        }

        p {
            font-family: Roboto;
            font-size: 1.46rem;
            line-height: 1.75rem;
        }

        .resumo {
            display: flex;
            flex-direction: column;

            font-size: 1.49rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.81;
            letter-spacing: normal;
            text-align: center;
            //margin: 0 calc(422 * var(--newSize)) 0 calc(422 * var(--newSize)) ;
            // width: calc(920 * var(--newSize));

            color: var(--white-one);

            .titulo-resumo {
                text-align: start !important;
                color: #FF6A00;
                letter-spacing: -1px;
                font-size: 6rem;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                font-size: 3.43rem;
                display: flex;
                margin-bottom: 5rem;

            }

            .resumo-content {

                display: flex;
                flex-direction: row;
                margin-bottom: 5rem;
                gap: 2rem;

                .video-player {
                    flex: 50%;
                    div {
                        min-width: 80vmin;
                        min-height: 80vmin;
                    }
                }
    
                .texto-informativo {
                    flex: 50%;
                    text-align: left;
                    font-size: 1.28rem;
                    min-height: 80vmin;



                    .title-informativo {
                        text-align: start !important;
                        color: #FF6A00;
                        letter-spacing: -1px;
                        font-size: 10rem;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: center;
                        font-size: 3.43rem;
                        display: flex;
                        margin-bottom: 5rem;
                    }
                }
            }

            
        }

        .seta-baixo {
            margin-top: calc(58 * var(--newSize));
            width: calc(28 * var(--newSize));
            height: calc(17 * var(--newSize));
        }

        .sessao-1 {
            display: flex;
            flex-direction: row;
            margin-top: calc(173 * var(--newSize));

            img {
                width: 100%;
                margin-left: 3rem;
            }

            .parte-1 {
                flex: 1;
                padding-right: 1.31rem;

                .titulo-parte-1 {
                    color: #ff6a00 !important;
                    font-size: 3.5rem;
                    font-weight: 900;
                    //margin-top: calc(157 * var(--newSize));
                    width: 36rem;
                    font-family: Roboto;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                }

                .texto-parte-1 {
                    margin-top: calc(33 * var(--newSize));
                    font-family: Roboto;
                    font-size: 1.28rem;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.78;
                    letter-spacing: normal;
                    text-align: left;
                    width: calc(430 * var(--newSize));
                }
            }

            .parte-2 {
                flex: 2;
            }
        }

        .linha-sessao-1 {
            margin-top: calc(87 * var(--newSize));
            width: calc(1642 * var(--newSize));
            height: calc(104 * var(--newSize));
            margin-left: calc(177 * var(--newSize));
            margin-right: calc(176 * var(--newSize));
            img {
                width: 100%;
            }
        }

        .sessao-2 {
            display: flex;
            flex-direction: row;
            margin-top: calc(110 * var(--newSize));

            img {
                width: 100%;
                margin-right: 3rem;
            }

            .parte-1 {
                flex: 2;
                padding-right: 0.62rem;
            }

            .parte-2 {
                flex: 1;

                .titulo-parte-2 {
                    color: #ff6a00 !important;
                    font-size: 3.09rem;
                    font-weight: 900;
                    // margin-top: calc(146 * var(--newSize));
                    width: 21.5rem;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                }

                .texto-parte-2 {
                    margin-top: calc(33 * var(--newSize));
                    font-size: 1.28rem;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.78;
                    letter-spacing: normal;
                    text-align: left;
                    color: var(--white-one);
                    width: calc(485 * var(--newSize));
                }
            }

            .sessao-2-mobile {
                display: none;
            }
        }

        .linha-sessao-2 {
            margin-top: calc(44 * var(--newSize));
            width: calc(1567 * var(--newSize));
            // height: calc(166 * var(--newSize));
            margin-left: calc(177 * var(--newSize));
            margin-right: calc(176 * var(--newSize));
            img {
                width: 100%;
            }
        }

        .sessao-3 {
            display: flex;
            flex-direction: row;
            margin-top: calc(97 * var(--newSize));

            img {
                width: 100%;
                margin-left: 3rem;
            }

            .parte-1 {
                padding-right: 2.56rem;
                flex: 1;

                .titulo-parte-3 {
                    color: #ff6a00 !important;
                    font-size: 3.09rem;
                    font-weight: 900;
                    // margin-top: calc(146 * var(--newSize));
                    width: 28.37rem;
                    font-family: Roboto;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                }

                .texto-parte-3 {
                    margin-top: calc(32 * var(--newSize));
                    font-family: Roboto;
                    font-size: 1.34rem;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.78;
                    letter-spacing: normal;
                    text-align: left;
                    color: var(--white-one);
                    width: calc(476 * var(--newSize));
                }
            }

            .parte-2 {
                flex: 2;
                text-align: right;
                margin-left: calc(41 * var(--newSize));
            }

            .sessao-3-mobile {
                display: none;
            }
        }

        .sessao-4 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: calc(216 * var(--newSize));

            .titulo-parte-4 {
                color: #ff6a00 !important;
                font-size: 3.09rem;
                font-weight: 900;
                width: 55rem;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
            }

            .texto-parte-4 {
                margin-top: calc(37 * var(--newSize));
                font-size: 2rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                color: var(--white-one);
                width: calc(780 * var(--newSize));
            }
        }

        .engrenagem {
            margin-top: calc(55 * var(--newSize));
            width: calc(106 * var(--newSize));
            height: calc(105 * var(--newSize));
        }

        .retangulo {
            width: 1204px;
            height: 492px;
            margin: 205px 359px 15px 357px;
            padding: 74px 264px 70px 143px;
            opacity: 0.25;
            border-radius: 12px;
            border: solid 1px var(--white);
            background-color: var(--background-black-two);
        }

        .sessao-5 {
            display: flex;
            flex-direction: column;
            margin-top: calc(97 * var(--newSize));
            border-radius: 12px;
            border: solid 1px var(--warm-grey);

            width: calc(1204 * var(--newSize));
            height: calc(550 * var(--newSize));
            span {
                color: #ff6a00 !important;
                font-weight: 900;
            }

            .titulo-parte-5 {
                margin-top: calc(54 * var(--newSize));
                color: #ff6a00 !important;
                font-size: 1.56rem;
                font-weight: 900;
                // margin-top: calc(146 * var(--newSize));
                width: 28.37rem;
                font-family: Roboto;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                margin-left: calc(144 * var(--newSize));
                margin-right: calc(264 * var(--newSize));
            }

            .texto-parte-5-1 {
                margin-top: calc(56 * var(--newSize));
                font-family: Roboto;
                font-size: 1.46rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.55;
                letter-spacing: normal;
                text-align: left;
                color: var(--white);
                margin-left: calc(144 * var(--newSize));
                margin-right: calc(264 * var(--newSize));

                span {
                    color: #ff6a00 !important;
                }
            }

            .texto-parte-5-2 {
                margin-top: calc(59 * var(--newSize));
                font-family: Roboto;
                font-size: 1.46rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.55;
                letter-spacing: normal;
                text-align: left;
                color: var(--white);
                margin-left: calc(144 * var(--newSize));
                margin-right: calc(264 * var(--newSize));

                span {
                    color: #ff6a00 !important;
                }
            }

            .texto-parte-5-3 {
                margin-top: calc(50 * var(--newSize));
                font-family: Roboto;
                font-size: 1.46rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.55;
                letter-spacing: normal;
                text-align: left;
                color: var(--white);
                margin-left: calc(144 * var(--newSize));
                margin-right: calc(264 * var(--newSize));

                span {
                    color: #ff6a00 !important;
                }
            }
        }

        .sessao-6 {
            display: flex;
            flex-direction: column;
            margin-top: calc(15 * var(--newSize));
            border-radius: 12px;
            border: solid 1px var(--warm-grey);

            width: calc(1204 * var(--newSize));
            height: calc(480 * var(--newSize));
            span {
                color: #ff6a00 !important;
                font-weight: 900;
            }

            .titulo-parte-6 {
                margin-top: calc(54 * var(--newSize));
                color: #ff6a00 !important;
                font-size: 1.56rem;
                font-weight: 900;
                // margin-top: calc(146 * var(--newSize));
                width: 32.37rem;
                font-family: Roboto;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                margin-left: calc(144 * var(--newSize));
                margin-right: calc(264 * var(--newSize));
            }

            .texto-parte-6-1 {
                margin-top: calc(56 * var(--newSize));
                font-size: 1.46rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.55;
                letter-spacing: normal;
                text-align: left;
                color: var(--white);
                margin-left: calc(144 * var(--newSize));
                margin-right: calc(264 * var(--newSize));

                width: 32.37rem;
                width: 100%;

                span {
                    color: #ff6a00 !important;
                }
            }

            .texto-parte-6-2 {
                margin-top: calc(59 * var(--newSize));
                font-family: Roboto;
                font-size: 1.46rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.55;
                letter-spacing: normal;
                text-align: left;
                color: var(--white);
                margin-left: calc(144 * var(--newSize));
                margin-right: calc(264 * var(--newSize));

                span {
                    color: #ff6a00 !important;
                }
            }

            .texto-parte-6-3 {
                margin-top: calc(50 * var(--newSize));
                font-family: Roboto;
                font-size: 1.46rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.55;
                letter-spacing: normal;
                text-align: left;
                color: var(--white);
                margin-left: calc(144 * var(--newSize));
                margin-right: calc(264 * var(--newSize));

                span {
                    color: #ff6a00 !important;
                }
            }
        }

        .sessao-7 {
            flex-direction: row;

            .titulo-parte-7 {
                color: #ff6a00 !important;
                font-size: 1.93rem;
                margin-top: calc(77 * var(--newSize));
                // margin-left: calc(531 * var(--newSize));
                // margin-right: calc(530 * var(--newSize));
                width: 53rem;
                font-family: Roboto;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
            }
        }
    }
}

@media (max-width: $max-width-mobile) {
    .como-funciona-menu {
        height: calc(208 * var(--newSize)) !important;
    }
    .como-funciona-fundo {
        height: calc(208 * var(--newSize)) !important;
        //height: calc(208 * 0.5px) !important;
        .como-funciona-titulo {
            font-size: 1.5rem;

            font-weight: 900;
            span {
                display: block;
            }
        }
    }
    .como-funciona-body {

        margin-bottom: 10px;
        
        .como-funciona-container {
            b,
            strong {
                font-weight: bolder;
            }

            p {
                font-family: Roboto;
                font-size: 1.46rem;
                line-height: 1.75rem;
            }

            .resumo {
                display: flex;
                flex-direction: column;
                padding-top: 3rem;
    
                .titulo-resumo {
                    color: #FF6A00;
                    letter-spacing: -1px;
                    font-size: 1.5rem;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    justify-content: center;
                    display: flex;
                    margin-bottom: 2rem;
                    margin-top: 4rem;
                }
    
                .resumo-content {
    
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 1rem;
                    gap: 0;
    
                    .video-player {
                        flex: 50%;
                        width: 100%;
                        height: 100%;
                    }
        
                    .texto-informativo{
                        flex: 50%;

                        margin-top: calc(12 * var(--newSize));
                        font-family: Roboto;
                        font-size: 1rem;
                        line-height: 1.5;
                        letter-spacing: normal;
                        text-align: center;
                        width: calc(334 * var(--newSize));
                        // min-height: 25vmin;
    
                        .title-informativo {
                            color: #FF6A00;
                            font-weight: 500;
                            justify-content: center;
                            font-size: 2rem;
                            display: flex;
                            margin-bottom: 1rem;
                        }
                    }
                }
    
                
            }


            .seta-baixo {
                margin-top: calc(125 * var(--newSize));
                width: calc(14 * var(--newSize));
                height: calc(8 * var(--newSize));

                img {
                    width: 100%;
                }
            }

            .sessao-1 {
                display: flex;
                flex-direction: column;
                margin-top: 105px;

                img {
                    width: 100%;
                    margin-left: 3rem;
                }

                .parte-1 {
                    flex: 1;
                    padding-right: 0;

                    .titulo-parte-1 {
                        color: #ff6a00 !important;
                        font-size: 1.15rem;
                        margin-top: 0;
                        width: 100%;
                        text-align: center;
                    }

                    .texto-parte-1 {
                        margin-top: calc(12 * var(--newSize));
                        font-family: Roboto;
                        font-size: 1.28rem;
                        line-height: 1.5;
                        letter-spacing: normal;
                        text-align: center;
                        width: calc(334 * var(--newSize));
                    }
                }
                .parte-2 {
                    text-align: center;
                    margin-top: calc(39 * var(--newSize));

                    img {
                        width: calc(310 * var(--newSize));
                        margin-left: calc(25 * var(--newSize));
                    }
                }
            }

            .linha-sessao-1 {
                display: none;
            }

            .sessao-2 {
                display: flex;
                flex-direction: column;
                margin-top: calc(35 * var(--newSize));

                .parte-2 {
                    .titulo-parte-2 {
                        color: #ff6a00 !important;
                        font-size: 1.15rem;
                        margin-top: 0;
                        width: 100%;
                        text-align: center;
                    }

                    .texto-parte-2 {
                        margin-top: calc(13 * var(--newSize));
                        margin-left: calc(20 * var(--newSize));
                        margin-right: calc(20 * var(--newSize));
                        font-family: Roboto;
                        font-size: 1.28rem;
                        line-height: 1.5;
                        letter-spacing: normal;
                        text-align: center;
                        width: calc(334 * var(--newSize));
                    }
                }

                .parte-1 {
                    display: none;
                }

                .sessao-2-mobile {
                    display: flex;
                    text-align: center;
                    margin-top: calc(39 * var(--newSize));

                    img {
                        width: calc(314 * var(--newSize));

                        height: calc(261 * var(--newSize)) !important;
                        margin-left: calc(23 * var(--newSize));
                        margin-right: calc(23 * var(--newSize));
                    }
                }
            }

            .linha-sessao-2 {
                display: none;
            }

            .sessao-3 {
                display: flex;
                flex-direction: column;
                margin-top: calc(72 * var(--newSize));

                .parte-1 {
                    flex: 1;
                    padding-right: 0;

                    .titulo-parte-3 {
                        color: #ff6a00 !important;
                        font-size: 1.15rem;
                        margin-top: 0;
                        width: 100%;
                        text-align: center;
                    }

                    .texto-parte-3 {
                        margin-top: calc(13 * var(--newSize));
                        font-family: Roboto;
                        font-size: 1.1rem;
                        line-height: 1.5;
                        letter-spacing: normal;
                        text-align: center;
                        width: calc(320 * var(--newSize));
                        margin-left: 18px;
                        margin-right: 18px;
                    }
                }

                .parte-2 {
                    display: none;
                }

                .sessao-3-mobile {
                    display: flex;
                    text-align: center;
                    margin-top: calc(33 * var(--newSize));

                    img {
                        width: calc(308 * var(--newSize));
                        height: calc(243 * var(--newSize));
                        margin-left: calc(23 * var(--newSize));
                    }
                }
            }

            .engrenagem {
                display: none;
            }

            .sessao-4 {
                display: flex;
                flex-direction: column;
                margin-top: calc(39 * var(--newSize));

                .titulo-parte-4 {
                    color: #ff6a00 !important;
                    font-size: 1.18rem;
                    margin-top: calc(39 * var(--newSize));
                    width: 100%;
                    text-align: center;
                    margin-left: unset; //calc(585 * var(--newSize));
                    margin-right: unset; //calc(583 * var(--newSize));
                }

                .texto-parte-4 {
                    margin-top: calc(13 * var(--newSize));
                    font-family: Roboto;
                    font-size: 1.1rem;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: center;
                    width: calc(320 * var(--newSize));
                    margin-left: 18px;
                    margin-right: 18px;
                }
            }

            .sessao-5 {
                width: calc(349 * var(--newSize));
                height: unset; //calc(311 * var(--newSize));
                margin-top: calc(24 * var(--newSize));
                padding-bottom: calc(17 * var(--newSize));

                .titulo-parte-5 {
                    margin-top: calc(17 * var(--newSize));
                    font-size: 1rem;
                    width: unset;
                    margin-left: calc(11 * var(--newSize));
                    margin-right: unset;
                }

                .texto-parte-5-1 {
                    margin-top: calc(17 * var(--newSize));
                    font-size: 1rem;
                    line-height: unset;
                    margin-left: calc(11 * var(--newSize));
                    margin-right: unset;

                    span {
                        color: #ff6a00 !important;
                        font-weight: 900;
                    }
                }

                .texto-parte-5-2 {
                    margin-top: calc(35 * var(--newSize));
                    font-size: 1rem;
                    line-height: unset;
                    margin-left: calc(11 * var(--newSize));
                    margin-right: unset;

                    span {
                        font-weight: 900;
                        color: #ff6a00 !important;
                    }
                }

                .texto-parte-5-3 {
                    margin-top: calc(21 * var(--newSize));
                    font-size: 1rem;
                    line-height: unset;
                    margin-left: calc(11 * var(--newSize));
                    margin-right: unset;

                    span {
                        color: #ff6a00 !important;
                        font-weight: 900;
                    }
                }
            }

            .sessao-6 {
                margin-top: calc(15 * var(--newSize));
                width: calc(349 * var(--newSize));
                height: unset; // calc(311 * var(--newSize));
                padding-bottom: calc(17 * var(--newSize));

                span {
                    color: #ff6a00 !important;
                    font-weight: 900;
                }

                .titulo-parte-6 {
                    margin-top: calc(17 * var(--newSize));
                    font-size: 1rem;
                    width: unset;
                    margin-left: calc(11 * var(--newSize));
                    margin-right: unset;
                }

                .texto-parte-6-1 {
                    margin-top: calc(17 * var(--newSize));
                    font-size: 1rem;
                    line-height: unset;
                    margin-left: calc(11 * var(--newSize));
                    margin-right: unset;
                    flex-wrap: wrap;
                }

                .texto-parte-6-2 {
                    margin-top: calc(22 * var(--newSize));
                    font-size: 1rem;
                    line-height: unset;
                    margin-left: calc(11 * var(--newSize));
                    margin-right: calc(13 * var(--newSize));
                    flex-wrap: wrap;
                }

                .texto-parte-6-3 {
                    margin-top: calc(27 * var(--newSize));
                    font-size: 1rem;
                    line-height: unset;
                    margin-left: calc(11 * var(--newSize));
                    margin-right: unset;
                    flex-wrap: wrap;
                }
            }

            .sessao-7 {
                margin-top: calc(30 * var(--newSize));

                .titulo-parte-7 {
                    margin-top: unset;
                    font-size: 1rem;
                    margin-bottom: 30px;
                    width: calc(321 * var(--newSize));
                    text-align: center;
                }
            }
        }
    }
}
