@import "../../../assets/styles/variaveis.scss";
.botao-borda-arredondado {
    cursor: pointer;
    min-width: calc(267 * var(--newSize));
    min-height: calc(58 * var(--newSize));
    height: calc(58 * var(--newSize));
    border-radius: 29px;
    border: solid 2px var(--white-one);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    .botao-borda-arredondado-text {
        font-family: Roboto;
        font-size: 1rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.34;
        letter-spacing: normal;
        text-align: left;
        color: var(--white-one);
        z-index: 5;
        text-transform: uppercase;
    }

    .transparencia {
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 29px;
        background-color: var(--greyish-brown);
        opacity: 0.8;
        z-index: 1;
    }

    @media (max-width: $max-width-mobile) {
        height: calc(51 * var(--newSize));
        max-height: calc(51 * var(--newSize));
        border-radius: calc(28 * var(--newSize));
        min-width: calc(257 * var(--newSize));
        .botao-borda-arredondado-text {
            font-size: 1rem;
            line-height: 1.34;
            letter-spacing: normal;
        }
        .transparencia {
            border-radius: calc(28 * var(--newSize));
            background-color: var(--greyish-brown);
            opacity: 0.8;
            z-index: 1;
        }
    }
}

.botao-borda-arredondado:hover {
    border: solid 2px transparent;
    .transparencia {
        background-color: var(--white-one);
        opacity: 1;
    }
    .botao-borda-arredondado-text {
        color: #333;
    }
}
