@import "../../../assets/styles/variaveis.scss";
.card-box-resultado-busca {
    padding-right: 10px;
    width: calc(192 * var(--newSize));
    height: calc(280 * var(--newSize));
    border-radius: 8px;
}

.card-body-resultado-busca {
    cursor: pointer;
    height: 100%;
    width: 100%;
}

.card-opcoes-on-mouse-over-resultado-busca {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0px;
    left: 0px;
    bottom: 5px;
    right: 10px;
    border-radius: 5px;
    padding: 14px;
    transition: all 0.25s;
    transform: scale(0.95);
}

.card-opcoes-on-mouse-over-resultado-busca:hover {
    transform: scale(1);
}

.card-image-poster-resultado-busca {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    /* box-shadow: 0 10px 20px 0 rgba(0,0,0,.2),0 10px 20px 0 rgba(0,0,0,.2); */
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
}

@media (max-width: $max-width-mobile) {
    // .card-box-resultado-busca {
    //     padding-right: unset;
    //     width:  calc(97 * var(--newSize)) !important;
    //     height: calc(141 * var(--newSize)) !important;
    //     .card-body-resultado-busca {
    //         img {
    //             width:  calc(97 * var(--newSize)) !important;
    //             height: calc(141 * var(--newSize))
    //         }
    //     }
    // }
    .card-box-resultado-busca {
        padding-right: 0;
    }

    .card-image-poster-resultado-busca {
        width: calc(144 * var(--newSize)) !important; // 97
        height: calc(211 * var(--newSize)) !important; // 141
    }
}
