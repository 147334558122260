@import "../../../assets/styles/variaveis.scss";

.video-popup {
    background-color: rgba(0, 0, 0, 0.9);
    // background-color: red;
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2105;
    float: left;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .video-popup-rodape {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 20px 20px;
    }
    .video-popup-progresso {
        font-size: 1.3rem;
        align-self: center;
        margin-right: 20px;
    }

    .video-popup-close {
        max-width: calc(50 * var(--newSize));
        min-width: calc(50 * var(--newSize));
        max-height: calc(50 * var(--newSize));
        min-height: calc(50 * var(--newSize));
        margin-left: 20px;
        margin-right: 20px;
        align-self: center;

    }

    .video-popup-mute {
        max-width: calc(50 * var(--newSize));
        min-width: calc(50 * var(--newSize));
        max-height: calc(50 * var(--newSize));
        min-height: calc(50 * var(--newSize));
        position: relative;
        float: left;
        top: 60px;
    }

    .video-popup-anuncio-link {
        background-color: rgba(0, 0, 0, 0.5);
        font-size: 1.2rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        cursor: pointer;

        span {
            cursor: pointer;
        }
    }
}

@media (max-width: $max-width-mobile) {
    .video-popup {
        .video-popup-rodape {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 20px 0;

            .video-popup-progresso {
                font-size: 0.8rem;
                margin-right: 0;
            }

            .video-popup-anuncio-link {
                font-size: 0.8rem;
                text-align: center;
                font-size: 1rem;
            }

            .video-popup-close {
                margin-top: 20px;
                margin-bottom: 20px;
                margin-right: 0px;
                margin-left: 0px;
            }

            .video-popup-mute {
                position: relative;
                top: 200px;
            }
        }

        
    }
}
