@import "../../../assets/styles/variaveis.scss";

.dialog-popup {
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1001;

    justify-content: center;
    align-items: center;

    .dialog-body {
        z-index: 1002;
        border-radius: 12px;
        border: solid 1px #474747;
        background-color: var(--background-black-two);
        min-width: calc(638 * var(--newSize));
        min-height: calc(356 * var(--newSize));
        display: flex;
        flex-direction: column;
        padding-left: calc(51 * var(--newSize));
        padding-right: calc(51 * var(--newSize));
        position: relative;

        .dialog-title {
            margin-top: calc(111 * var(--newSize));
            font-size: 2.5rem;
            font-weight: 500;
            line-height: 0.4;
            letter-spacing: normal;
            color: #b2c108;
        }

        .dialog-title-tipo-2 {
            font-size: 1.75rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.57;
            letter-spacing: normal;
            text-align: center;
            color: var(--warm-grey);
        }

        .dialog-subtitle {
            margin-top: calc(30 * var(--newSize));
            font-size: 1.625rem;
            line-height: 1.31;
            color: var(--warm-grey);
            width: calc(638 * var(--newSize));
        }

        .dialog-subtitle-tipo-2 {
            text-align: center;
            font-size: 1.125rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.78;
            letter-spacing: normal;
            text-align: center;
            color: var(--warm-grey);
            padding-left: calc(100 * var(--newSize));
            padding-right: calc(100 * var(--newSize));
            //width: calc(638 * var(--newSize));
            box-sizing: border-box;
        }

        .dialog-buttons {
            display: flex;
            flex-direction: row;
            position: absolute;
            bottom: calc(50 * var(--newSize));
            width: 100%;
            margin-left: '21%';

            .dialog-custom-button {
                min-height: calc(46 * var(--newSize));
                min-width: calc(127 * var(--newSize));
                max-height: calc(46 * var(--newSize));
                max-width: calc(127 * var(--newSize));

            }
            .ok-button {
                margin-right: 15%;
                margin-left: 6%;
            }
        }

        .dialog-buttons-tipo-2 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    }
}

@media (max-width: $max-width-mobile) {
    .dialog-popup {
        .dialog-body {
            border-radius: 12px;
            border: solid 0px #474747;
            background-color: var(--background-black-two);
            min-width: 90%;
            max-width: 90%;
            display: flex;
            flex-direction: column;
            padding-left: calc(20 * var(--newSize));
            padding-right: calc(20 * var(--newSize));
            position: relative;
            box-sizing: border-box;
            justify-content: space-around;

            .dialog-title {
                margin-top: calc(20 * var(--newSize));
                font-size: 1.4rem;
                line-height: 1.5;
            }

            .dialog-title-tipo-2 {
                font-size: 1.4rem;
            }

            .dialog-subtitle {
                margin-top: 0;
                font-size: 1.2rem;
                width: 100%;
            }

            .dialog-subtitle-tipo-2 {
                text-align: center;
                font-size: 1.125rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.78;
                letter-spacing: normal;
                text-align: center;
                color: var(--warm-grey);
                padding-left: calc(0 * var(--newSize));
                padding-right: calc(0 * var(--newSize));
                //width: calc(638 * var(--newSize));
                box-sizing: border-box;
            }

            .dialog-buttons {
                display: flex;
                flex-direction: row;
                position: static;
                bottom: calc(50 * var(--newSize));
                width: 100%;
                margin-left: 0;
                justify-content: space-between;
                .dialog-custom-button {
                    min-height: calc(46 * var(--newSize));
                    min-width: calc(127 * var(--newSize));
                    max-height: calc(46 * var(--newSize));
                    max-width: calc(127 * var(--newSize));
                }
                .ok-button {
                    margin-right: 0;
                    margin-left: 0;
                }
            }

            .dialog-buttons-tipo-2 {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
