@import "../../../assets/styles/variaveis.scss";

.linha-informativa {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    
    .linha-informativa-label {
        margin-right: 8px ;
    }
    
    .linha-informativa-classificacao {
        margin-left: 16px ;
    }
    
}

@media (max-width: $max-width-mobile) {
    .linha-informativa {
        
        margin-top: 5px;
        align-items: center;
        flex-wrap: wrap;

        .Text-Style-9 {
            font-size: 0.5rem;
            line-height: 1.44;
        }
    
        .Text-Style-7 {
            font-size: 1rem;
            line-height: 1.5;
        }
        
        .linha-informativa-label {
            margin-right: 8px ;
        }

        .linha-informativa-classificacao {
            margin-left: 2px ;
        }

    }
}