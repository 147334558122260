
.midiaFrame {
    background-image: url('/assets/img/usemais/video-home-usemais-curto.gif');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

@media (max-width: 1440px) {
    .midiaFrame {
        background-image: url('/assets/img/usemais/video-home-usemais-curto-reverse.gif');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: top;
    }
}

.midiaBg {
    position: relative;
    background-image: url('/assets/img/usemais/spark_back.png');
    background-repeat: no-repeat;
    background-size: 100%;
}

.category-button {
    background-color: #151a15;
    font-size: 18px;
    color: #FFFFFF;
}

.button-overlay {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
}

.overlay-text {
    position: absolute;
    z-index: 30;
}

.carousel-lista-parceiros {
    .react-multi-carousel-list ul li {
        width: 300px !important;
    }
}