.provider-group {
    .provider-group-titulo {
        font-family: Roboto;

        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        letter-spacing: normal;
        text-align: left;
        color: var(--white-one);
    }

    .provider-group-cards {
        padding-top: 13px;
        display: flex;
        flex-direction: row;
    }

    .provider-group-card {
    }
}
