body {
    background: black;
}


.elenco-principal {
    background-color: #fccd0a;
    display: flex;
    justify-content: center;
    align-items: center;
    
    min-height: calc(200 * var(--newSize));
    padding: 4%;

    .elenco-principal-body {
        width: 80%;

        .elenco-principal-title {
            color: #000;
            font-size: 2.25rem;
            line-height: 2.25rem;
            font-weight: 400;
            font-family: "DIN Pro Bold", sans-serif;
            width: 100%;
            text-align: center;
        }

        .estrelas {
            padding-top: calc(50 * var(--newSize));
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;

            .artista {
                width: 25%;
                display: flex;
                margin-bottom: 25px;

                .artista-imagem {
                    flex: 1;
                    border: solid #222 3px;
                    width: 70px;
                    margin: 0 5%;
                }

                .artista-descricao {
                    flex: 2;

                    .artista-nome {
                        font-family: DIN Pro Bold, sans-serif;
                        margin: 0;
                        color: #000;
                        font-size: 20px;
                        line-height: 24px;
                    }

                    .artista-personagem {
                        color: #000;
                    }
                }
            }
        }
    }
}

.relacionados {
    background-color: #000;
    padding-top: 4%;
    padding-bottom: 4%;
    width: 100%;
    

    .relacionados-titulo {
        color: #fccd0a;
        width: 100%;
        text-align: center;
        font-size: 40px;
        line-height: 44px;
        font-family: "DIN Pro Bold",sans-serif;
        padding-bottom: 20px;
    }
    
}
