.container-lista-resultado-filmes-busca {
    background-color: "#ccc";
}

.container-lista-resultado-filmes-busca .titulo-lista-horizontal {
    font-size: 1.8rem;
    line-height: 30px;
    /*font-family: "DIN Pro Bold", sans-serif;*/
    color: #fff;
    letter-spacing: -1px;
    font-weight: 400;
}



@media (max-width: 992px) {

    .container-lista-resultado-filmes-busca {
        margin-top: 54px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

}