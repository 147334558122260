.input-busca-mobile-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    padding: 16px; //calc(16 * var(--newSize));
    box-sizing: border-box;

    .busca-mobile-close-modal {
        width: calc(45 * var(--newSize));
        margin-top: 28px;
    }

    .input-busca-mobile {
        position: relative;
        background-color: #fff;
        margin-top: 18px;
        border-radius: 10px;
        color: #0f0f0f;
        display: flex;
        transition: height 0.5s;
        flex-direction: column;
        height: calc(49 * var(--newSize));
        max-height: calc(65 * var(--newSize));
        overflow: hidden;
        width: 100%;
        z-index: 1;
    }

    .input-busca-mobile-field {
        display: flex;
        flex: 1;
        width: 100%;

        input {
            border: none;
            width: 100%;
            border: none;
            font-family: Roboto;
            font-size: 1rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--warm-grey);
            vertical-align: middle;
            padding-left: 20px;
            padding-right: 20px;
        }

        input:focus {
            outline: none;
        }

        .botao-lupa {
            cursor: pointer;
            display: flex;
            align-items: center;
            padding-right: 16px;
            color: var(--bright-orange);
            margin: 0;
            font-size: 1.2rem;
        }

        .botao:hover {
            color: #ddd;
        }
    }

    .filtro-busca {
        width: 100%;
        margin: 4px 0 3px;

        background-color: unset;
        margin-top: 22px;
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 1;
    }

    .filtro-busca {
        select {
            background-color: transparent;
            width: 100%;
            padding: 8px;

            border: 0px;
            outline: 0px;

            margin: 0 5px;

            //-moz-appearance: none; /* Firefox */
            //-webkit-appearance: none; /* Safari and Chrome */
            //appearance: none;

            font-family: Roboto;
            font-size: 1.1rem;
            font-weight: normal;
            text-align: left;
            color: var(--warm-grey);

            option {
                font-size: 1.1rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.03;
                letter-spacing: normal;
                text-align: left;
                color: #333;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .busca-mobile-container {
        display: none;
    }
}
