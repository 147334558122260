@import "../../../assets/styles/variaveis.scss";

.faq-item {
  width: 100%;

  .faq-item-titulo {
    // padding: 25px 30px 25px 34px;
    //width: calc(1081 * var(--newSize));
    //width: 100%;
    display: flex;
    align-items: center;
    padding-left: calc(34 * var(--newSize));
    padding-right: calc(34 * var(--newSize));

    border-radius: 12px;
    border: solid 1px rgba(255, 255, 255, 0.25);
    background-color: var(--background-black-two);
    height: calc(74 * var(--newSize));
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    margin-top: calc(10 * var(--newSize));

    font-size: 1.4rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: var(--warm-grey);
  }

  .faq-click {
    cursor: pointer;
    font-size: 2rem;
  }

  .faq-item-resposta {
    margin-top: calc(28 * var(--newSize));
    margin-bottom: calc(32 * var(--newSize));

    width: 100%;

    font-size: 1.41rem; // 1.71
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.55;
    color: var(--white);

    a {
      color: var(--white);
    }
  }
}
@media (max-width: $max-width-mobile) {
  .faq-item {
    .faq-item-titulo {
      height: calc(39 * var(--newSize));
      // width: calc(329 * var(--newSize));
      font-size: 0.65rem;
      text-transform: uppercase;
      padding-left: calc(18 * var(--newSize));
      padding-right: calc(18 * var(--newSize));

      border-radius: 8px;
    }

    .faq-item-resposta {
      //width: calc(325 * var(--newSize));
      font-size: 0.9rem;
    }
    .faq-click {
      font-size: 1.2rem;
    }
  }
}
