.busca-mobile {
    width: 100%;
    height: 100%;
    background-color: var(--background-black-two);
    position: fixed;
    z-index: 1000000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.busca-mobile-close-modal {
    margin-top: 32px;
    width: 45px;
}