
@import "../../../assets/styles/variaveis.scss";

.imagem-destaque-post {
    width: calc(732 * var(--newSize));
    height: calc(486 * var(--newSize));
    border-radius: 8px;
    background-color: var(--warm-grey);
    display: flex;
    
    img {
        width: 100%;
        border-radius: 8px;
    }
}

@media screen and (max-width: 1366px) {
    
}

@media screen and (max-width: 992px) {

}


@media (max-width: $max-width-mobile) {
    
    .imagem-destaque-post {
        width: calc(327 * var(--newSize));
        height: calc(187 * var(--newSize));
        margin-top: calc(33 * var(--newSize));
    }
}
