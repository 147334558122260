@import "../../../assets/styles/variaveis.scss";
.anuncio-tira {
    .imagemCapa {
        width: calc(1296 * var(--newSize));
        height: calc(104 * var(--newSize));
    }
}

@media (max-width: $max-width-mobile) {
    .anuncio-tira {
        .imagemCapa {
            width: calc(329 * var(--newSize)) !important;
            height: calc(26 * var(--newSize)) !important;
        }
    }
}
