@import "../../../assets/styles/variaveis.scss";

.container-lista-noticia-horizontal {
    padding: 21px;

    .lista-noticias-horizontal-titulo {
        font-size: 1rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: var(--warm-grey);
    }

    .lista-noticias {
        margin-top: 26px;
        display: flex;
        overflow: hidden;
        width: 100%;
        flex-wrap: nowrap;
        transition: 0.5s;
        justify-content: space-around;
        flex-direction: row;
        div {
            // margin-left: 1px;
        }
    }

    // @media (max-width: 992px) {

    // }

    @media (max-width: $max-width-mobile) {
        .lista-noticias {
            margin-top: calc(1 * var(--newSize));
            flex-direction: column;
        }
    }
}
