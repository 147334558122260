@import "../../../assets/styles/variaveis.scss";
.relacionados {
    display: flex;

    flex-direction: column;
    .relacionados-body {
        .relacionados-titulo {
            padding-left: calc(207 * var(--newSize));
            font-family: Roboto;
            font-size: 3rem;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--white-one);
            width: max-content;
        }
    }

    .ficha-tecnica-body {
        width: 80%;

        .ficha-tecnica-title {
            color: #fff;
            font-size: 40px;
            line-height: 44px;
            font-weight: 400;
            font-family: "DIN Pro Bold", sans-serif;
        }
    }
}

@media (max-width: $max-width-mobile) {
    .relacionados {
        margin-top: calc(49 * var(--newSize));
        margin-left: calc(14 * var(--newSize));
        //width: calc(40 * var(--newSize));
        width: 95% !important;

        .relacionados-body {
            .relacionados-titulo {
                font-size: 1rem;
                padding-left: unset;
            }
        }
    }
}
