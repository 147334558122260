@import "../../../assets/styles/variaveis.scss";
.container-item-resultado-busca:not(:first-child) {
    padding-top: calc(98 * var(--newSize));
}

.container-item-resultado-busca {
    position: relative;
    // width: calc(1000/1080* 10vh);
    // max-width: calc(1000/1080* 10vh);
    display: flex;
    justify-content: left;
    padding: 0px 0 0px calc(161 * var(--newSize));
    // height: 483px;

    .container-item-resultado-busca-body {
        display: flex;
        flex-direction: row;
        text-align: center;

        .card-resultado-busca {
            padding-bottom: calc(161 * var(--newSize));
        }
    }

    .container-item-resultado-descricao {
        margin-left: calc(50 * var(--newSize));
        .container-item-resultado-linha-titulo {
            display: flex;
            flex-direction: row;
            width: 100%;

            .container-item-resultado-titulo {
                font-family: Roboto;
                font-size: 2rem;
                font-weight: 900;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: var(--white-one);
                cursor: pointer;
            }
        }

        .container-item-resultado-onde-assistir {
            margin-top: calc(46 * var(--newSize));
            font-family: Roboto;
            font-size: 1.5rem;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.73;
            letter-spacing: normal;
            text-align: left;
            color: var(--white-one);
        }

        .container-item-resultado-nao-disponivel {
            margin-top: calc(46 * var(--newSize));
            font-family: Roboto;
            font-size: 1.2rem;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.73;
            letter-spacing: normal;
            text-align: left;
            color: var(--white-one);
        }

        .generos {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 25px;

            div:not(:last-child) {
                margin-right: 8px;
            }
        }
    }

    .container-item-resultado-providers {
        margin-top: 33px;
        display: flex;
        flex-direction: row;

        .container-item-resultado-provider-item {
            margin-right: 71px;
            .card-box-provider {
                margin-right: 14px;
            }
        }
    }
}

// .container-item-resultado-busca:nth-child(even) {
//     background-color: #444;
// }

.divisor {
    border-bottom: var(--white-one) 1px solid;
    width: calc(900 * var(--newSize));
    margin: 0 calc(150 * var(--newSize));
    opacity: 0.25;
}

@media (max-width: $max-width-mobile) {
    .container-item-resultado-busca {
        position: relative;

        display: flex;
        justify-content: left;
        padding: 0px; //0px 0 0px calc(161 * var(--newSize));
        // height: 483px;

        .container-item-resultado-busca-body {
            display: flex;
            flex-direction: column;
            text-align: center;
            width: 100%;

            .card-resultado-busca {
                padding-bottom: calc(00 * var(--newSize));
                display: flex;
                justify-content: center;
            }
        }

        .container-item-resultado-descricao {
            margin-left: calc(0 * var(--newSize));
            width: 100%;
            display: flex;
            flex-direction: column;

            .container-item-resultado-linha-titulo {
                width: 100%;
                text-align: center;
                display: flex;
                flex-direction: row;

                .container-item-resultado-titulo {
                    font-size: 1.5rem;
                    text-align: center;
                    width: 100%;
                }
            }

            .container-item-resultado-onde-assistir {
                width: 100%;
                margin-top: calc(32 * var(--newSize));
                font-size: 1.2rem;
                text-align: center;
            }

            .container-item-resultado-nao-disponivel {
                width: 100%;
                margin-top: calc(12 * var(--newSize));

                font-size: 0.9rem;
                font-weight: 400;
                font-stretch: normal;
                font-style: normal;
                line-height: 0.73;
                letter-spacing: normal;
                text-align: center;
                color: var(--white-one);
            }

            .generos {
                display: flex;
                flex-direction: row;
                justify-content: center;
                flex-wrap: wrap;
                margin-top: 25px;

                .Text-Style-9 {
                    font-size: 0.5rem;
                }

                div:not(:last-child) {
                    margin-right: 8px;
                }
            }
        }

        .container-item-resultado-providers {
            margin-top: 12px;
            display: flex;
            flex-direction: row;
            justify-content: center;

            .container-item-resultado-provider-item {
                margin-right: 0px;

                .card-box-provider {
                    margin-right: 5px;
                    margin-bottom: 12px;
                }

                .provider-group-cards {
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }
        }
    }

    // .container-item-resultado-busca:nth-child(even) {
    //     background-color: #444;
    // }

    .divisor {
        border-bottom: var(--white-one) 1px solid;
        width: 100%;
        margin: 0px;
        opacity: 0.19;
        margin-top: 24px;
    }
}
