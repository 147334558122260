@import "../../assets/styles/variaveis.scss";

.ajuda-fundo {
    height: calc(624 * var(--newSize)) !important;
    h1 {
        color: #ffffff !important;
        letter-spacing: -1px;

        font-weight: 900;
        font-size: 3.43rem;
        line-height: 3.75rem;

        span {
            display: block;
        }
    }
}

.titulo-popup {
    margin-top: calc(38 * var(--newSize));
    font-size: 1.2rem;

    .seta-voltar-custom {
        margin-right: calc(20 * var(--newSize));
    }
}

.ajuda-body {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;

    .ajuda-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding-left: calc(420 * var(--newSize));
        padding-right: calc(420 * var(--newSize));

        //        max-width: calc(1400 * var(--newSize));

        .faq-custom {
            margin-top: calc(420 * var(--newSize));
        }

        b,
        strong {
            font-weight: bolder;
        }

        p {
            font-family: Roboto;
            font-size: 1.46rem;
            line-height: 1.75rem;
        }
    }
}

@media (max-width: $max-width-mobile) {
    .ajuda-fundo {
        height: calc(208 * var(--newSize)) !important;
        h1 {
            font-size: 1.5rem !important;
        }
    }
    .ajuda-body {
        margin-bottom: 0;
        .ajuda-container {
            padding-left: calc(15 * var(--newSize));
            padding-right: calc(15 * var(--newSize));

            .faq-custom {
                margin-top: calc(60 * var(--newSize));
            }
        }
    }
}
