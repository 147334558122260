@import "../../../assets/styles/variaveis.scss";

.thumb-quadrada {
    cursor: pointer;
    width: calc(262 * var(--newSize));
    height: calc(262 * var(--newSize));
    border-radius: 8px;
    background-color: unset; // var(--warm-grey);
    background-size: contain;
    background-position: center;
}

@media (max-width: $max-width-mobile) {
    .thumb-quadrada {
        width: calc(195 * var(--newSize)) !important;
        height: calc(195 * var(--newSize));
    }
}
