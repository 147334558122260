@import "../../../assets/styles/variaveis.scss";

.filtro-voucher {

    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 50px;
    width: 60%;
    .input-resgate {
        width: 50%;
    }
    .button-ordenar {
        width: 50%;
    }
    .botao-resgate-custom {
        // width: calc(236 * var(--newSize));
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        height: 95%;
        margin-top: 1px;
    }
}

@media (max-width: $max-width-mobile) {
    .filtro-voucher {

        display: flex;
        flex-direction: column;
        gap: 0.1rem;
        align-items: center;    
    }
}
