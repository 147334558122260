@import "../../../assets/styles/variaveis.scss";

.noticia {
    width: calc(426 * var(--newSize));

    background-color: #242424;
    padding: 0.8rem;
    border-radius: 5px;
    margin-bottom: 2rem;

    .noticia-data {
        font-family: Roboto;
        font-size: 1rem;
        line-height: 2.66;
        letter-spacing: normal;
        text-align: left;
        color: var(--white-one);
    }

    .noticia-imagem {
        width: calc(426 * var(--newSize));
        height: calc(242 * var(--newSize));
        margin-top: calc(17 * var(--newSize));
        border-radius: 12px;

        img {
            width: 100%;
            border-radius: 12px;
            object-fit: cover;
            height: 180px;
            object-position: top center;
        }
    }

    .noticia-titulo {
        width: 100%;
        //height: calc(63 * var(--newSize));
        margin-top: calc(24 * var(--newSize));
        font-family: Roboto;
        font-size: 1.5rem;
        font-weight: 900;
        letter-spacing: normal;
        text-align: left;
        color: var(--white-one);
    }

    .noticia-detalhe {
        width: 100%;

        margin-top: calc(18 * var(--newSize));
        p {
            padding: 0px;
            margin: 0px;
            max-height: calc(80 * var(--newSize));
            width: 100%;
            overflow: hidden;
            text-overflow: clip;
            font-family: Roboto;
            font-size: 1.1rem;
            line-height: 1.51;
            letter-spacing: normal;
            text-align: left;
            color: var(--warm-grey);
        }

        .leia-mais {
            margin-top: calc(20 * var(--newSize));
            font-family: Roboto;
            font-size: 1.1rem;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: right;
            color: #ff6a00;
            cursor: pointer;
        }
    }
}

.newNoticia {
    width: calc(660 * var(--newSize));

    background-color: #242424;
    padding: 0.8rem;
    border-radius: 5px;
    margin-bottom: 2rem;

    .new-noticia-data {
        font-family: Roboto;
        font-size: 1rem;
        line-height: 2.66;
        letter-spacing: normal;
        text-align: left;
        color: var(--white-one);
    }

    .new-noticia-imagem {
        width: calc(426 * var(--newSize));
        height: calc(242 * var(--newSize));
        margin-top: calc(17 * var(--newSize));
        border-radius: 12px;

        img {
            width: 100%;
            border-radius: 12px;
            object-fit: cover;
            height: 180px;
            object-position: top center;
        }
    }

    .new-noticia-titulo {
        width: 100%;
        //height: calc(63 * var(--newSize));
        margin-top: calc(24 * var(--newSize));
        font-family: Roboto;
        font-size: 1.5rem;
        font-weight: 900;
        letter-spacing: normal;
        text-align: left;
        color: var(--white-one);
    }

    .new-noticia-detalhe {
        width: 100%;

        margin-top: calc(18 * var(--newSize));
        p {
            padding: 0px;
            margin: 0px;
            max-height: calc(80 * var(--newSize));
            width: 100%;
            overflow: hidden;
            text-overflow: clip;
            font-family: Roboto;
            font-size: 1.1rem;
            line-height: 1.51;
            letter-spacing: normal;
            text-align: left;
            color: var(--warm-grey);
        }

        .new-leia-mais {
            margin-top: calc(20 * var(--newSize));
            font-family: Roboto;
            font-size: 1.1rem;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: right;
            color: #ff6a00;
            cursor: pointer;
        }
    }
}

.red {
    border-right: 0.5rem solid #f00;
}

.blue {
    border-right: 0.5rem solid #267ce7;
}

// @media screen and (max-width: 992px) {

// }

@media (max-width: $max-width-mobile) {
    .noticia {
        width: calc(290 * var(--newSize));

        .noticia-titulo {
            width: 100%;
            //            height: calc(43 * var(--newSize));
            font-size: 1.37rem;
            margin-right: calc(75 * var(--newSize));
        }

        .noticia-data {
            margin-top: calc(38 * var(--newSize));
            font-size: 0.78rem;
        }

        .noticia-detalhe {
            margin-top: calc(18 * var(--newSize));
            p {
                max-height: calc(120 * var(--newSize));
                font-size: 0.96rem;
            }

            .leia-mais {
                font-size: 0.96rem;
            }
        }
        .noticia-imagem {
            width: calc(317 * var(--newSize));
            height: calc(187 * var(--newSize));
            margin-left: calc(17 * var(--newSize));
            margin-right: calc(16 * var(--newSize));
        }
    }

    .newNoticia{
        width: 85%;
    }
}