@import "../../../assets/styles/variaveis.scss";



.showMobile {
    @media (min-width: $max-width-mobile) {
        display: none !important;
    }
}

.hideMobile {
    @media (max-width: $max-width-mobile) {
        display: none !important;
    }
}



@media (max-width: 450px) {

    .conta-perfil {
        width: 100%;
        height: 100%;

        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        .perfilGrupo {
            width: 100%;
            padding: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 20px 0 0 0;

            .perfilDados {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
        
                .perfiFoto {
                width: 100%;
                margin: 0 0 10px 0;
                }
                .dados-perfil {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    align-items: center;

                    .dados-perfil-nome {
                        font-size: 1.5rem;
                        font-weight: 600;
                        // text-align: right;
                        color: var(--bright-orange);
                    }
            
                    .dados-perfil-email {
                        
                        margin-bottom: 20px;
                        font-size: 1rem;
                        font-weight: normal;
                        // text-align: left;
                        color: var(--white-one);
                    }
                    .divAvatar {
                        // background-color: aquamarine;
                        margin-top: 5px;
                        // height: calc(100% - 230px);
                        // border:1px solid antiquewhite;
                        // overflow: none;
                        width: 100%;
                        
                    }

                    .contaBotoes {

                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        justify-content: space-around;
                        margin: 5px 0 0 0;
                        padding: 0 10px;
                        
                        .botao-borda-arredondado-mobile {
                            min-width: none !important;
                        }
                        .conta-perfil-botao-editar {
                        
                            width: 45% !important;
                            background-color: #616161;
                            border: none !important;
                            min-width: none !important;
                            height: 32px;
                            .conta-perfil-botao-editar-text {
                                font-size: 0.8rem;
                                font-weight: 500;
                            }
                        }
                        .conta-perfil-botao-sair {
                            width: 45% !important;
                            // align-self: flex-end;
                            height: 32px;
                            border: 1px solid #616161 !important;
                            background-color: var(--licorice);
                            min-width: none !important;
                            
                            .conta-perfil-botao-sair-text {
                                font-size: 0.8rem;
                                font-weight: 500;
                                color: #8c8c8c;
                            }
                            
                        }
                    }
                    
                }
            }
        }
    
    }
    
}

@media (min-width: 450px) {

    .conta-perfil {
        width: 100%;
        height: 100%;

        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        .perfilGrupo {
            width: 100%;
            padding: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .perfilDados {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
        
                .perfiFoto {
                width: 100%;
                }
                .dados-perfil {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    align-items: center;

                    .dados-perfil-nome {
                        font-size: 1.5rem;
                        font-weight: 600;
                        // text-align: right;
                        color: var(--bright-orange);
                    }
            
                    .dados-perfil-email {
                        
                        margin-bottom: 20px;
                        font-size: 1rem;
                        font-weight: normal;
                        // text-align: left;
                        color: var(--white-one);
                    }
                    .divAvatar {
                        // background-color: aquamarine;
                        margin-top: 5px;
                        // height: calc(100% - 230px);
                        // border:1px solid antiquewhite;
                        // overflow: none;
                        width: 100%;
                        
                    }

                    .contaBotoes {

                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        justify-content: space-between;
                        margin: 5px 0 0 0;
                        
                        .botao-borda-arredondado-mobile {
                            min-width: none !important;
                        }
                        .conta-perfil-botao-editar {
                        
                            width: 45% !important;
                            background-color: #616161;
                            border: none !important;
                            min-width: none !important;
                            height: 30px !important;
                            .conta-perfil-botao-editar-text {
                                font-size: 0.8rem;
                                font-weight: 500;
                            }
                        }
                        .conta-perfil-botao-sair {
                            width: 45% !important;
                            // align-self: flex-end;
                            height: 30px !important;
                            border: 1px solid #616161 !important;
                            background-color: var(--licorice);
                            min-width: none !important;

                            .conta-perfil-botao-sair-text {
                                font-size: 0.8rem;
                                font-weight: 500;
                                color: #8c8c8c;
                            }
                            
                        }
                    }
                    
                }
            }
        }
    
    }
    
}


@media (min-width: 794px) {

    .conta-perfil {
        width: 100%;
        height: 100%;
        // padding: 10% 0 5% 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        

        .perfilGrupo {
            width: 100%;
            padding: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .perfilDados {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
        
                .perfiFoto {
                width: 100%;
                }
                .dados-perfil {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    align-items: center;
                    margin: 20px 0 0 0;

                    .dados-perfil-nome {
                        font-size: 1.5rem;
                        font-weight: 600;
                        // text-align: right;
                        color: var(--bright-orange);
                    }
            
                    .dados-perfil-email {
                        
                        margin-bottom: 20px;
                        font-size: 1rem;
                        font-weight: normal;
                        // text-align: left;
                        color: var(--white-one);
                    }
                    .divAvatar {
                        // background-color: aquamarine;
                        margin-top: 5px;
                        // height: calc(100% - 230px);
                        // border:1px solid antiquewhite;
                        // overflow: none;
                        width: 100%;
                        
                    }

                    .contaBotoes {

                        display: flex;
                        flex-direction: row;
                        // gap: 10px;
                        justify-content: space-between;
                        margin: 5px 0 0 0;
                        width: 100%;
                        
                        .botao-borda-arredondado-mobile {
                            min-width: none !important;
                        }
                        .conta-perfil-botao-editar {
                        
                            width: 45% !important;
                            background-color: #616161;
                            border: none !important;
                            min-width: none !important;
                            height: 55px !important;

                            .conta-perfil-botao-editar-text {
                                font-size: 0.8rem;
                                font-weight: 500;
                            }
                            
                        }
                        .conta-perfil-botao-sair {
                            width: 45% !important;
                            // align-self: flex-end;
                            height: 55px !important;
                            border: 1px solid #616161 !important;
                            background-color: var(--licorice);
                            min-width: none !important;

                            .conta-perfil-botao-sair-text {
                                font-size: 0.8rem;
                                font-weight: 500;
                                color: #8c8c8c;
                            }
                            
                        }
                    }
                    
                }
            }
        }
    }   
}


@media (min-width: 1024px) {
    .conta-perfil {
        width: 100%;
        height: 100%;
        display: flex;
   
    
        .perfilGrupo {
            width: 100%;
            padding: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        
            .perfilDados {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                .perfiFoto {
                width: 50%;
                }
                .dados-perfil {
                    // margin: 20px 0 0 0;
                    display: flex;
                    align-items: flex-end;
                    flex-direction: column;
                    justify-items: flex-end;
                    width: 50%;
                    
                    .dados-perfil-nome {
                        font-size: 1.5rem;
                        font-weight: 600;
                        text-align: right;
                        color: var(--bright-orange);
                    }
            
                    .dados-perfil-email {
                    
                        margin-bottom: 20px;
                        font-size: 1rem;
                        font-weight: normal;
                        text-align: left;
                        color: var(--white-one);
                    }
            
                    .contaBotoes {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        width: 100%;         
            
                        .conta-perfil-botao-editar {
                            justify-self: end;
                            align-self: flex-end;
                            width: 150px !important;
                            // min-width: 70%;
                            background-color: #616161;
                            border: none !important;
                            height: 40px !important;

                            .conta-perfil-botao-editar-text {
                                font-size: 0.8rem;
                                font-weight: 500;
                            }
                        }
                        .conta-perfil-botao-sair {
                            width: 150px !important;
                            // min-width: 70%;
                            align-self: flex-end;
                            height: 40px !important;
                            border: 1px solid #616161 !important;
                            background-color: var(--licorice);

                            .conta-perfil-botao-sair-text {
                                font-size: 0.8rem;
                                font-weight: 500;
                                color: #8c8c8c;
                            }
                            
                        }
                        
                    }
                }
            }
            .divAvatar {
                margin-top: 30px;
                height: 130px;
                overflow: hidden;
                width: 100%;
           }
        }
    }
    
}

@media (min-width: 1200px) {

    .conta-perfil {
        width: 100%;
        height: 100%;
        display: flex;
       

        .perfilGrupo {
            width: 100%;
            padding: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .perfilDados {
                display: flex;
                justify-content: space-between;
        
                .perfiFoto {
                width: 50%;
                }
                .dados-perfil {
                    // margin: 20px 0 0 0;
                    display: flex;
                    align-items: flex-end;
                    flex-direction: column;
                    justify-items: flex-end;
                    width: 50%;
                    
                    .dados-perfil-nome {
                        font-size: 1.5rem;
                        font-weight: 600;
                        text-align: right;
                        color: var(--bright-orange);
                    }
            
                    .dados-perfil-email {
                       
                        margin-bottom: 20px;
                        font-size: 1rem;
                        font-weight: normal;
                        text-align: left;
                        color: var(--white-one);
                    }
            
            
                    .contaBotoes {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        width: 100%;
                        
            
                        .conta-perfil-botao-editar {
                            justify-self: end;
                            align-self: flex-end;
                            width: 150px;
                            // min-width: 70%;
                            background-color: #616161;
                            border: none !important;
                            height: 32px;

                            .conta-perfil-botao-editar-text {
                                font-size: 0.8rem;
                                font-weight: 500;
                            }
                        }
                        .conta-perfil-botao-sair {
                            width: 150px;
                            // min-width: 70%;
                            align-self: flex-end;
                            height: 32px;
                            border: 1px solid #616161 !important;
                            background-color: var(--licorice);

                            .conta-perfil-botao-sair-text {
                                font-size: 0.8rem;
                                font-weight: 500;
                                color: #8c8c8c;
                            }
                            
                        }
                        
                    }
                }
            }

            .divAvatar {
                margin-top: 30px;
                height: 40%;
                overflow: hidden;
                width: 100%;
            }
        }
    }
}

