@import "../../../assets/styles/variaveis.scss";

.popup-resgate-custom {
    button.popup-resgate-custom-button {
        margin-top: 20px;
        .text {
            font-size: 16px;
            line-height: 16px;
            font-weight: 400;
        }
    }

    .popup-aviso {
        .popup-aviso-mensagem {

            color: #FFF;
            font-weight: 300;

            a.popup-link-contato {
                color: #FFF;
                font-weight: 700;
            }

            a.popup-link-contato:hover {
                color: var(--bright-orange);
                font-weight: 700;
            }
        }
    }

    

}

.container-lista-resgate-pontos-horizontal {
    margin-top: 45px;
    // display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    // height: 300px;
    // background-color: wheat;

    .custom-card-resgate {
        margin: 0;
        margin-left: auto;
    }

    .react-multi-carousel-track {
        gap: 4rem;

        .react-multi-carousel-item {
            width: 100%;
        }
    }

    .popup-resgate-concluido {
        width: calc(675 * var(--newSize));
        height: calc(488 * var(--newSize));
        padding-left: calc(67 * var(--newSize));
        padding-right: calc(67 * var(--newSize));
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;

        img {
            margin-top: calc(64 * var(--newSize));
        }
        .popup-resgate-concluido-titulo {
            padding-top: calc(30 * var(--newSize));
            font-size: 2.5rem;
            font-weight: 500;
            line-height: 0.4;
            color: #b2c108;
        }
        .popup-resgate-concluido-mensagem {
            margin-top: calc(40 * var(--newSize));
            font-size: 1.625rem;
            font-weight: normal;
            line-height: 1.31;
            color: var(--warm-grey);
        }

        .popup-resgate-custom-button {
            margin: calc(55 * var(--newSize)) auto 0 auto;
            width: calc(157 * var(--newSize));
            height: calc(56 * var(--newSize));

            .text {
                font-size: 2rem;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 0.5;
                letter-spacing: normal;
            }
        }
    }
}
@media (max-width: $max-width-mobile) {

    .container-lista-resgate-pontos-horizontal {
        padding: 0px !important;
        // margin-left: calc(26 * var(--newSize));
        margin-top: calc(30 * var(--newSize));

        .custom-card-resgate {
            margin: 0 auto;
        }

        .react-multi-carousel-track {
            gap: unset;
        }

        .popup-resgate-agora-custom {

            .dialog-body{
                min-height: 0;
            }

            .dialog-buttons {
                margin: 150px auto 20px !important;
                justify-content: space-evenly;
                width: 100% !important;
                position: static;
                .ok-button {
                    margin: 0 !important;
                }
            }
        }
    }
    
    .popup-resgate-concluido-mensagem {
        width: 75%;
        margin-top: calc(29 * var(--newSize));
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.78;

        padding-left: 10px;
        padding-right: 10px;
    }
}


@media (max-width: 400px) {
    .popup-resgate-concluido-mensagem {
        font-size: 1.625rem;
        width: 31%;
        font-weight: normal;
        line-height: 1.31;
        color: var(--warm-grey);
    }
}