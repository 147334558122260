.botao-retangulo-arredondado {
    border: none;
    cursor: pointer;
    min-width: calc(165 * var(--newSize));
    height: calc(46 * var(--newSize));
    border-radius: calc(17 * var(--newSize));
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.32);
    background-color: var(--bright-orange);
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
        font-family: Roboto;
        font-size: 1.1rem;
        font-weight: 900;
        line-height: 2.03;
        letter-spacing: 0.46px;
        text-align: center;
        color: var(--white-one);
        text-transform: uppercase;
    }

    @media (max-width: 1366px) {
    }
}

.botao-retangulo-arredondado:hover {
    background-color: #c60;
}

.botao-retangulo-desabilitado {
    cursor: not-allowed;
    opacity: 0.5;
}
