@import "../../../assets/styles/variaveis.scss";

.menu {
    position: relative;
    height: calc(850 * var(--newSize));
}

.menu-simples {
    position: relative;
    height: calc(250 * var(--newSize));
    .efeito-gradiente {
        display: none !important;
    }
}

.menu-container-busca {
    margin-top: calc(650 * var(--newSize));
    padding: 0 calc(16 * var(--newSize));
}

.menu-container-busca-with-google-ad {
    margin-top: calc(450 * var(--newSize));
    padding: 0 calc(16 * var(--newSize));
}

.botao-busca-custom {
    display: none;
}

.menu-container {
    align-items: center;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-attachment: scroll;
    border-bottom: solid 1px rgba(255, 255, 255, 0.4);
    height: calc(86 * var(--newSize));

    .menu-logo {
        z-index: 1;
    }

    .topnav {
        //background-color: transparent;
        background-color: unset;
        overflow: hidden;
        flex: 1;
        z-index: 10;

        .menu-itens {
            width: 100%;
            height: 100%;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            .espaco-menu {
                padding-left: calc(100 * var(--newSize));
            }

            .menu-group-social-login {
                position: absolute;
                right: 0px;
                display: flex;
                margin-right: 30px;
                align-items: center;

                .menu-item-rede-social {
                    font-size: 1.5em !important;
                    margin-left: 33px;
                }
            }

            .menu-group-social-login-perfil {
                position: absolute;
                right: 0px;
                display: flex;
                margin-right: 20px;
                align-items: center;
                gap: 24px;

                .menu-item-rede-social {
                    font-size: 1.5em !important;
                    padding: 0;
                }

                .menu-item-logado {
                    width: 45px ;
                    height: 45px;
                }
            }
        }

        .dados-logado-container {
            display: flex;
            right: 0;
            position: absolute;
            top: 15px;
            right: 10px;
            padding: 10px 0;
            margin: 0;
            flex-direction: column;
            gap: 2px;
            align-items: flex-end;

            h6.dados-logado-usuario {
                font-size: 0.8rem;
                line-height: 0.8rem;
                font-weight: 400;
                color: #fff;
                padding: 0;
                margin: 0;
            }

            p.dados-logado-pontos {
                font-size: 1rem;
                line-height: 1rem;
                font-weight: 600;
                padding: 0;
                margin: 0;
                
            }

            p.dados-logado-pontos.btn-entrar {
                padding: 10px 5px;
            }

            span.dados-logado-legend {
                font-size: 0.6rem;
                line-height: 0.6rem;
                font-weight: 400;
                padding: 0;
                margin: 0;
            }
        }
    }
}

// .efeito-gradiente {
//     display: flex;
//     position: absolute;
//     width: 100%;
//     height: calc(150 * var(--newSize));
//     background: rgb(0, 0, 0);
//     //    margin-top: calc(-150 * var(--newSize));
//     z-index: 0;
//     background: linear-gradient(180deg, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 100%);

//     position: absolute;
//     bottom: 0px;
// }

.efeito-gradiente-topo {
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(86 * var(--newSize));
    background: rgb(0, 0, 0);
    margin-top: 0px;
    z-index: 1;
    // background: linear-gradient(180deg, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 100%);
}

nav {
    width: 100%;
    height: calc(50 * var(--newSize));
    display: flex;
    align-items: center;
    line-height: calc(86 * var(--newSize));
    height: calc(86 * var(--newSize));
}

/* Add a black background color to the top navigation */

// .menu-item-custom-class-anuncie {
//     background-color: var(--bright-orange);
//     padding: calc(32 * var(--newSize)) 14px;
// }

/* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */

@media screen and (max-width: $max-width-mobile) {
    .menu-container {
        border-bottom: unset;
        height: 62px;

        .menu-logo {
            display: flex;
            width: 100%;
            justify-content: center;
            align-content: center;
            margin-left: 90px;
            margin-right: 90px;
            z-index: 11;
        }
    }

    .hide-on-mobile {
        display: none !important;
    }

    .topnav {
        overflow: auto;
        display: unset;
        position: absolute;

        width: 100%;

        .menu-itens {
            //    display: inline-block;
            display: flex;
            //justify-content: flex-end;
            // padding-top: 50px;
            justify-content: flex-start !important;
            align-items: center;
            flex-direction: column;

            .menu-item-custom-class {
                display: none;
            }

            .menu-group-social-login {
                display: none !important;
            }

            .botao-menu-hamburger-custom {
                display: block;
                left: 0;
                position: absolute;
                top: 10px;
                padding-left: 15px;
                padding-right: 15px;
                padding-bottom: 30px;

                // padding-top: 10px;
                svg {
                    font-size: 2rem;
                }
            }

            .botao-busca-custom {
                display: block;
                right: 0;
                position: absolute;
                top: 5px;
                padding-left: 15px;
                padding-right: 15px;
                padding-bottom: 30px;
            }

            // .botao-menu-hamburger-custom:hover {
            //     background-color: transparent;
            //     // color: #fccb0a;
            // }
        }
    }

    // .topnav .menu-itens {
    //
    // }
}

/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */

@media screen and (max-width: $max-width-mobile) {
    .menu-item-entrar {
        display: none !important;
    }

    .topnav.topnav.responsive {
        overflow: auto;
    }

    .topnav.topnav.responsive .menu-item-rede-social {
        display: none;
    }

    .topnav.responsive .botao-busca-custom {
        display: none;
    }

    .topnav.responsive {
        position: fixed;
        z-index: 11;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        line-height: 25px;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.8);
        touch-action: none;
        padding-top: 12px;
        // transition: 0.5s;
        //        z-index: 100000;
    }

    .topnav.responsive .botao-menu-hamburger-custom {
        //display: block;
        //text-align: center;

        //        margin-bottom: 120px;
        //        padding: 20px 100px 0px 0px;
        // margin-top: 60px;
        top: 12px;
    }

    .topnav.responsive .menu-item-custom-class {
        float: none;
        display: block;
        height: 48px;
        line-height: 48px;
        /*text-align: left; */
    }

    .topnav.responsive .menu-item-custom-class:hover {
        background-color: transparent;
        color: #fccb0a;
    }

    .topnav.responsive .menu-item-custom-class {
        font-size: 14px;
        font-weight: 500;
    }

    .topnav.responsive .menu-item-custom-class:hover {
        background-color: transparent;
        color: #fff;
    }

    .menu-container {
        display: flex;
        justify-content: space-between;
    }
}

.menu-footer {
    height: calc(60 * var(--newSize));
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-attachment: scroll;
    background-image: linear-gradient(0deg, rgb(51, 51, 51) 2%, rgba(51, 51, 51, 0) 98%);
    background-size: auto;
    background-origin: padding-box;
    background-clip: border-box;
    z-index: 5;
}

@media screen and (min-width: 300px) and (max-width: 350px){
    .dados-logado-container {
        top: 10px !important;
    }
}

@media screen and (min-width: 350px) and (max-width: 400px){
    .dados-logado-container {
        top: 12px !important;

        p.dados-logado-pontos.btn-entrar {
            padding: 15px 5px 10px !important;
        }
    }
}

@media screen and (min-width: 400px) and (max-width: $max-width-mobile){
    .dados-logado-container {
        top: 15px !important;

        p.dados-logado-pontos.btn-entrar {
            padding: 15px 5px 10px !important;
        }
    }
}

@media (max-width: $max-width-mobile) {
    .menu {
        height: calc(358 * var(--newSize));
    }

    .menu-simples {
        position: relative;
        height: calc(158 * var(--newSize));
    }

    .menu-container-busca {
        margin-top: 400px;
    }

    .efeito-gradiente-topo {
        // height: calc(45 * var(--newSize));
        height: 55px;
        background: #F4782E;
        // background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
    }

    // .efeito-gradiente {
    //     display: flex;
    //     height: calc(30 * var(--newSize));
    //     //    margin-top: calc(280 * var(--newSize)); // calc(185 * var(--newSize));
    //     //    z-index: 5;
    //     // background: linear-gradient(0deg, rgb(0, 0, 0) 10%, rgba(0, 0, 0, 0) 100%);
    //     background: unset;
    //     background-color: rgba(blue, 0.3);
    //     // display: flex;
    //     // position: absolute;
    //     // width: 100%;
    //     // height: calc(150 * var(--newSize));
    //     // background: rgb(0, 0, 0);
    //     // //    margin-top: calc(-150 * var(--newSize));
    //     // z-index: 0;
    //     // background: linear-gradient(180deg, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 100%);
    //     // position: absolute;
    //     // bottom: 0px;
    // }
}
