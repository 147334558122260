@import "../../../assets/styles/variaveis.scss";

.busca-container-local {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .busca-local {
        position: relative;

        border: solid 1px rgba(255, 255, 255, 0.25);
        padding-left: calc(45 * var(--newSize));
        border-radius: 10px;
        color: #0f0f0f;
        display: flex;
        transition: height 0.5s;
        flex-direction: column;
        height: calc(65 * var(--newSize));
        max-height: calc(65 * var(--newSize));
        overflow: hidden;
        // max-width: calc(620 * var(--newSize));
        width: 100%;
        //  opacity: 15%;
        z-index: 1;
        box-sizing: border-box;
    }

    .input-busca-local {
        display: flex;
        flex: 1;

        input {
            border: none;
            width: 100%;
            border: none;
            font-family: Roboto;
            font-size: 1.5rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--warm-grey);
            vertical-align: middle;
            background-color: #000;
        }

        input:focus {
            outline: none;
        }

        .botao {
            cursor: pointer;
            padding: 0 10px;
            transition: 0.5;
            justify-content: center;
            align-items: center;
            display: flex;
            font-family: Roboto;
            font-size: 1.5rem;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
        }

        .botao.lupa {
            color: var(--bright-orange);
            padding-right: 1.5rem;
        }
    }
}

@media (max-width: $max-width-mobile) {
    .busca-container-local {
        .busca-local {
            height: calc(35 * var(--newSize));
            max-height: calc(35 * var(--newSize));
            overflow: hidden;
            max-width: calc(620 * var(--newSize));
            width: 100%;
            //  opacity: 15%;
            z-index: 1;
            padding-left: calc(15 * var(--newSize));
            box-sizing: border-box;
            border-radius: 7px;
        }

        .input-busca-local {
            display: flex;
            flex: 1;

            input {
                width: calc(258 * var(--newSize));
                font-size: 0.75rem;
            }

            input:focus {
                outline: none;
            }

            .botao {
                cursor: pointer;
                padding: 0 10px;
                transition: 0.5;
                justify-content: center;
                align-items: center;
                display: flex;
                font-family: Roboto;
                font-size: 1.5rem;
                font-weight: 500;
            }

            .botao.lupa {
                color: var(--bright-orange);

                font-size: 1rem;
            }
        }
    }
}
