@import "../../../assets/styles/variaveis.scss";
.sobre-filme-serie {
    padding: calc(50 * var(--newSize)) 0px calc(66 * var(--newSize)) calc(217 * var(--newSize));
    display: flex;
    flex-direction: row;
    background-size: cover;

    .dados-filme-serie {
        display: flex;
        flex-direction: column;
        padding-left: calc(67 * var(--newSize));

        .dados-filme-serie-titulo {
            font-family: Roboto;
            font-size: 3rem;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--white-one);
        }

        .staff {
            margin-top: calc(23 * var(--newSize));
            max-width: calc(820 * var(--newSize));
        }

        .dados-filme-serie-classificacao {
            margin-top: calc(46 * var(--newSize));
            display: flex;
            align-items: center;
        }

        .dados-filme-serie-label-classificacao {
            font-family: Roboto;
            font-size: 1rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.47;
            letter-spacing: normal;
            text-align: left;
            color: var(--warm-grey);
            margin-left: calc(32 * var(--newSize));
        }

        .dados-filme-serie-botao {
            width: calc(266 * var(--newSize));
            margin-top: calc(70 * var(--newSize));
        }
    }

    // @media (max-width: 600px) {
    //     .generos {
    //         display: none;
    //     }

    //     .staff {
    //         display: none;
    //     }

    //     h1 {
    //         font-size: 32px;
    //     }
    // }
}
@media (max-width: $max-width-mobile) {
    .sobre-filme-serie {
        padding: unset;
        display: flex;
        flex-direction: row !important;
        flex-wrap: wrap;
        margin-top: calc(24 * var(--newSize));
        margin-left: calc(16 * var(--newSize));
        background-image: none !important;

        .card {
            display: flex;
            flex-direction: column;
            align-items: center !important;
            width: calc(350 * var(--newSize));
        }

        .dados-filme-serie {
            width: calc(150 * var(--newSize));
            height: calc(250 * var(--newSize));
            padding-left: calc(16 * var(--newSize));
            
            .card-board {
                margin-left: calc(107 * var(--newSize));
            }

            .dados-filme-serie-botao {
                margin-top: calc(24 * var(--newSize));
                margin-left: calc(33 * var(--newSize));
                margin-right: calc(34 * var(--newSize));
                width: calc(173 * var(--newSize));
                height: calc(42 * var(--newSize));
            }

            .dados-filme-serie-classificacao {
                display: none;
            }

            .dados-filme-serie-titulo {
                display: none;
            }

            .generos {
                display: none;
            }

            .staff {
                display: flex;
                flex-direction: column;
                margin-top: calc(23 * var(--newSize));
                width: calc(328 * var(--newSize));
                align-items: center;


                .Text-Style-8 {
                    font-size: 0.87rem;
                }

                .Text-Style-9 {
                    font-size: 0.87rem;
                }
            }
        }
    }
}
