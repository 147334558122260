@import "../../../assets/styles/variaveis.scss";

.classificar-compartilhar {
    padding-left: 217px;
    padding-top: 67px;
    padding-bottom: 67px;

    h1 {
        font-family: Roboto;
        font-size: 17.5px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--white-one);
    }

    .classificar-compartilhar-opcoes {
        display: flex;
        flex-direction: row;
        align-items: center;

        flex-wrap: wrap;
    }

    .favoritar-custom {
        margin-left: calc(62 * var(--newSize));
    }

    .favoritar-custom:hover {
        opacity: 0.8;
    }

    .share-custom {
        margin-left: 34px;
    }
}

@media (max-width: $max-width-mobile) {
    .classificar-compartilhar {
        padding-left: unset; // calc(10 * var(--newSize));
        padding-top: unset;
        padding-bottom: unset;
        margin-top: calc(20 * var(--newSize));
        margin-left: calc(10 * var(--newSize)) !important;

        h1 {
            font-size: 1.09rem;
        }
        .classificar-compartilhar-opcoes {
            margin-top: calc(5 * var(--newSize));
        }

        .favoritar-custom {
            margin-top: calc(12 * var(--newSize));
            margin-left: calc(60 * var(--newSize)) !important;
            margin-right: calc(24 * var(--newSize)) !important;
        }

        .share-custom {
            margin-top: calc(12 * var(--newSize));
            margin-right: calc(14 * var(--newSize));
            margin-left: unset;
        }
    }
}
