@import "../../../assets/styles/variaveis.scss";

.posts-relacionados-body {
    width: calc(540 * var(--newSize));

    padding-left: calc(93 * var(--newSize));
    box-sizing: border-box;
    padding-bottom: calc(327 * var(--newSize));

    .card-post-container-custom:not(:first-child) {
        padding-top: calc(32 * var(--newSize));
    }

    .card-post-pontilhado {
        margin-top: calc(69 * var(--newSize));
    }

    .card-post-publicidade {
        margin-top: calc(78 * var(--newSize));

        .card-post-publicidade-label {
            margin-bottom: calc(25 * var(--newSize));
            font-size: 1rem;
            font-weight: 500;
            color: var(--warm-grey);
        }
    }

    .card-post-divisor-cabecalho {
        display: none;
    }

    .lista-post-relacionados {
        margin-top: calc(26 * var(--newSize));
        display: flex;
        overflow: hidden;
        width: 100%;
        flex-wrap: nowrap;
        transition: 0.5s;
        justify-content: space-between;
        flex-direction: column;
    }
}

@media (max-width: $max-width-mobile) {
    .posts-relacionados-body {
        width: calc(540 * var(--newSize));
        padding-left: unset;
        margin-left: calc(16 * var(--newSize));
        padding-bottom: calc(41 * var(--newSize));

        .card-post-container-custom:not(:first-child) {
            padding-top: unset;
        }

        .card-post-publicidade {
            display: none;
        }

        .card-post-pontilhado {
            display: none;
        }

        .card-post-divisor-cabecalho {
            display: flex;
            border-bottom: var(--white-one) 1px solid;
            width: 100%;
            opacity: 0.25;
            margin-top: calc(45 * var(--newSize));
        }

        .lista-post-relacionados {
            margin-top: unset;
            display: flex;
            overflow: hidden;
            width: 100%;
            flex-wrap: nowrap;
            transition: 0.5s;
            justify-content: space-between;
            flex-direction: row;
        }
    }
}
