@import "../../../assets/styles/variaveis.scss";

.card-box-provider {
    padding-right: calc(10 * var(--newSize));
    border-radius: 8px;
    height: calc(74 * var(--newSize));
    width: calc(74 * var(--newSize));
    margin-right: calc(21 * var(--newSize));

    .card-body {
        cursor: pointer;
        width: calc(74 * var(--newSize));
    }

    .card-image-poster {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        /* box-shadow: 0 10px 20px 0 rgba(0,0,0,.2),0 10px 20px 0 rgba(0,0,0,.2); */
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
    }
}

@media (max-width: $max-width-mobile) {
    .card-box-provider {
        height: calc(65 * var(--newSize));
        width: calc(65 * var(--newSize));
        .card-body {
            width: calc(65 * var(--newSize));
            .card-image-poster {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
}
