@import "../../../assets/styles/variaveis.scss";
.copyright{
    padding: 20px;
}

.copyright span {
    font-size: 9px;
    font-family: 'Roboto', sans-serif;
}
.rodapeConta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0;
}

@media (min-width: $max-width-mobile) {
    .rodape {
        display: flex;
        flex-direction: column;
        margin-top: 0;
        overflow: hidden;
    }
    .rodapeBgGray {
        width: 100%;
        background-color: #1d1d1d;
        display: flex;
        justify-content: center;
        padding: 20px;

        gap: 80px;

        .rodapeTitulo {
            text-decoration: underline;
            color: #FFF;
            font-size: 18px;
            height: auto;
            font-weight: 600;
        }

        .rodapeContaMobile {
            .politicasColor a{
                color: #fff;
                font-size: 18px;
                font-weight: 500;
                // display: flex;
                // flex-direction: column;
                // justify-items: center;
            }
        }

    }
}

// @media (max-width: $max-width-mobile) {
@media (min-width: 450px) {


    .rodapeConta {
        margin-top: calc(50 * var(--newSize));
        flex-direction: column;
        gap: 20px;
    }
    .copyright{
        padding: 10px;
        text-align: center;

    }
    
    .copyright span {
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
    }

    .rodapeContaMobile {
        display: flex;
        flex-direction: column;

        .linksColor {
            color: #fff;
            font-size: 14px;
            font-weight: 500;
        }
        .linksSpace {
            padding: 0 35px;

        }

        .politicasColor{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .politicasColor a{
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            // display: flex;
            // flex-direction: column;
            // justify-items: center;
        }
        .imgRodape {
            width: 206px;
            height: 96px;
            flex-grow: 0;
        }

        .bodyRodape {
            padding: 10px;
        }

    }
}

@media (max-width: 450px) {


    .rodapeConta {
        margin-top: calc(50 * var(--newSize));
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
    }
    .copyright{
        padding: 10px;
        text-align: center;

    }
    
    .copyright span {
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
    }

    .rodapeContaMobile {
        display: flex;
        flex-direction: column;

        .linksColor {
            color: #fff;
            font-size: 14px;
            font-weight: 500;
        }
        .linksSpace {
            padding: 0 35px;
        }

        .politicasColor{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .politicasColor a{
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            // display: flex;
            // flex-direction: column;
            // justify-items: center;
        }
        .imgRodape {
            width: 206px;
            height: 96px;
            flex-grow: 0;
        }

        .bodyRodape {
            padding: 10px;
        }

    }
}
