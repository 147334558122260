@import '../../../assets/styles/variaveis.scss';

.label-quadrado {
    display: flex;
    margin: 1px;
    justify-content: center;
    align-items: center;
    padding: 0px 14px;
    min-width: calc(64 * var(--newSize));
    height: calc(32 * var(--newSize));
    border-radius: 4px;
    background-color: var(--warm-grey);
}

@media (max-width: $max-width-mobile) {
    .label-quadrado {
        margin: calc(2 * var(--newSize)) !important;
        min-width: calc(33 * var(--newSize)) !important;
        //width: calc(33 * var(--newSize));
        height: calc(21 * var(--newSize));
    }
}