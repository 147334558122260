@import "../../../assets/styles/variaveis.scss";




@media (max-width: 450px) {
    .conta-saldo {
        display: flex;
        flex-direction: column;
        margin: 10px 0 0 0;
       
    
        .conta-saldo-titulo {
            display: flex;
            font-size: 20px;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.88px;
            color: var(--bright-orange);
            justify-content: center;
        }
    
        // .link-extrato-pontos {
        //     width: calc(348 * var(--newSize));
        //     text-align: left;
        //     cursor: pointer;
    
        //     font-size: 1rem;
        //     font-weight: 500;
        //     font-stretch: normal;
        //     font-style: normal;
        //     line-height: normal;
        //     letter-spacing: normal;
        //     text-align: left;
        //     color: var(--bright-orange);
        // }
    
        .conta-saldo-cupom {
            display: flex;
            align-items: center;
            // justify-items: end;
            height: 100%;
            width: 100%;
            color: var(--bright-orange);
    
            form {
                width: 100%;
            }
            span.input-label {
                font-size: 20px;
                // padding-left: 15px;
                // height: 2.5rem;
                font-size: 20px;
                color: var(--white-one);
                margin: 0 0 20px 0;
            }
    
            span.input-error {
                font-size: 1rem;
                padding-left: 0;
                height: 2.5rem;
                line-height: 2.5rem;
            }
    
            input {
                // padding: calc(10 * var(--newSize)) calc( 10 * var(--newSize)) calc(10 * var(--newSize)) calc(10 * var(--newSize));
                // border-radius: 4rem;
                &::placeholder {
                    color: var(--white-one);
                    font-size: 14px;
                }
                padding: 0;
                height: 40px ;
                // border-left: none;
                border-radius: 5px ;
            }
    
            .input {
                margin-bottom: 0;
                width: 100% !important;
                // border-radius: 1rem 0 0 0;
                font-size: 14px;
               
                
            }
            .campoCupons {
                display: flex;
                width: 100%;
                align-items: flex-end;
                // margin: 20px 0 0 0;
                gap: 10px;
                // padding-right: 10px;

                width: 95%;
                margin: 20px auto 0;
    
                .input-retangular {
                    width: 100%;
                    margin: 0 0 0 0;
                    
                    input {
                        background-color: #363030 !important;
                        padding: 0 10px !important;
                        height: 40px !important;
                        
                    }

                    input::placeholder {
                        font-size: 14px;
                        color: #616161;
                    }
                }

                .campoCupom-input {
                    margin: 0;     

                }
    
            }
        }
        
       
        .conta-saldo-botao {
    
            background-color: var(--bright-orange);
            // margin-left: 20px;
            // max-width: calc(50 * var(--newSize));
            // min-width: calc(50 * var(--newSize));
            // max-height: calc(50 * var(--newSize));
            // min-height: calc(50 * var(--newSize));
    
            // .transparencia {
            //     background-color: var(--bright-orange);
            // }
            width: 40px;
            height: 40px;    
            
            border-radius: 50%;
            border: none;
            // flex-wrap: nowrap;
            flex-shrink: 0;    
        }

        .conta-saldo-botao-text {
            font-size: 12px;
               
        }
    }
    
}

@media (min-width: 450px) {
    .conta-saldo {
        display: flex;
        flex-direction: column;
        margin: 10px 0 0 0;
       
    
        .conta-saldo-titulo {
            display: flex;
            font-size: 20px;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.88px;
            color: var(--bright-orange);
            justify-content: center;
        }
    
    
        .conta-saldo-cupom {
            display: flex;
            align-items: center;
            // justify-items: end;
            height: 100%;
            width: 100%;
            color: var(--bright-orange);
    
            form {
                width: 100%;
            }
            span.input-label {
                font-size: 20px;
                // padding-left: 15px;
                // height: 2.5rem;
                margin-bottom: 20px;
                font-size: 20px;
                color: var(--white-one);
            }
    
            span.input-error {
                font-size: 1rem;
                padding-left: 0;
                height: 2.5rem;
                line-height: 2.5rem;
            }
    
            input {
                // padding: calc(10 * var(--newSize)) calc( 10 * var(--newSize)) calc(10 * var(--newSize)) calc(10 * var(--newSize));
                // border-radius: 4rem;
                &::placeholder {
                    color: var(--white-one);
                    font-size: 1.15rem;
                }
                padding: 0 !important;
                height: 30px !important;
                // border-left: none;
                border-radius: 5px ;
            }
    
            .input {
                margin-bottom: 0;
                width: 100% !important;
                // border-radius: 1rem 0 0 0;
                
            }
            .campoCupons {
                display: flex;
                width: 100%;
                align-items: flex-end;
                // margin: 20px 0 0 0;
                gap: 20px;
                // padding-right: 10px;

                width: 95%;
                margin: 20px auto 0;
    
                .input-retangular {
                    width: 100%;
                    margin: 0 0 0 0;
                    
                    input {
                        background-color: #363030 !important;
                        padding: 0 10px !important;
                        height: 40px !important;
                        
                    }

                    input::placeholder {
                        font-size: 14px;
                        color: #616161;
                    }
                }

                .campoCupom-input {
                    margin: 0;     

                }
    
            }
        }
        
        .conta-saldo-botao {
    
            background-color: var(--bright-orange);
            // margin-left: 20px;
            // max-width: calc(50 * var(--newSize));
            // min-width: calc(50 * var(--newSize));
            // max-height: calc(50 * var(--newSize));
            // min-height: calc(50 * var(--newSize));
    
            // .transparencia {
            //     background-color: var(--bright-orange);
            // }
            width: 40px;
            height: 40px;    
            
            border-radius: 50%;
            border: none;
            // flex-wrap: nowrap;
            flex-shrink: 0;    
        }

        .conta-saldo-botao-text {
            font-size: 12px;
               
        }
    }   
}

@media (min-width: 794px) {
    .conta-saldo {
        display: flex;
        flex-direction: column;
        margin: 0 0 0 0;
    
        .conta-saldo-titulo {
            display: flex;
            font-size: 20px;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.88px;
            color: var(--bright-orange);
            justify-content: center;
        }
    
    
        .conta-saldo-cupom {
            display: flex;
            align-items: center;
            // justify-items: end;
            height: 100%;
            width: 100%;
            color: var(--bright-orange);
    
            form {
                width: 100%;
            }
            span.input-label {
                font-size: 20px;
                // padding-left: 0 !important;
                // height: 2.5rem;
                line-height: 20px;
                color: var(--white-one);
            }
    
            span.input-error {
                font-size: 1rem;
                padding-left: 0;
                height: 2.5rem;
                line-height: 2.5rem;
            }
    
            input {
                // padding: calc(10 * var(--newSize)) calc( 10 * var(--newSize)) calc(10 * var(--newSize)) calc(10 * var(--newSize));
                // border-radius: 4rem;
                &::placeholder {
                    color: var(--white-one);
                    font-size: 14px;
                }
                padding: 0 !important;
                height: 30px !important;
                // border-left: none;
                border-radius: 5px ;
            }
    
            .input {
                margin-bottom: 0;
                width: 100% !important;
                // border-radius: 1rem 0 0 0;
                
            }
            
            .campoCupons {
                display: flex;
                width: 95%;
                margin: 20px auto 0;
                
                // margin-top: 10%;
    
                .input-retangular {
                    width: 100%;

                    input {
                        background-color: #363030 !important;
                        padding: 0 10px !important;
                        height: 40px !important;
                    }

                    input::placeholder {
                        font-size: 14px;
                        color: #616161;
                    }
                }
    
             
    
            }
        }
        
        .conta-saldo-botao {
    
            background-color: var(--bright-orange);
            // margin-left: 20px;
            // max-width: calc(50 * var(--newSize));
            // min-width: calc(50 * var(--newSize));
            // max-height: calc(50 * var(--newSize));
            // min-height: calc(50 * var(--newSize));
    
            // .transparencia {
            //     background-color: var(--bright-orange);
            // }
            width: 40px;
            height: 40px;    
            
            border-radius: 50%;
            border: none;
            // flex-wrap: nowrap;
            flex-shrink: 0;    
        }

        .conta-saldo-botao-text {
            font-size: 12px;
               
        }
    }   
}

@media (min-width: 1024px) {
    .conta-saldo {
        display: flex;
        flex-direction: column;
        margin: 0 0 0 0;
    
        .conta-saldo-titulo {
            display: flex;
            font-size: 20px;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.88px;
            color: var(--bright-orange);
            justify-content: flex-start !important;
            margin: 20px 0 0 0;
        }
    
    
        .conta-saldo-cupom {
            display: flex;
            align-items: center;
            // justify-items: end;
            height: 100%;
            width: 100%;
            color: var(--bright-orange);
    
            form {
                width: 100%;
            }
            span.input-label {
                font-size: 1.5rem;
                padding-left: 0 !important;
                height: 2.5rem;
                line-height: 2.5rem;
                color: var(--white-one);
                margin: 0 0 16px 0;
               
            }
    
            span.input-error {
                font-size: 1rem;
                padding-left: 0;
                height: 2.5rem;
                line-height: 2.5rem;
            }
    
            input {
                // padding: calc(10 * var(--newSize)) calc( 10 * var(--newSize)) calc(10 * var(--newSize)) calc(10 * var(--newSize));
                // border-radius: 4rem;
                &::placeholder {
                    color: var(--white-one);
                    font-size: 1.15rem;
                }
                padding: 0 !important;
                height: 30px;
                // border-left: none;
                border-radius: 5px ;
            }
    
            .input {
                margin-bottom: 0;
                width: 100% !important;
                // border-radius: 1rem 0 0 0;
                
            }
            .campoCupons {
                display: flex;
                width: 100%;
                
                // margin-top: 10%;
    
                .input-retangular {
                    width: 100%;
                    margin: 0 0 0 0;
                    input {
                        background-color: #363030 !important;
                        padding: 0 10px !important;
                        height: 40px !important;

                    }

                    input::placeholder {
                        font-size: 14px;
                        color: #616161;
                    }
                }
    
                
    
            }
        }
        
        .conta-saldo-botao {
    
            background-color: var(--bright-orange);
            // margin-left: 20px;
            // max-width: calc(50 * var(--newSize));
            // min-width: calc(50 * var(--newSize));
            // max-height: calc(50 * var(--newSize));
            // min-height: calc(50 * var(--newSize));
    
            // .transparencia {
            //     background-color: var(--bright-orange);
            // }
            width: 40px;
            height: 40px;    
            
            border-radius: 50%;
            border: none;
            // flex-wrap: nowrap;
            flex-shrink: 0;    
        }

        .conta-saldo-botao-text {
            font-size: 12px;
               
        }
    }   
}