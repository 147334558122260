// @import "../../../assets/styles/variaveis.scss";

// .foto-perfil-edit {
//     display: flex;
//     align-items: center;
//     flex-direction: column;

//     .foto-peril-edit-img {
//         width: 190px;
//         height: 190px;
//         flex-grow: 0;
//         margin: 0;
//         box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
//         background-color: #777;
//         border-radius: 50%;
//         border: 0.1px solid var(--bright-orange);
//     }

//     .camera-icon {
//         margin-top: 8px;
//         width: calc(28 * var(--newSize));
//         cursor: pointer;
//     }
// }

@media (max-width: 450px) {

    .foto-perfil-edit {
        display: flex;
        align-items: center;
        flex-direction: column;
    
        .foto-peril-edit-img {
            height: 120px;
            width: 120px;
            background-color: #777;
            border-radius: 50%;
            border: 2px solid var(--bright-orange);
        }
    }
}

@media (min-width: 450px) {

    .foto-perfil-edit {
        display: flex;
        align-items: center;
        flex-direction: column;
    
        .foto-peril-edit-img {
            height: 150px !important;
            width: 150px !important;
            background-color: #777;
            border-radius: 50%;
            border: 0.1px solid var(--bright-orange);
        }
    }
}

@media screen and (min-width: 794px) {

    .foto-perfil-edit {
        display: flex;
        align-items: center;
        flex-direction: column;
    
        .foto-peril-edit-img {
            height: 150px !important;
            width: 150px !important;
            background-color: #777;
            border-radius: 50%;
            border: 0.1px solid var(--bright-orange);
        }
    }
}

@media (min-width: 1024px) {

    .foto-perfil-edit {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    
        .foto-peril-edit-img {
            height: 150px !important;
            width: 150px !important;
            background-color: #777;
            border-radius: 50%;
            border: 2px solid var(--bright-orange);
        }
    }
}

@media screen and (min-width: 1200px) {

    .foto-perfil-edit {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    
        .foto-peril-edit-img {
            height: 200px !important;
            width: 200px !important;
            background-color: #777;
            border-radius: 50%;
            border: 2px solid var(--bright-orange);
        }
    }
}
