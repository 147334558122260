@import "../../../assets/styles/variaveis.scss";
.progresso {
    display: flex;
    flex-direction: row;

    .barra-total {
        width: calc(348 * var(--newSize));
        height: 6px;
        border-radius: 3px;
        background-color: var(--warm-grey);
        position: relative;

        .progresso-total {
            position: absolute;
            height: 6px;
            border-radius: 3px;
            background-color: var(--bright-orange);
        }
    }
}

@media (max-width: $max-width-mobile) {
    .progresso {
        .barra-total {
            width: calc(310 * var(--newSize));
        }
    }
}
