.custom-dot-item {
    z-index: 1;
    margin-top: calc(-50 * var(--newSize));
    margin-right: calc(15 * var(--newSize));
    margin-left: calc(15 * var(--newSize));
    height: calc(7 * var(--newSize));
    width: calc(48 * var(--newSize));

    background-color: var(--greyish-brown);
    cursor: pointer;

    @media screen and (max-width: 992px) {
        margin-top: 0px;
        height: 3px;
        width: 24px;
        margin-right: 10px;
        margin-left: 10px;
    }

    
}

.custom-dot-item-ativo {
    background-color: var(--bright-orange);
}
