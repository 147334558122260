@import "../../../assets/styles/variaveis.scss";
.item-banner {
    display: "relative";
    height: calc(626 * var(--newSize));

    .item-banner-background {
        right: 0;
        left: 0;
        top: 20px;
        position: fixed;
        opacity: 0.9;
        background-position-x: center;
        background-position-y: top;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
    }

    .item-banner-conteudo {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: calc(86 * var(--newSize));
    }
}

// @media (max-width: 1366px) {
//     .item-banner {
//         height: 500px;
//         .item-banner-background {
//             height: 100%;
//         }
//     }
// }
// mobile

@media (max-width: $max-width-mobile) {
    .item-banner {
        height: calc(250 * var(--newSize));
        //height: 100%;
        width: 100%;
        .item-banner-conteudo {
            margin-top: 62px; // mesma altura do menu

            -webkit-transform: translate3d(0, 0, 0px);
            transform: translateZ(0px);
            background: unset;
            width: 100%;
            //background-color: rgba(red, 0.5);
        }

        .item-banner-background {
            position: absolute;
            background-repeat: no-repeat;

            background-size: cover;
            background-position: center center;

            -webkit-transform: translate3d(0, 0, -2px);
            //transform: translate3d(0, 0, 1px);
            transform: translateZ(-2px);
            //background-attachment: ;
        }
    }
}
