@import "../../../assets/styles/variaveis.scss";
.extrato-ponto {
    padding-top: 50px;
    width: calc(1014 * var(--newSize));
    height: calc(532 * var(--newSize));
    box-sizing: border-box;
    padding-bottom: 150px;

    .extrato-header {
        display: flex;
        flex-direction: row;

        color: #000;

        .header-title {
            font-size: 2rem;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.5;
            letter-spacing: normal;
            text-align: left;
            color: var(--black);
            margin-left: calc(46 * var(--newSize));
        }

        .extrato-saldo {
            position: absolute;
            right: calc(46 * var(--newSize));
            font-size: 1.5rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.87;
            letter-spacing: normal;

            color: var(--black);
        }
    }
    .table-extrato-ponto {
        margin-top: calc(44 * var(--newSize));
        .table-head {
            width: 100%;
            display: flex;
            flex-direction: row;
            font-size: 1rem;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            color: var(--black);
            height: calc(56 * var(--newSize));
            align-items: center;
        }

        .table-body {
            width: 100%;
            display: flex;
            flex-direction: column;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 20px;
            letter-spacing: normal;
            text-align: left;
            color: var(--black);

            overflow: auto;
            max-height: 240px;

            .row {
                width: 100%;
                display: flex;
                flex-direction: row;
                height: calc(56 * var(--newSize));
                align-items: center;
            }

            .row:nth-child(odd) {
                background-color: #f7f7f7;
            }
        }

        .col1 {
            width: calc(280 * var(--newSize));
            margin-left: calc(46 * var(--newSize));
        }

        .col2 {
            width: calc(375 * var(--newSize));
        }

        .col3 {
            width: calc(190 * var(--newSize));
        }

        .col4 {
            width: calc(80 * var(--newSize));
            margin-right: calc(46 * var(--newSize));
        }
        .col3-valor {
            padding-left: calc(46 * var(--newSize));
        }
    }
}

@media (max-width: $max-width-mobile) {
    .extrato-ponto {
        padding-top: 50px;
        width: 100%;
        height: calc(532 * var(--newSize));
        box-sizing: border-box;
        padding-bottom: 150px;

        .extrato-header {
            display: flex;
            flex-direction: column;
            padding-left: 20px;
            box-sizing: border-box;
            .header-title {
                font-size: 1rem;
                line-height: 1;
                margin-left: 0;
            }

            .extrato-saldo {
                position: relative;
                right: 0;
                font-size: 1rem;
                line-height: 1.5;
            }
        }
        .table-extrato-ponto {
            margin-top: calc(16 * var(--newSize));
            .table-head {
                font-size: 0.75rem !important;
                height: calc(49 * var(--newSize));
            }

            .table-body {
                width: 100%;
                display: flex;
                flex-direction: column;
                font-size: 0.75rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: left;
                color: var(--black);

                .row {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    height: calc(49 * var(--newSize));
                    align-items: center;
                }

                .row:nth-child(odd) {
                    background-color: #f7f7f7;
                }
            }

            .col1 {
                width: calc(70 * var(--newSize));
                margin-left: calc(20 * var(--newSize));
            }

            .col2 {
                width: calc(108 * var(--newSize));
                padding-left: calc(8 * var(--newSize));
            }

            .col2.evento-mobile {
                font-size: 0.625rem;
                align-items: flex-start;
            }

            .col3 {
                width: calc(50 * var(--newSize));
            }

            .col4 {
                width: calc(80 * var(--newSize));
                margin-right: calc(20 * var(--newSize));
                display: none;
            }
            .col3-valor {
                padding-left: calc(26 * var(--newSize));
                text-align: right;
                padding-right: calc(20 * var(--newSize));
            }
        }
    }
}
