@import "../../../assets/styles/variaveis.scss";
.recuperar-senha-container {
    box-sizing: border-box;
    padding: 10px 50px;
    width: calc(500 * var(--newSize));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .recuperar-senha-inputs {
        margin-top: 13.8%;
        width: 100%;
    }

    .recuperar-senha-acoes {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        a {
            align-items: center;
            line-height: calc(60px * var(--newSize));
            height: calc(60px * var(--newSize));
        }

        .nao-tem-cadastro {
            margin-top: calc(40 * var(--newSize));
            font-family: Roboto;
            font-size: 1.4rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.73;
            letter-spacing: normal;
            text-align: center;
            color: var(--warm-grey);
        }
    }
}

@media (max-width: $max-width-mobile) {
    .recuperar-senha-container {
        padding: 10px 50px;
        width: 100%; // calc(500 * var(--newSize));
    }
}
