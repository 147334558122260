.container-lista-provider-horizontal {
    height: 100;
    background-color: "#ccc";
    padding: 0 20px 20px;
    border-bottom: solid 1px rgba(255, 255, 255, 0.4);

    .lista-cards-providers {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
        transition: 0.5s;
        // height: calc(90 * var(--newSize));
    }

    .lista-cards-providers:hover {
    }

    .lista-cards-providers::-webkit-scrollbar {
        height: 2px !important; // manage scrollbar width here
    }
    .lista-cards-providers::-webkit-scrollbar * {
        background: transparent !important; // manage scrollbar background color here
    }

    .card-provider-customizado {
        margin-bottom: 10px;
    }

    .card-provider-customizado-inativo {
        filter: grayscale(1);
        opacity: 0.6;
    }

    @media (max-width: 992px) {
        .lista-cards-providers {
            overflow: auto;
            width: 100%;
        }
    }
}

.link-adicionar-mais-plataformas {
    margin-top: calc(55 * var(--newSize));
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
