@import "../../../assets/styles/variaveis.scss";

.post-body {
    width: calc(1252 * var(--newSize));

    padding-left: calc(218 * var(--newSize));
    box-sizing: border-box;
    padding-bottom: calc(327 * var(--newSize));

    .post-body-data {
        font-size: 1rem;
        letter-spacing: normal;
        text-align: left;
        color: var(--warm-grey);
        margin-top: calc(17 * var(--newSize));
    }

    .post-body-text {
        margin-top: calc(48 * var(--newSize));
        margin-right: calc(115 * var(--newSize));
        white-space: pre-line;
        font-size: 1.1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.83;
        letter-spacing: normal;
        text-align: left;
        color: var(--white-one);
    }

    .post-body-thumb-images {
        margin-top: calc(30 * var(--newSize));
        display: flex;
        flex-direction: row;
    }

    .post-body-thumb-images-item:not(:first-child) {
        margin-left: calc(20 * var(--newSize));
    }

    .post-body-button {
        margin-top: calc(128 * var(--newSize));
        width: calc(226 * var(--newSize));
    }
}

@media (max-width: $max-width-mobile) {
    .post-body {
        width: calc(326 * var(--newSize));

        padding-left: unset; // calc(218 * var(--newSize));
        // padding-bottom: calc(327 * var(--newSize));

        margin-left: calc(18 * var(--newSize));
        margin-right: calc(16 * var(--newSize));
        padding-bottom: unset;

        .post-body-data {
            margin-top: calc(24 * var(--newSize)) !important;
            font-size: 0.9rem;
            margin-top: calc(17 * var(--newSize));
        }

        .post-body-text {
            margin-top: calc(24 * var(--newSize));
            margin-right: unset; //calc(115 * var(--newSize));

            font-family: Roboto;
            font-size: 0.96rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.52;
            letter-spacing: normal;
            text-align: left;
            color: var(--white-one);
        }

        .post-body-thumb-images {
            margin-top: calc(30 * var(--newSize));
            width: calc(500 * var(--newSize)) !important;

            // display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            // -webkit-overflow-scrolling: touch;
        }

        .post-body-button {
            margin-top: calc(18 * var(--newSize));
        }
    }
}
