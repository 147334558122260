@import "../../../assets/styles/variaveis.scss";

.mensagem-padrao {
    width: calc(300 * var(--newSize));
    max-width: calc(300 * var(--newSize));
    height: calc(80 * var(--newSize));
    border-radius: 8px;
    background-color: var(--warm-grey);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 10000;
    font-size: 1.1rem;
    font-weight: 500;
    transition: 0.9s ease;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    padding: 10px;
    text-align: center;
}

.mensagem-padrao-error {
    color: white;
    background-color: #db2826;
}

@media screen and (max-width: 1366px) {
}

@media screen and (max-width: 992px) {
}

@media (max-width: $max-width-mobile) {
    .mensagem-padrao {
    }
}
