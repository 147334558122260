section {
    background-color: #f6f6f6;
    font-family: "Inter", sans serif;
}

.login-welcome {
    position: relative;
    background-image: url('/assets/img/usemais/banner_login_background.png');
    background-repeat: no-repeat;
}

.main-text {
    font-weight: bold;
    font-size: 50px;

}

.sub-text {
    font-weight: 500;
    font-size: 35px;
}

.password-text-tip {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #8A8A8A;
}