@import "../../../assets/styles/variaveis.scss";
.anuncio-tira-em-pe {
    .imagemCapa {
        width: calc(356 * var(--newSize));
        height: calc(724 * var(--newSize));
    }
}

@media (max-width: $max-width-mobile) {
    .anuncio-tira-em-pe {
        .imagemCapa {
            width: calc(157 * var(--newSize));
            height: calc(319 * var(--newSize));
        }
    }
}
