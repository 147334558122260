@import "../../../assets/styles/variaveis.scss";

.busca-local {
    margin-bottom: calc(30 * var(--newSize));
    width: 100%;
}

@media (max-width: $max-width-mobile) {
    .busca-local {
        margin-bottom: calc(15 * var(--newSize));
    }
}
