@import "../../../assets/styles/variaveis.scss";

.backdrop {
    min-width: calc(1440 * var(--newSize));
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;

    .efeito-gradiente {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.95) 25%, transparent 80%);
        bottom: 0;
        position: absolute;
        height: calc(50 * var(--newSize));

        width: 100%;
    }

    .efeito-gradiente-direita-esquerda {
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 25%, transparent 60%);
        position: absolute;
        height: 100%;
        width: 100%;
    }

    .backdrop-image {
        width: 100%;
        height: 100%;
        background-size: cover;
        position: absolute;
        z-index: -2;
    }

    // .backdrop-image-opacity {
    //     opacity: 0.6;
    // }

    // @media (max-width: 600px) {
    //     min-width: 600px;
    //     max-width: 600px;

    //     .backdrop-image {
    //         background-size: cover;
    //     }
    // }
}

@media (max-width: $max-width-mobile) {
    .backdrop {
        min-width: unset; //calc(1440 * var(--newSize));
        width: 100%;
        height: calc(208 * var(--newSize));

        .backdrop-image {
            background-size: cover;
            background-position-x: center;
        }
    }
}
