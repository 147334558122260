@import "../../assets/styles/variaveis.scss";
.pesquisa-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: calc(409 * var(--newSize));
    padding-right: calc(124 * var(--newSize));
    margin-bottom: 60px;
}

.titulo-pesquisa-busca {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .termos-da-busca {
        font-size: 3rem;
        font-weight: 900;
        line-height: normal;
        letter-spacing: normal;
        color: var(--white-one);
    }

    .informacoes-resultado {
        margin-top: calc(40 * var(--newSize));
        font-size: 2.46rem;
        font-weight: 900;
        color: var(--white-one);

        span {
            color: var(--bright-orange);
        }

        .pesquisa-aguarde-msg {
            animation: blink 1s step-start 0s infinite;
            @keyframes blink {
                50% {
                    opacity: 0.9;
                }
            }
        }
    }
}

.pesquisa-container-busca-custom {
    margin-top: calc(458 * var(--newSize));
}

.pesquisa-container-busca-custom-google-ad {
    margin-top: calc(330 * var(--newSize));
}


.anuncio-resultado-busca-custom {
    position: sticky;
    top: calc(50 * var(--newSize));
    right: calc(124 * var(--newSize));
    /* Reserve space for header plus some extra space for sneak peeking. */
    // scroll-padding-top: 15vh;
}


@media (max-width: $max-width-mobile) {
    .pesquisa-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: calc(33 * var(--newSize));
        padding-right: 0;
        margin-bottom: 0;
    }

    .titulo-pesquisa-busca {
        .termos-da-busca {
            font-size: 1.5rem;
        }

        .informacoes-resultado {
            margin-top: calc(12 * var(--newSize));
            font-size: 1rem;
            padding-left: calc(40 * var(--newSize));
            padding-right: calc(40 * var(--newSize));
            text-align: center;

            span {
                color: var(--bright-orange);
            }
        }
    }

    .anuncio-resultado-busca-custom {
        position: unset;
        top: calc(50 * var(--newSize));
        right: 0; //calc(124 * var(--newSize));
    }
}
