@import "../../../assets/styles/variaveis.scss";

.containerListaFilmeHorizontal {
    height: 200;
    background-color: "#ccc";
    padding: 20px;
    margin-bottom: 104px;

    .titulo {
        display: flex;
        align-items: first baseline;
        margin-bottom: 37px;

        div:last-child {
            margin-left: 33px;

        }

        .titulo-ver-mais {
            cursor: pointer;
        }
    }

    @media (max-width: 992px) {
        margin-bottom: 24.3px;
        
        .titulo {
            margin-bottom: 11px;
    
            div:last-child {
                margin-left: 11px;
    
            }
        }
    }
}

.titulo-lista-horizontal {
    font-size: 28px;
    line-height: 30px;
    font-family: "DIN Pro Bold", sans-serif;
    color: #fff;
    letter-spacing: -1px;
    font-weight: 400;
}

@media (max-width: $max-width-mobile) {
    .containerListaFilmeHorizontal {
        padding: 0px !important;
        margin-left: 12px;
        margin-right: 12px;
    }
}


.carousel-lista-gifts-resgatados {

    .react-multi-carousel-list  ul {
        gap: 8px;
    }
    

    .react-multi-carousel-list ul li{
        width: 300px !important;
        margin: 5px;
    }
}
