@import "../../../assets/styles/variaveis.scss";
.onde-assistir {
    padding-left: calc(217 * var(--newSize));
    padding-top: calc(67 * var(--newSize)) h1 {

    }

    p {
        font-family: Roboto;
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.66;
        letter-spacing: normal;
        text-align: left;
        color: var(--white-one);
    }

    .onde-assistir-providers {
        display: flex;
        flex-direction: row;
    }

    .onde-assistir-provider {
        margin-right: 51px;
    }

    .onde-assistir-nao-disponivel {
        margin-top: calc(20 * var(--newSize));
        font-family: Roboto;
        font-size: 1.2rem;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.73;
        letter-spacing: normal;
        text-align: left;
        color: var(--white-one);
    }
}

@media (max-width: $max-width-mobile) {
    .onde-assistir {
        padding-left: calc(20 * var(--newSize));
        padding-right: calc(20 * var(--newSize));
        padding-top: calc(40 * var(--newSize));
        padding-bottom: calc(40 * var(--newSize));

        h1 {
            font-size: 1.4rem;
        }

        p {
            font-family: Roboto;
            font-size: 1rem;
        }

        .onde-assistir-provider {
            margin-right: 6px;

            .provider-group-cards {
                flex-wrap: wrap;
                .card-box-provider {
                    margin-bottom: 12px;
                }
            }
        }

        .onde-assistir-nao-disponivel {
            margin-top: calc(20 * var(--newSize));
            font-size: 0.95rem;
        }
    }
}
