@import "./colors";

@font-face {
    font-family: Roboto;
    src: url(../fonts/Roboto-Thin.ttf) format("truetype");
    font-weight: 100;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: Roboto;
    src: url(../fonts/Roboto-Light.ttf) format("truetype");
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: Roboto;
    src: url(../fonts/Roboto-Regular.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: Roboto;
    src: url(../fonts/Roboto-Medium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: Roboto;
    src: url(../fonts/Roboto-Bold.ttf) format("truetype");
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: Roboto;
    src: url(../fonts/Roboto-Black.ttf) format("truetype");
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
}

.Text-Style {
    font-family: Roboto;
    font-size: 96.5px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-one);
}

.Text-Style-2 {
    font-family: Roboto;
    font-size: 2rem;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--white-one);
    @media (max-width: 992px) {
        font-size: 16px;
    }
}

.Text-Style-5 {
    font-family: Roboto;

    font-size: 17.5px;

    font-weight: 500;

    font-stretch: normal;

    font-style: normal;

    line-height: normal;

    letter-spacing: 0.88px;

    text-align: left;

    color: var(--white-one);
}

.Text-Style-8 {
    font-family: Roboto;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.66;
    letter-spacing: normal;
    text-align: left;
    color: var(--warm-grey);
}

.Text-Style-3 {
    font-family: Roboto;

    font-size: 1rem;

    font-weight: 500;

    font-stretch: normal;

    font-style: normal;

    line-height: normal;

    letter-spacing: normal;

    text-align: left;

    color: var(--bright-orange);

    @media (max-width: 992px) {
        font-size: 8px;
    }
}

.Text-Style-6 {
    font-family: Roboto;

    font-size: 1rem;

    font-weight: 500;

    font-stretch: normal;

    font-style: normal;

    line-height: normal;

    letter-spacing: 0.4px;

    text-align: center;

    color: var(--white-one);
}

.Text-Style-4 {
    font-family: Roboto;

    font-size: 16px;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: normal;

    letter-spacing: normal;

    text-align: center;

    color: var(--white-one);
}

.Text-Style-7 {
    font-family: Roboto;

    font-size: 16px;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: 1.66;

    letter-spacing: normal;

    text-align: left;

    color: var(--white-one);
}

.Text-Style-9 {
    font-family: Roboto;

    font-size: 1.1rem; // 16px;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: 1.47;

    letter-spacing: normal;

    text-align: left;

    color: var(--white-one);
}
