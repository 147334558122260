.container-anuncio-tira-em-pe-titulo {
    height: 100;
    background-color: "#ccc";
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    .lista-anuncios-tira-em-pe-titulo {
        font-size: 1rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: var(--warm-grey);

    }

    .lista-anuncios {
        margin-top:26px;
        display: flex;
        overflow: hidden;
        width: 100%;
        flex-wrap: nowrap;
        transition: 0.5s;
        justify-content: center;

        
    }

    

    
    @media (max-width: 992px) {

    }
}
