@import "../../../assets/styles/variaveis.scss";

.dados-filme {
    padding-top: calc(50 * var(--newSize));
    padding-left: calc(217 * var(--newSize));
    padding-right: 8%;

    .dados-gerais-sinopse {
        width: calc(534 * var(--newSize));

        margin-top: calc(32 * var(--newSize));
    }
    .dados-gerais-votacao {
        margin-top: calc(70 * var(--newSize));
    }

    /* ******************* */
    .staff-poster {
        display: flex;
        flex-direction: row;
        padding-top: 8%;

        .sessao-staff-compartilhar {
            flex: 6;
        }

        .staff {
            .nomes {
                color: #fccd0a;
                transition: 0.5s;
            }
        }

        .compartilhar {
            display: flex;
            width: 100%;
            padding-top: 8%;

            .rating {
                flex: 1;
            }

            .like {
                flex: 1;
                text-align: center;
            }

            .share {
                flex: 2;
                text-align: right;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                min-height: calc(100 * var(--newSize));

                a {
                    color: #fff;
                    text-decoration: none;
                }

                a:hover {
                    color: #fccd0a;
                }
            }
        }

        .poster {
            min-width: calc(100 * var(--newSize));
            width: 16%;
            padding: 4%;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            .poster-image {
                width: 100%;
                border: 2px solid #fff;
            }

            @media (max-width: 600px) {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding-left: 0;
                .poster-image {
                    width: 80%;
                }
            }
        }
    }

    @media (max-width: 600px) {
        .generos {
            display: none;
        }

        .staff {
            display: none;
        }

        h1 {
            font-size: 32px;
        }
    }
}

@media (max-width: $max-width-mobile) {
    .dados-filme {
        padding-top: calc(50 * var(--newSize)); // 150
        padding-left: calc(16 * var(--newSize));
        padding-right: 5%;

        .dados-gerais-sinopse {
            width: calc(325 * var(--newSize));
            margin-top: calc(17 * var(--newSize));
        }

        .dados-gerais-votacao {
            margin-top: calc(38 * var(--newSize));
        }
    }
}
