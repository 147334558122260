@import "../../../assets/styles/variaveis.scss";

.thumb-wide {
    cursor: pointer;
    width: calc(262 * var(--newSize));
    height: calc(148 * var(--newSize));
    border-radius: 8px;
    
    background-size: contain;

}

@media (max-width: $max-width-mobile) {
    .thumb-wide {
        width: calc(89 * var(--newSize));
        height: calc(88 * var(--newSize));
    }
}