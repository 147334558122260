@import "../../../assets/styles/variaveis.scss";

.titulo-vermelho-neon {
    min-width: calc(414 * var(--newSize));
    min-height: calc(65 * var(--newSize));

    border: solid 1px var(--bright-orange);
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .titulo-vermelho-divisor {
        width: calc(28 * var(--newSize));
        border-right: solid 1px var(--bright-orange);
        height: calc(65 * var(--newSize));
    }

    .titulo-vermelho-texto {
        width: 100%;
        text-align: center;
        font-size: 1.81rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: var(--bright-orange);
    }
}

@media (max-width: $max-width-mobile) {
    .titulo-vermelho-neon {
        border: unset;

        .titulo-vermelho-texto {
            font-size: 0.96rem;
            text-align: left;
        }
        
        .titulo-vermelho-divisor {
            display: none;
        }


    }
}