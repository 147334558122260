@import "../../assets/styles/variaveis.scss";
.lista-titulos-plataforma {
    .lista-titulos-plataforma-fundo {
        height: calc(500 * var(--newSize));
    }

    .titulo-pagina {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .titulo-principal {
            font-size: 3rem;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: var(--white-one);
        }

        .titulo-secundario {
            margin-top: 39px;
            font-size: 1.46rem !important;
            font-weight: 500 !important;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: var(--white-one);
            padding-left: 150px;
            padding-right: 150px;

            span {
                color: var(--bright-orange);
            }
        }
    }
}

body {
    background: black;
}

@media (max-width: $max-width-mobile) {
    .lista-titulos-plataforma {
        .lista-titulos-plataforma-fundo {
            height: calc(500 * var(--newSize));
        }

        .titulo-pagina {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .titulo-principal {
                font-size: 3rem;
                font-weight: 900;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: var(--white-one);
            }

            .titulo-secundario {
                margin-top: 05px !important;
                font-size: 0.6rem !important;
                font-weight: 500 !important;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: var(--white-one);
                padding-left: 20px;
                padding-right: 20px;
                height: 50px;

                span {
                    color: var(--bright-orange);
                }
            }
        }
    }
}
