@import "../../../assets/styles/variaveis.scss";
.popup-aviso {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(733 * var(--newSize));
    height: calc(284 * var(--newSize));
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: center;

    .popup-aviso-titulo {
        margin-top: calc(82 * var(--newSize));
        font-size: 2rem;
        line-height: 0.5;
        letter-spacing: normal;
        color: var(--bright-orange);
        font-weight: bold;
    }
    .popup-aviso-mensagem {
        margin-top: calc(29 * var(--newSize));
        font-size: 1.125rem;
        line-height: 1.78;
        letter-spacing: normal;
        width: 60%;
        font-weight: bold;
        text-align: center;
        color: #dddddd;
    }
}

@media (max-width: $max-width-mobile) {
    .popup-aviso {
        width: 100%;
        height: 100%;
        .popup-aviso-titulo {
            margin-top: calc(112 * var(--newSize));
            font-size: 1.3rem;
        }
        .popup-aviso-mensagem {
            margin-top: calc(29 * var(--newSize));
            font-size: 1rem;
            font-weight: normal;
            line-height: 1.78;

            padding-left: 10px;
            padding-right: 10px;
        }
    }
}
