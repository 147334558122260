@import "../../../assets/styles/variaveis.scss";

.botao-arredondado {
    cursor: pointer;
    min-width: calc(165 * var(--newSize));
    height: calc(58 * var(--newSize));
    border-radius: calc(29 * var(--newSize));
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.32);
    background-color: var(--bright-orange);
    display: flex;
    justify-content: center;
    align-items: center;
    border: unset;

    .text {
        font-family: Roboto;
        font-size: 1.1rem;
        font-weight: 900;
        line-height: 2.03;
        letter-spacing: 1.34px;
        text-align: center;
        color: var(--white-one);
        text-transform: uppercase;
    }

    @media (max-width: 1366px) {
    }
}

.botao-arredondado:hover {
    background-color: #c60;
}

@media (max-width: $max-width-mobile) {
    .botao-arredondado {
        // margin-left: calc(59 * var(--newSize));
    }
}
