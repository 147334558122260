.card-popup-info-box {
    border-radius: 8px;
    width: calc(432 * var(--newSize));
    height: calc(481 * var(--newSize));
    border-radius: 8px;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.49);
    background-color: #1c1c1c;
    transition: 0.25s ease;
    position: relative;

    .card-popup-info-onde-assitir {
        position: absolute;
        padding-top: calc(16 * var(--newSize));
        padding-right: calc(17 * var(--newSize));
        top: 0;
        right: 0;

        height: calc(241 * var(--newSize));

        z-index: 10;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .card-popup-info-onde-assitir-dropdown-options {
            width: calc(96 * var(--newSize));
            height: calc(122 * var(--newSize));
            overflow-y: auto;
            overflow-x: hidden;
            display: flex;
            padding: calc(10 * var(--newSize));
            border-radius: 8px;
            background-color: #333;
            box-sizing: border-box;
            margin-top: calc(8 * var(--newSize));
            display: flex;
            flex-direction: column;

            .card-popup-info-custom-provider {
                margin-bottom: calc(6 * var(--newSize));

                height: calc(49 * var(--newSize)) !important;
                width: calc(49 * var(--newSize)) !important;
                margin-right: 0;

                .card-body {
                    width: calc(49 * var(--newSize)) !important;
                }

                img {
                    width: calc(49 * var(--newSize));
                }
            }
            .card-popup-info-custom-provider:last-child {
                margin-bottom: calc(0 * var(--newSize));
            }
        }

        .card-popup-info-onde-assitir-menu {
            width: calc(96 * var(--newSize));
            height: calc(90 * var(--newSize));
            padding: calc(10 * var(--newSize));
            box-sizing: border-box;
            border-radius: 4px;
            background-color: #1c1c1c;

            .card-popup-info-onde-assitir-titulo {
                font-size: 0.87rem;
                font-weight: normal;
                letter-spacing: normal;
                text-align: left;
                color: var(--white);
            }

            .card-popup-info-onde-assitir-provider-dropdown {
                display: flex;
                flex-direction: row;

                .card-popup-info-onde-assitir-dropdown {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    cursor: pointer;

                    img:hover {
                        transform: scale(1.15);
                    }
                }

                .card-popup-info-custom-provider {
                    margin-top: calc(6 * var(--newSize));

                    height: calc(49 * var(--newSize)) !important;
                    width: calc(49 * var(--newSize)) !important;
                    margin-right: 0;

                    .card-body {
                        width: calc(49 * var(--newSize)) !important;
                    }

                    img {
                        width: calc(49 * var(--newSize));
                    }
                }
            }
        }
    }

    .card-image-backdrop {
        width: 100%;
        height: calc(241 * var(--newSize));
        border-radius: 8px;
        background-color: #777;
    }

    .card-popup-icone {
        width: calc(64 * var(--newSize));
    }

    .card-popup-tipo {
        min-width: calc(70 * var(--newSize));
        height: calc(34 * var(--newSize));
        border-radius: 4px;
        background-color: #808080;
        position: absolute;
        padding-left: calc(10 * var(--newSize));
        padding-right: calc(10 * var(--newSize));
        top: calc(260 * var(--newSize));
        right: calc(20 * var(--newSize));
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
    }

    .titulo {
        min-width: calc(70 * var(--newSize));
        height: calc(34 * var(--newSize));
        border-radius: 4px;
        background-color: rgba(28, 28, 28, 0.85);
        position: absolute;
        padding-left: calc(10 * var(--newSize));
        padding-right: calc(10 * var(--newSize));
        top: calc(16 * var(--newSize));
        left: calc(16 * var(--newSize));
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
    }

    .body {
        padding: calc(34 * var(--newSize)) calc(26 * var(--newSize));

        .info-favoritar {
            display: flex;
            align-items: center;
            img:first-child {
                padding-right: calc(16 * var(--newSize));
            }

            .favoritar-custom {
                img {
                    width: calc(64 * var(--newSize));
                }
            }
        }

        .card-popup-sinopse {
            height: calc(60 * var(--newSize));
            max-height: calc(60 * var(--newSize));
            margin-top: calc(21 * var(--newSize));
            font-family: Roboto;

            font-size: 0.88rem;
            font-weight: normal;
            line-height: 1.57;
            letter-spacing: normal;
            text-align: left;
            color: var(--white-one);
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .card-popup-sinopse::before {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
        }

        .card-popup-generos {
            margin-top: calc(13 * var(--newSize));
            font-family: Roboto;
            font-size: 0.9rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.21;
            letter-spacing: normal;
            text-align: left;
            color: var(--white-one);
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .card-popup-generos div:not(:first-child) {
            display: list-item;
            padding-left: calc(0 * var(--newSize));
            margin-left: calc(30 * var(--newSize));
        }
    }
}

.card-body {
}

.card-opcoes-on-mouse-over {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0px;
    left: 0px;
    bottom: 5px;
    right: 10px;
    border-radius: 5px;
    padding: 14px;
    transition: all 0.25s;
    transform: scale(0.95);
}

.card-opcoes-on-mouse-over:hover {
    transform: scale(1);
}

.card-image-provider {
    height: 30.3px;
    width: 30.3px;
    float: right;
    border-radius: 4px;
    /* */
}
