@import "../../assets/styles/variaveis.scss";
.lista-titulos-plataforma {
    .lista-titulos-plataforma-fundo {
        height: calc(500 * var(--newSize));
    }

    .titulo-pagina {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .titulo-principal {
            font-size: 3rem;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: var(--white-one);
        }

        .titulo-secundario {
            margin-top: 39px;
            font-size: 2.46rem;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: var(--white-one);

            span {
                color: var(--bright-orange);
            }
        }
    }
}

body {
    background: black;
}

@media (max-width: $max-width-mobile) {

    .lista-titulos-plataforma {

        .menu {
            height: 180px;

            .efeito-gradiente {
                margin-top: calc(148 * var(--newSize));
            }
        }

        .lista-titulos-plataforma-fundo {
            height: calc(200 * var(--newSize));
        }

        .titulo-pagina {

            .titulo-principal {
                font-size: 1.5rem;
                margin-top: calc(34 * var(--newSize));
            }

            .titulo-secundario {
                margin-top: calc(17 * var(--newSize));
                font-size: 1rem;
            }
        }
    }
}