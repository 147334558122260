@import "../../assets/styles/variaveis.scss";

.anuncie-fundo {
    height: calc(624 * var(--newSize)) !important;
    .anuncie-cabecalho {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;

        .anuncie-titulo {
            margin-top: calc(43 * var(--newSize));
            color: #ffffff !important;
            letter-spacing: normal;
            font-family: Roboto;
            font-size: 3.43rem;
            font-weight: 900;
            text-align: center;
            color: var(--white);
        }
    }
}

.anuncie-body {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: calc(190 * var(--newSize));
    flex-direction: column;
    margin-bottom: 60px;

    .anuncie-conteudo {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: calc(196 * var(--newSize));

        .anuncie-conteudo-item {
            margin-top: calc(42 * var(--newSize));
            margin-left: calc(360 * var(--newSize));
            margin-right: calc(360 * var(--newSize));
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: calc(362 * var(--newSize));
            }

            span {
                margin-left: calc(57 * var(--newSize));
                font-size: 1.49rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: var(--white);

                a,
                a:link {
                    text-decoration: none;
                    font-weight: bold;
                    color: var(--bright-orange);
                }
            }
        }
    }

    .anuncie-com-a-gente {
        display: flex;
        background-color: #202020;
        height: calc(483 * var(--newSize));
        width: 100%;
        margin-top: calc(154 * var(--newSize));

        align-items: center;
        justify-content: center;
        flex-direction: column;

        a,
        a:link {
            font-size: 3rem;
            text-decoration: none;
            font-weight: bold;
            color: var(--bright-orange);
        }

        .anuncie-botao-custom {
            margin: unset;
            width: calc(714 * var(--newSize));
            height: calc(115 * var(--newSize));
            border-radius: calc(57 * var(--newSize));
            .text {
                font-size: 3rem;
            }
        }

        span {
            margin-bottom: calc(106 * var(--newSize));
            font-size: 3rem;
            font-weight: 900;

            letter-spacing: normal;

            text-align: center;

            color: var(--white);
        }
    }
}

@media (max-width: $max-width-mobile) {
    .anuncie-fundo {
        height: calc(208 * var(--newSize)) !important;
        .anuncie-cabecalho {
            img {
                display: none;
            }
            .anuncie-titulo {
                margin-top: calc(43 * var(--newSize));
                font-size: 1.5rem;
                font-weight: 900;
            }
        }
    }
    .anuncie-body {
        margin-top: calc(-20 * var(--newSize));
        margin-bottom: 0;
        .anuncie-conteudo {
            flex-direction: column;
            margin-top: calc(50 * var(--newSize));

            .anuncie-conteudo-item {
                margin-top: calc(44 * var(--newSize));
                margin-left: calc(17 * var(--newSize));
                margin-right: calc(17 * var(--newSize));
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                img {
                    width: calc(158 * var(--newSize));
                }

                span {
                    margin-top: calc(27 * var(--newSize));
                    margin-left: unset;
                    font-size: 1rem;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    text-align: center;
                }
            }
        }

        .anuncie-com-a-gente {
            height: calc(220 * var(--newSize));
            margin-top: calc(50 * var(--newSize));

            .anuncie-email {
                font-size: 1.3rem !important;
            }

            .anuncie-botao-custom {
                margin: unset;
                width: calc(320 * var(--newSize));
                height: calc(60 * var(--newSize));
                border-radius: calc(30 * var(--newSize));
                .text {
                    font-size: 1.4rem;
                }
            }

            span {
                margin-bottom: calc(30 * var(--newSize));
                font-size: 1.5rem;
                font-weight: 900;

                letter-spacing: normal;

                text-align: center;

                color: var(--white);
            }
        }
    }
}
