@import "../../../assets/styles/variaveis.scss";
.aviso-cookie {
    background-color: rgba(0, 0, 0, 0.85);
    width: 100%;
    height: calc(65 * var(--newSize));
    position: fixed;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: solid 1px rgba(150, 150, 150, 0.2);

    .aviso-cookie-mensagem {
        font-size: 0.625rem;
        padding-right: 20px;
        padding-left: 20px;
        color: #fff;
    }

    .aviso-cookie-botoes {
        display: flex;

        flex-direction: row;
        justify-content: center;
        align-items: center;

        .botao-custom {
            min-width: calc(90 * var(--newSize));
            height: calc(35 * var(--newSize));
            min-height: calc(35 * var(--newSize));
            margin-right: 10px;

            .text,
            .botao-borda-arredondado-text {
                font-size: 0.9rem;
            }
        }
    }
}

@media (max-width: $max-width-mobile) {
    .aviso-cookie {
        background-color: rgba(0, 0, 0, 0.85);
        width: 100%;
        // height: calc(60 * var(--newSize));
        position: fixed;
        bottom: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: calc(160 * var(--newSize));
        box-sizing: border-box;
        padding-top: 10px;

        .aviso-cookie-mensagem {
            font-size: 0.8rem;
            line-height: 1rem;
        }

        .aviso-cookie-botoes {
            display: flex;
            margin-top: 15px;
            margin-bottom: 15px;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .botao-custom {
                min-width: calc(90 * var(--newSize));
                height: calc(35 * var(--newSize));
                min-height: calc(35 * var(--newSize));
                margin-right: 10px;

                .text,
                .botao-borda-arredondado-text {
                    font-size: 0.7rem;
                }
            }
        }
    }
}
