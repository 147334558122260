@import "../../../assets/styles/variaveis.scss";

.coluna-rodape-links {
    padding: 20px;

    .titulo {
        font-family: Roboto;
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        // line-height: 2.03;
        letter-spacing: normal;
        text-align: center;
        color: var(--warm-grey);
        // height: 60px;
    }

    .subtitulo {
        color: var(--bright-orange) !important;
    }

    .descriptionItem {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 10px;
        cursor: pointer;

        span {
            padding-top: 10px;
            font-weight: 300;
        }

        span.show {
            padding-top: 0;
        }

        &:hover {
            span {
                color: var(--bright-orange);
            }

            svg {
                color: var(--bright-orange);
            }
        }
    }

    .items {
        font-family: Roboto;
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1rem;
        letter-spacing: normal;
        text-align: center;
        color: var(--white-one);
        text-transform: uppercase;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &.newItem {
            margin-bottom: 0;
            position: relative;
        }

        li + li {
            margin-top: 10px;
        }

        ul {
            display: none;
            position: absolute;
            z-index: 10;
            top: 30px;
            background-color: #1d1d1d;

            li:last-child {
                margin-bottom: 10px;
            }
        }

        ul.show {
            display: block;
        }

        a:visited,
        a:link {
            color: var(--white-one);
            text-decoration: none;
        }
        a:hover {
            color: var(--bright-orange);
        }

        .itemsDescubra {
            a {
                font-weight: 300;
            }

            & + .itemsDescubra {
                margin-top: 10px;
            }
        }

        
    }

    
}

@media (max-width: $max-width-mobile) {
    .coluna-rodape-links {
        padding: 20px;

        .titulo::before {
            font-size: 1rem;
            content: "+ ";
            vertical-align: middle;
        }
        .titulo {
            font-family: Roboto;
            font-size: 0.65rem;
            vertical-align: middle;
            height: 1rem;
        }

        .subtitulo {
            color: var(--bright-orange) !important;
        }

        .items {
            div:first-child {
                margin-top: calc(27 * var(--newSize));
            }
            font-size: 0.65rem;
            line-height: 50px;
        }
    }
}
