@import "../../../assets/styles/variaveis.scss";

.card-resgate-pontos {
    width: 260px;
    height: 300px;
    margin: 8px;
    border-radius: 5px;
    background-color: var(--white-one);

    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-bottom: calc(var(--newSize)*33);

    .card-resgate-total-pontos {
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: .8;
        letter-spacing: normal;

        background-color: var(--bright-orange);
        color: var(--white);
        width: 60%;
        padding: 0.8rem 0;
        margin: 0 auto;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        text-align: center;
        font-size: 1.125rem;
    }

    .card-resgate-premio {
        min-height: calc(42 * var(--newSize));
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 16px;
        letter-spacing: normal;
        text-align: center;
        color: var(--warm-grey);

        height: 60px;
        margin: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
    }

    .card-thumb-custom-resgate {
        height: 100%;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    .thumb-custom-resgate {
        width: 120px;
        height: 80%;
        cursor: unset;
    }

    .botao-resgate-custom {
        margin-top: 15px;
        width: calc(180 * var(--newSize));

        &:disabled {
            background-color: #8c8c8c;

            .text {
                color: #616161;
            }
        }
    }
}

@media (max-width: $max-width-mobile) {
    .card-resgate-pontos {
        // margin-left: calc(0 * var(--newSize));
        // width: calc(150 * var(--newSize));
        // height: calc(250 * var(--newSize));
        max-height: calc(250 * var(--newSize));

        // padding-top: calc(30 * var(--newSize));

        .card-resgate-total-pontos {
            // margin-top: calc(20 * var(--newSize));
            font-size: 1.28rem;
        }

        .card-resgate-premio {
            min-height: calc(42 * var(--newSize));
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 16px;
            letter-spacing: normal;
            text-align: center;
            color: var(--warm-grey);
    
            height: 60px;
            margin: 0;
    
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;
        }

        .card-thumb-custom-resgate {
            height: 100%;
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
        }

        .thumb-custom-resgate {
            width: 120px;
            height: 80%;
            // height: calc(160 * var(--newSize));
            cursor: unset;
            // margin-top: calc(10 * var(--newSize));
        }

        .botao-resgate-custom {
            width: 100% !important;
            overflow: auto;

            &:disabled {
                background-color: #8c8c8c;
    
                .text {
                    color: #616161;
                }
            }

            .text {
                font-size: 0.7rem;
                font-weight: 500;
            }
        }
    }
}
