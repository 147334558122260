@import "../../../assets/styles/variaveis.scss";
.container-lista-anuncio-tira-horizontal {
    //height: 100px;
    background-color: "#ccc";

    .lista-anuncios-horizontal-titulo {
        padding: 21px;
        font-size: 1rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: var(--warm-grey);
        margin-bottom: 2px;
    }

    .lista-anuncios-tira {
        margin-top: 26px;
        display: flex;
        overflow: hidden;
        width: 100%;
        flex-wrap: nowrap;
        transition: 0.5s;
        justify-content: center;
        align-items: flex-start;

        div {
            // margin-left: 1px;
        }
    }

    @media (max-width: $max-width-mobile) {
        .container-lista-anuncio-meia-tira-horizontal {
            //height: calc(150 * var(--newSize));
        }

        .lista-anuncios-horizontal-titulo {
            font-size: 0.62rem;
        }

        .lista-anuncios-tira {
            height: calc(100 * var(--newSize)) !important;
            margin-top: calc(0 * var(--newSize));
            //flex-direction: column;
        }
    }
}
