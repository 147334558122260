@import "../../../assets/styles/variaveis.scss";
.popup-sms {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(765 * var(--newSize));
    height: calc(500 * var(--newSize));

    .popup-sms-titulo {
        // margin-top: calc(30 * var(--newSize));
        padding-top: calc(30 * var(--newSize));
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 0.4;
        color: #b2c108;
    }
    .popup-sms-mensagem {
        margin-top: calc(29 * var(--newSize));
        width: calc(430 * var(--newSize));
        font-size: 1.625rem;
        font-weight: normal;
        line-height: 1.31;
        color: var(--warm-grey);
        text-align: justify;
    }
    .popup-input {
        margin-top: calc(29 * var(--newSize));
        border-radius: 5px;
        width: calc(430 * var(--newSize));
        height: 20px;
    }
    .link-mail {
        display: block;
        width: calc(430 * var(--newSize));
        color: #fff;
        font-size: 12px;
        margin-top: 5px;
        text-align: left;
        cursor: pointer;
    }
    .popup-button {
        margin-top: calc(29 * var(--newSize));
        background-color: #c60;
        font-size: 1.250rem;
        padding: 0px 8px;
        font-weight: 100;
    }
    .popup-resgate-custom-button {
        margin-top: calc(55 * var(--newSize));
        width: calc(157 * var(--newSize));
        min-height: calc(60 * var(--newSize));
        max-height: calc(60 * var(--newSize));
        // margin-bottom: 15px;
        .text {
            font-size: 2rem;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.5;
            letter-spacing: normal;
        }
    }
}

.popup-aviso {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(733 * var(--newSize));
    height: calc(284 * var(--newSize));
    justify-content: center;

    .popup-aviso-titulo {
        margin-top: calc(82 * var(--newSize));
        font-size: 2rem;
        font-weight: normal;
        line-height: 0.5;
        letter-spacing: normal;
        color: var(--bright-orange);
    }
    .popup-aviso-mensagem {
        margin-top: calc(29 * var(--newSize));
        font-size: 1.125rem;
        font-weight: normal;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: center;
        color: var(--warm-grey);

        a {
            color: blue;
        }
    }
}

@media (max-width: $max-width-mobile) {
    .popup-sms {
        width: 90%;
        height: 90%;
        padding-bottom: 20px;
        margin: 0 auto;
        justify-content: center;
        .popup-sms-titulo {
            margin-top: calc(82 * var(--newSize));
            font-size: 2.5rem;
            font-weight: 600;
            line-height: 0.4;
            color: #b2c108;
        }
        .popup-sms-mensagem {
            margin-top: calc(29 * var(--newSize));
            font-size: 1.2rem;
            font-weight: normal;
            line-height: 1.31;
            color: var(--warm-grey);

            width: 70%;
            margin-left: auto;
            margin-right: auto;
        }
        .popup-input {
            margin-top: calc(29 * var(--newSize));
            border-radius: 5px;
            width: 60%;
            height: 20px;
        }
        .link-mail {
            display: block;
            color: #fff;
            width: 60%;
            font-size: 14px;
            margin-top: 5px;
            cursor: pointer;
        }
        .popup-resgate-custom-button {
            .text {
                font-size: 1.5rem;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 0.5;
                letter-spacing: normal;
            }
        }
    }

    .popup-aviso {
        width: 100%;
        height: 100%;
        justify-content: center;
        .popup-aviso-titulo {
            margin-top: calc(92 * var(--newSize));
            font-size: 1.3rem;
        }
        .popup-aviso-mensagem {
            margin-top: calc(29 * var(--newSize));
            font-size: 1rem;
            font-weight: normal;
            line-height: 1.78;

            padding-left: 10px;
            padding-right: 10px;

            a {
                color: blue;
            }
        }
    }
}
