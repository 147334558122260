@import "../../../assets/styles/variaveis.scss";
.menu-perfil-logado {
    position: relative;
    margin-top: calc(36 * var(--newSize));
    .avatar {
        width: calc(69 * var(--newSize));
        height: calc(69 * var(--newSize));
        background-color: #333;
        border-radius: 50%;
        cursor: pointer;
    }

    .menu-aberto {
        position: absolute;
        top: calc(76 * var(--newSize));
        right: 0;
        width: calc(171 * var(--newSize));
        max-width: calc(171 * var(--newSize));

        border-radius: 16px;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.32);
        background-color: #333;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding-top: calc(30 * var(--newSize));
        padding-bottom: calc(30 * var(--newSize));
        padding-left: calc(17 * var(--newSize));
        padding-right: calc(17 * var(--newSize));

        .menu-aberto-item {
            font-size: 1.1rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.91;
            letter-spacing: normal;
            text-align: left;
            color: var(--white-one);
            width: 100%;
        }

        .menu-aberto-item[logout] {
            cursor: pointer;
        }

        .menu-aberto-item[hover]:hover {
            color: var(--bright-orange);
        }

        a:visited,
        a:link {
            color: var(--white-one);
            text-decoration: none;
        }
    }
}

@media (max-width: $max-width-mobile) {
    .menu-perfil-logado {
        display: none;
    }
}
