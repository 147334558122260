@import "../../../assets/styles/variaveis.scss";

.tmdb-rating {
    display: flex;
    align-items: center;

    img {
        width: calc(238 * var(--newSize));
    }

    .tmdb-rating-votacao {
        margin-left: calc(13 * var(--newSize));
        font-family: Roboto;
        font-size: 1rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.66;
        letter-spacing: normal;
        text-align: left;
        color: var(--white-one);
    }
}

@media (max-width: $max-width-mobile) {
    .tmdb-rating {
        img {
            width: calc(115 * var(--newSize));
        }

        .tmdb-rating-votacao {
            margin-left: calc(6 * var(--newSize));
            font-size: 0.47rem;
            line-height: 1.67;
        }
    }
}
