@import "../../../assets/styles/variaveis.scss";

.anuncio {
    border-radius: 8px;
    background-color: var(--warm-grey);
    display: flex;
    color: #fff;
    flex-direction: column;
    box-sizing: content-box;
    width: fit-content;
    height: fit-content;

    .imagemCapaCustom {
        width: 100%;
        height: calc(250 * var(--newSize));
        border-radius: 8px;
    }

    cursor: pointer;

    .barra-titulo {
        margin-left: 0px !important;
        width: 100%;
        height: calc(40 * var(--newSize));
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--bright-orange);
        position: relative;

        .imagem-barra-titulo {
            position: absolute;
            left: 8px;
        }
        .barra-titulo-label {
            font-size: 0.875rem;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            text-transform: uppercase;
        }
    }
}

.anuncio-monetizado {
    .imagemCapa {
        border-top-left-radius: unset;
        border-top-right-radius: unset;
    }
}

.popup-video {
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;

    justify-content: center;
    align-items: center;

    .protecao-de-tela {
        width: 100%;
        height: 100%;
        position: absolute;
    }
}

.popup-pontos-recebidos {
    width: calc(675 * var(--newSize));
    height: calc(488 * var(--newSize));
    padding-left: calc(67 * var(--newSize));
    padding-right: calc(67 * var(--newSize));
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;

    img {
        margin-top: calc(64 * var(--newSize));
    }
    .popup-pontos-recebidos-titulo {
        padding-top: calc(30 * var(--newSize));
        font-size: 2.5rem;
        font-weight: 500;
        line-height: 0.4;
        color: #b2c108;
    }
    .popup-pontos-recebidos-mensagem {
        margin-top: calc(40 * var(--newSize));
        font-size: 1.625rem;
        font-weight: normal;
        line-height: 1.31;
        color: var(--warm-grey);
    }

    .popup-pontos-recebidos-custom-button {
        margin: calc(55 * var(--newSize)) auto 0 auto;
        width: calc(157 * var(--newSize));
        height: calc(56 * var(--newSize));

        .text {
            font-size: 2rem;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.5;
            letter-spacing: normal;
        }
    }
}

@media (max-width: $max-width-mobile) {
    .anuncio {
        .imagemCapaCustom {
            width: calc(252 * var(--newSize));
            height: calc(86 * var(--newSize));
        }

        .barra-titulo {
            min-height: calc(26 * var(--newSize)) !important;
            max-height: calc(26 * var(--newSize)) !important;
            height: calc(26 * var(--newSize)) !important;
            //        width: calc(329 * var(--newSize)) !important;

            .imagem-barra-titulo {
                width: calc(13 * var(--newSize)) !important;
            }
            .barra-titulo-label {
                font-size: 0.5625rem;
            }
        }
    }

    .popup-pontos-recebidos {
        max-width: 65%;

        .popup-pontos-recebidos-mensagem {
            word-break: break-word;
            max-width: 95%;
        }
    }

    // .anuncio-monetizado {
    //     height: calc(183 * var(--newSize)) !important;
    //     width: calc(190 * var(--newSize)) !important;
    // }
}
