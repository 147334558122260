@import '../../../assets/styles/variaveis.scss';

.classificacao {
    width: calc(48 * var(--newSize));
    height: calc(48 * var(--newSize));
}

@media (max-width: $max-width-mobile) {

    .classificacao {
        width: calc(17 * var(--newSize)) !important;
        height: calc(16 * var(--newSize)) !important;
        align-items: center;
    }

}
