.menu-item {

    
    text-align: center;
    transition: 0.2s;
    cursor: pointer;
    text-transform: uppercase;
    padding: 0px 14px;

    font-family: Roboto;

    /*font-size:  17.5px; */
    font-size:  1.1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.88px;
    text-align: left;
}

.menu-item-ativo {
    color: var(--bright-orange);
}

.menu-item:hover {
    
    color: var(--bright-orange);
}
