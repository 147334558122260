.botao-somente-texto {
    cursor: pointer;
    // min-width: calc(165 * var(--newSize));
    height: calc(58 * var(--newSize));
    border-radius: calc(29 * var(--newSize));
    //box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.32);

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: unset;
    border: unset;

    .text {
        font-size: 1.3rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.67;
        letter-spacing: normal;
        text-align: center;
        color: var(--white-one);
    }

    @media (max-width: 1366px) {
    }
}

.botao-somente-texto:hover {
    background-color: var(--white-one);
    .text {
        color: var(--background-black-two);
    }
}
