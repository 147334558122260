.text-product-title {
    width: 673px;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 54px;
    color: #151A25;
}

.girl-product-div {
    position: relative;
    background-size: contain;
    background-position: center;
    background-image: url('/assets/img/usemais/couple_home.png');
    background-repeat: no-repeat;
}

.div-main-bg {
    position: relative;
    background-image: url('/assets/img/usemais/spark_back.png');
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: 90% bottom;
    background-attachment: scroll;
}

.product-number-topic {
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    width: 53px;
    line-height: 97px;
    /* identical to box height */
    display: flex;
    align-items: center;

    /* gradiente_atualizado */
    background: linear-gradient(90deg, #E13441 0%, #FAAE2E 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.btn-quero-esse {
    justify-content: center;
    align-items: center;
    padding: 0px;
    background: linear-gradient(90deg, #E13441 0%, #FAAE2E 100%);
    border-radius: 20px;
    width: 413px;
    height: 57px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
}