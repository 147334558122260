.busca-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .busca {
        position: relative;
        background-color: #fff;
        padding-left: calc(45 * var(--newSize));
        border-radius: 10px;
        color: #0f0f0f;
        display: flex;
        transition: height 0.5s;
        flex-direction: column;
        height: calc(65 * var(--newSize));
        max-height: calc(65 * var(--newSize));
        overflow: hidden;
        max-width: calc(960 * var(--newSize));
        width: 100%;
        z-index: 1;
    }

    .busca.duas-linhas {
        height: calc(80 * var(--newSize));
    }

    .input-busca {
        display: flex;
        flex: 1;

        input {
            border: none;
            width: calc(704 * var(--newSize));
            border: none;
            font-family: Roboto;
            font-size: 1.1rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--warm-grey);
            vertical-align: middle;
        }

        input:focus {
            outline: none;
        }

        .botao {
            cursor: pointer;
            padding: 0 10px;
            transition: 0.5;
            justify-content: center;
            align-items: center;
            display: flex;
            font-family: Roboto;
            font-size: 1.1rem;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
        }

        .botao.lupa {
            color: var(--bright-orange);
            padding-right: 1.5rem;
            font-size: 1.5rem;
        }

        .botao.filtro {
            color: var(--white-one);
        }

        .botao:hover {
            color: #ddd;
        }

        .fundo-botao-filtro {
            align-items: center;
            display: flex;
            justify-content: center;
            background-color: var(--bright-orange);
            width: calc(257 * var(--newSize));
        }
    }

    .filtro-busca {
        max-width: calc(960 * var(--newSize));
        width: 100%;
        height: calc(109 * var(--newSize));
        margin: 4px 0 3px;

        padding: 0px calc(22.5 * var(--newSize));

        border-radius: 16px;

        box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.32);

        border: solid 1px var(--warm-grey);

        background-color: var(--white-one);
        margin-top: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 1;
    }

    .filtro-busca {
        select:not(:last-child) {
            border-right: 3px solid #f2f2f2;
        }

        select {
            background-color: rgba(255, 255, 255, 0.9);
            width: 100%;
            padding: 8px;

            border: 0px;
            outline: 0px;

            margin: 0 5px;
            border-radius: 4px;
            -moz-appearance: none; /* Firefox */
            -webkit-appearance: none; /* Safari and Chrome */
            appearance: none;

            font-family: Roboto;
            font-size: 1.1rem;
            font-weight: normal;
            text-align: left;
            color: var(--warm-grey);

            option {
                font-family: Roboto;
                font-size: 1.1rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.03;
                letter-spacing: normal;
                text-align: left;
                color: #333;
            }
        }
    }

    
}

@media screen and (max-width: 992px) {
    .busca-container {
        max-width: 65% !important;
    }
}
