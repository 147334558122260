@import "../../../assets/styles/variaveis.scss";
.titulo-filme-serie {
    font-family: Roboto;
    /*font-size: calc(2.5em + 1vw); /* 3.5rem;    */
    font-size: 4rem;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--white-one);
    text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
}

@media (max-width: $max-width-mobile) {
    .titulo-filme-serie {
        font-size: 1.34rem !important;
        margin-top: calc(12 * var(--newSize));
    }
}
