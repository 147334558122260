@import "../../../assets/styles/variaveis.scss";



.links {

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    a {
        display: block;
        font-family: Roboto;
        font-size: 1.2rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.03;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }
}

   


@media (max-width: $max-width-mobile) {
    .coluna-rodape-outros {
        align-items: center;
        min-width: unset;

        .use-mais {
            margin: 10px 0 0 10px;
           width: 206px;
        //    height: 139px;
           flex-grow: 0;
        }

        .assista-mais {
            margin-top: calc(22 * var(--newSize));
            width: calc(94 * var(--newSize));
        }

        .rede-social {
            padding-bottom: calc(32 * var(--newSize));

            a {
                margin-right: calc(21 * var(--newSize));
                svg {
                    font-size: 1rem;
                }
            }

            a:link {
                text-decoration: none;
                color: #fff;
            }

            a:visited {
                text-decoration: none;
                color: #fff;
            }
        }

        .links {
            a {
                font-size: 1.2rem;
            }
        }

        .registro {
            margin-top: calc(22 * var(--newSize));
            line-height: 1;
            font-size: 1.2rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-items: center;
            
            p{
               margin: 0; 
               color: #fff;
            }
        }
        .apresentacao {
            margin-top: calc(20 * var(--newSize));
            font-size: 1.2rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
            // width: calc(150 * var(--newSize));
        }

        .contato {
            font-size: 1.2rem;
            line-height: 1.2;
            // width: calc(150 * var(--newSize));
            text-align: center;
            display: flex;
            align-items: center;
        }
        .contato-pontos {
            margin-top: calc(20 * var(--newSize));
            margin-bottom: 50px;
            // display: flex;
            flex-direction: column;
            align-items: flex-start;
            // justify-content: center;
            font-size: 1rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            text-align: center;
            
            
            a:link {
                color: #fff;
                
            }
    
            a:visited {
                color: #fff;
            }
        }
        .apresentacao-pontos {
            margin-top: calc(20 * var(--newSize));
            font-size: 1rem;
            font-weight: 100;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
            // width: calc(300 * var(--newSize));
        }

        .contato-pontos-usemais {
           
            margin: 20px 0 0 0;
            // display: flex;
            flex-direction: column;
            align-items: flex-start;
            // justify-content: center;
            font-size: 1rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            text-align: center;
            
            
            a:link {
                color: #fff;
                text-decoration: none;
            }
    
            a:visited {
                color: #fff;
            }
        }
    }
}
