@import "../../../assets/styles/variaveis.scss";

.seta-voltar {
    cursor: pointer;

    img {
        width: calc(32 * var(--newSize));
        height: calc(32 * var(--newSize));
    }

    @media (max-width: 1366px) {
    }
}

@media (max-width: $max-width-mobile) {
}
