.pontilhado {
    width: calc(444 * var(--newSize));
    height: calc(64 * var(--newSize));
    display: flex;
    
    img {
        width: 100%;
    }
}

@media screen and (max-width: 1366px) {
    
}

@media screen and (max-width: 992px) {

}