@import "../../../assets/styles/variaveis.scss";
.banner1-container {
    display: flex;
    flex-direction: column;

    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    z-index: 50000000000;

    .banner1-titulo {
        font-family: Roboto;
        font-size: 2.5rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--white-one);
        text-align: center;
        margin-top: calc(145 * var(--newSize));

        @media (max-width: $max-width-mobile) {
            margin-top: calc(30 * var(--newSize));
            padding: 0px 40px 0 40px;
            font-size: 1.2rem;
            line-height: 24px;
        }
    }

    .banner1-subtitulo {
        margin-top: calc(27 * var(--newSize));
        font-family: Roboto;
        font-size: 1.5rem;
        line-height: calc(15 * var(--newSize));
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: var(--white-one);

        @media (max-width: $max-width-mobile) {
            font-size: 1rem;
            line-height: 1.1rem;
            margin-top: 11px;
            padding: 0 40px;
        }
    }

    .banner1-botao {
        margin-top: calc(106 * var(--newSize));
        height: calc(48 * var(--newSize));

        @media (max-width: $max-width-mobile) {
            margin-top: calc(17 * var(--newSize));
        }
    }
}

@media (max-width: $max-width-mobile) {
    .banner1-container {
        box-sizing: border-box;
        z-index: 1000001;
        position: absolute;
    }
    // .item-banner {
    //     //height: 300px;
    //     .item-banner-conteudo {
    //         margin-top: 62px; // mesma altura do menu
    //     }
    // }

    // .item-banner-background {
    //     height: calc(300 * var(--newSize));
    //     background-repeat: no-repeat;
    //     background-size: cover;
    // }
}

.popup-video {
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    position: static;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;

    justify-content: center;
    align-items: center;

    .protecao-de-tela {
        width: 100%;
        height: 100%;
        position: absolute;
    }
}
