@import "../../assets/styles/variaveis.scss";
.editar-perfil {

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .custom-menu {
        height: unset;
    }

    .editar-perfil-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: calc(90 * var(--newSize));
        margin-bottom: calc(90 * var(--newSize));

        .outras-opcoes {
            height: calc(120 * var(--newSize));
            width: calc(1435 * var(--newSize));

            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;

            .excluir-conta {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: center;
                width: fit-content;

                font-size: 1rem;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                color: var(--bright-orange);

                img {
                    margin-right: 8px;
                }
            }
        }

        .form-inputs {
            border-radius: 12px;
            border: solid 1px #474747;
            background-color: var(--background-black-two);

            width: calc(1295 * var(--newSize));
            height: calc(850 * var(--newSize));
            padding-left: calc(71 * var(--newSize));
            padding-right: calc(71 * var(--newSize));

            .mensagem-dados-incompleto {
                font-size: 1.3rem;
                font-weight: 500;
                margin-top: calc(-16 * var(--newSize));
                padding-bottom: calc(16 * var(--newSize));
            }

            .form-input-topo {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: calc(38 * var(--newSize));

                .titulo-popup {
                    margin-top: calc(78 * var(--newSize));
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-size: 1.75rem;

                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 0.57;
                    letter-spacing: normal;
                    text-align: center;

                    color: var(--white-one);

                    .seta-voltar-custom {
                        margin-right: calc(20 * var(--newSize));
                    }
                }

                .alterar-senha {
                    margin-top: calc(63 * var(--newSize));
                    font-size: 1rem;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.8px;
                    text-align: left;
                    color: var(--bright-orange);
                    cursor: pointer;
                }
            }

            .inputs {
            }
            .form-acao {
                margin-top: calc(40 * var(--newSize));
                margin-bottom: calc(40 * var(--newSize));
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

@media (max-width: $max-width-mobile) {
    .editar-perfil {
        .editar-perfil-container {
            margin-top: 0px;
            margin-bottom: 0px;

            .outras-opcoes {
                width: 100%; // calc(1435 * var(--newSize));
                padding: calc(20 * var(--newSize));
                box-sizing: border-box;
            }

            .form-inputs {
                border: solid 0px #474747;
                box-sizing: border-box;
                width: 100%;
                height: unset;
                padding-left: calc(20 * var(--newSize));
                padding-right: calc(20 * var(--newSize));

                .mensagem-dados-incompleto {
                    font-size: 1rem;
                }

                .form-input-topo {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-bottom: calc(38 * var(--newSize));

                    .titulo-popup {
                        margin-top: calc(38 * var(--newSize));
                        font-size: 1.2rem;

                        .seta-voltar-custom {
                            margin-right: calc(20 * var(--newSize));
                        }
                    }

                    .alterar-senha {
                        margin-top: calc(45 * var(--newSize));
                        font-size: 0.9rem;
                    }
                }

                .form-acao {
                    margin-top: calc(40 * var(--newSize));
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .editar-perfil-btn-hack {
                        height: calc(48 * var(--newSize));
                        width: calc(220 * var(--newSize));
                        .text {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }
}
